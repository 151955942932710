

import { updateLocalStorage } from './localStorage'

import { CandidiateArray } from '../../data/CandidateDataJson';


var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setPoliticalIntrestAsync = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].politicalIntrest.push(array)
    user.politicalIntrest = userList[index].politicalIntrest
    updateLocalStorage(user, userList)

}
export const getPoliticalIntrestAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));

    //console.log("Ellis User Check");
    //console.log('@user');
    const items = await user?.politicalIntrest;
    return items ? items : []
}


export const getPoliticalIntresfromLStAsync = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.politicalIntrest;
    return items ? items : null
}


export const getCandidatePoliticalInterest = async (name) => {

    console.log("Ellis Candidate Political Interest");

    let foundUser = CandidiateArray.find(x => x.name === name)
    const items = [];
    items.push(foundUser?.interestMap.get("Gun Control"));
    items.push(foundUser?.interestMap.get("Abortion Rights"));
    items.push(foundUser?.interestMap.get("LGBT Rights"));
    items.push(foundUser?.interestMap.get("Voter Registration Reform"));
    items.push(foundUser?.interestMap.get("Immigration Reform"));
    return items ? items : []
}

export const updatePoliticalIntrestAsync = (array) => {
    userList[index].politicalIntrest = array
    user.politicalIntrest = array
    updateLocalStorage(user, userList)
}

export const deleteInterests = (array) => {
    userList[index].politicalIntrest = array
    user.politicalIntrest = array
    updateLocalStorage(user, userList)
}
