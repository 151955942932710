import userOneImg from '../assets/images/img-lg-1.jpg';
import derekGreenPortrait from '../assets/images/derek_green_portrait.jpg';
import dougMastrianoProfile from '../assets/images/doug_mastriano_profile.jpg';
import fettermanPortrait from '../assets/images/fetterman_portrait.jpg';
import louBarlettaProfile from '../assets/images/lou_barletta_profile.jpg';
import martinaWhitePortrait from '../assets/images/martina_white_portrait.jpg';
import mehmetOzPortrait from '../assets/images/mehmet_oz_portrait.jpg';
import mariaSanchezPortrait from '../assets/images/maria_sanchez_portrait.jpg';

import derekGreenPortraitbanner from '../assets/images/POST/bannerImage/dg_banner.jpg';
import dougMastrianoProfilebanner from '../assets/images/POST/bannerImage/doug_mastriano_banner.jpg';
import fettermanPortraitbanner from '../assets/images/POST/bannerImage/John_Fetterman_banner.jpeg';
import louBarlettaProfilebanner from '../assets/images/POST/bannerImage/lou_barletta_banner.jpg';
import martinaWhitePortraitbanner from '../assets/images/POST/bannerImage/Martina White-banner.jpg';
import mehmetOzPortraitbanner from '../assets/images/POST/bannerImage/Mehmet_Oz_banner.jpg';
import mariaSanchezPortraitbanner from '../assets/images/POST/bannerImage/Maria_q_s_banner.png';

import johnDoeImage from '../assets/images/john-doe.jpg';
import mathildaImage from '../assets/images/img-lg-1.jpg';
import sarahImage from '../assets/images/img-lg-2.jpg';


export const ProfileImageMap = new Map();

ProfileImageMap.set('Derek Green', derekGreenPortrait);
ProfileImageMap.set('Doug Mastriano', dougMastrianoProfile);
ProfileImageMap.set('John Fetterman', fettermanPortrait);
ProfileImageMap.set('Lou Barletta', louBarlettaProfile);
ProfileImageMap.set('Martina White', martinaWhitePortrait);
ProfileImageMap.set('Mehmet Oz', mehmetOzPortrait);
ProfileImageMap.set('Maria quiñones-sánchez', mariaSanchezPortrait);

ProfileImageMap.set('Jhon Doe', johnDoeImage);
ProfileImageMap.set('Mathilda Brinker', mathildaImage);
ProfileImageMap.set('Sarah Hetfield', sarahImage);






export const BannerImageMap = new Map();
BannerImageMap.set('John Fetterman', fettermanPortraitbanner);
BannerImageMap.set('Derek Green', derekGreenPortraitbanner);
BannerImageMap.set('Maria quiñones-sánchez', mariaSanchezPortraitbanner);
BannerImageMap.set('Martina White', martinaWhitePortraitbanner);
BannerImageMap.set('Mehmet Oz', mehmetOzPortraitbanner);
BannerImageMap.set('Lou Barletta', louBarlettaProfilebanner);
BannerImageMap.set('Doug Mastriano', dougMastrianoProfilebanner);