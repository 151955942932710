// Libraries
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';
import { getInterviewAsync, getInterviewLSAsync } from '../../../../shared/asyncstorage/interview';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const Interviews = () => {
    const { username } = useParams();
    const [interviewArray, setInterview] = useState([]);
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)

    useEffect(() => {
        if (publicUser?.userCandidateExtension?.interviews?.length > 0)
            setInterview(publicUser?.userCandidateExtension?.interviews)
    }, [publicUser])
    // useEffect(() => {

    //     let checkLS = getInterviewLSAsync(username)
    //     if (checkLS && checkLS.length > 0) {
    //         setInterview(checkLS)
    //     }
    //     else {
    //         getInterviewAsync().then(res => {
    //             setInterview(res)
    //         })
    //     }
    // }, [])
    return (
        <>
            {
                interviewArray.length > 0 &&
                interviewArray.map((value, key) => {
                    return (
                        <div className='mv-view mv-mt-20'>
                            <Title level={2}>{value.linkTitle}</Title>
                            <div className='mv-player-wrapper'>
                                <ReactPlayer
                                    className='mv-react-player'
                                    id={value._id}
                                    url={value.linkUrl}
                                    // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Interviews;
