// Libraries
import { Layout } from 'antd';

// Components
import {
    AppLayout,
    ProfileBanner,
} from '../../components';
import {
    PoliticalInterests,
} from './components';

// Styles
import './style.css';

// Assets

const { Footer } = Layout;

const EditUser = () => {
    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700'>
                <ProfileBanner  public='false'/>
                <PoliticalInterests />
            </main>
            {/* <Footer>© MyVote 2022. All rights reserved.</Footer> */}
        </AppLayout>
    )
}

export default EditUser;
