// Libraries
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Component
import userImg from '../../../../assets/images/user.png';

import {
    AppLayout,
    SendPost,
    Post,
    Poll,
    Users,
} from '../../../../components';

// Styles
import './style.css';

// Assets
import { getPoliticalIntrestAsync } from '../../../../shared/asyncstorage/politicalIntrest';
import { user } from '../../../../shared/asyncstorage/localStorage';
import interceptor from '../../../../shared/interceptor';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';
import { IMAGE_URL } from '../../../../enviroment/Envirments';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { Spin } from 'antd';

const Candidate = () => {
    const [data, setDate] = useState();
    const [post, setPosts] = useState([]);

    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { publicPost } = useShareSelector(state => state.PublicUserDetails)
    const { token } = useToken()
    const dispatcher = useShareDispatch();

    useEffect(() => {
        debugger
        if (!publicPost) {
            dispatcher(actionsApi.getPublicPost(token))
        }

    }, [publicPost]);



    return (
        <AppLayout>
            <main className='mv-main-content'>
                {
                    loginUserObject?.matchedCandidates?.length > 0 && loginUserObject?.userBase?.userType != "CANDIDATE" && <Users />
                }
                <SendPost />
                {
                    publicPost && publicPost.length > 0 ?
                        publicPost.map((item, key) => {
                            return (
                                <>

                                    {

                                        <Post
                                            postId={item.postId}
                                            text={item?.postBase?.postStr}
                                            textOne={item.textOne}
                                            profileName={item.postUserName}
                                            profilePic={item.postUserProfilePicture.contentId ? IMAGE_URL + item.postUserProfilePicture.contentId : userImg}
                                            img={item.postContent?.contentTitle == "post_photo" ? item.postContent?.contentId : null}
                                            time={item.postBase.postTimestamp}
                                            video={item.postContent?.contentTitle == "post_video" ? item.postContent?.contentId : null}
                                            likes={item.postLikes}
                                            comments={item.postComments}
                                            postUserId={item.postBase.userId}
                                            user={loginUserObject?.userProfilePicture?.contentId}
                                            liked={item.likedByCurrentUser}
                                            followed={item.followedByCurrentUser ? item.followedByCurrentUser : false}
                                            requested={item.requestedByCurrentUser ? item.requestedByCurrentUser : false}

                                        />
                                    }

                                    {/* {
                                    item.type == 1 &&
                                    <Poll
                                        text={item.text}
                                        textOne={item.textOne}
                                        profileName={item.postUser}
                                        profilePic={item.img}
                                        poll={item.poll}
                                    />
                                } */}
                                </>
                            )
                        })
                        :
                        <>
                            {
                                publicPost && publicPost.length == 0 ?
                                    <div className='card'>
                                        No posts available to view
                                    </div>
                                    :
                                    <div className='loading'>
                                        <Spin />
                                    </div>

                            }

                        </>

                }


                {/* <Post
                    text={ImagePost.text}
                    textOne={ImagePost.textOne}
                    profileName="Mr. Cool"
                    profilePic={userImageOne}

                />
                <Post
                    video={ImagePost.video}
                    profileName="Charming Prince"
                    replyUser="Marina Valentine"
                    replyiMAGE={userImagetHREE}
                    profilePic={userImagetwo}
                /> */}

            </main>
        </AppLayout>
    )
}

export default Candidate;
