import { useState, useEffect } from 'react'
import { Card, Form, Button, AutoComplete, Input, Typography, Modal } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon, DeleteIcon } from '../../../../assets/icons/icons';

// Styles
import './style.css';

import uuidv4 from '../../../../shared/uuidgenerator';
import { deleteLinksAsync, getLinksAsync, setLinksAsync, updatelinksAsync } from '../../../../shared/asyncstorage/links';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../../../Notification/successNotif';

// Assets

const { Title } = Typography;

const ImportantLinks = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [linksArray, setLinksArray] = useState([]);
    const [model, setModel] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false)
    const [form] = Form.useForm();
    const { token, getUserID } = useToken();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();


    useEffect(() => {
        if (loginUserObject?.userCandidateExtension?.importantLinks?.length > 0)
            setLinksArray(loginUserObject?.userCandidateExtension?.importantLinks)
    }, [loginUserObject])

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.linkTitle) {
            values.linkType = "important"
            setIsUpdating(true)
            interceptor.axiosPost("Post", "/links", values, token).then(res => {
                setIsUpdating(false)
                if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Added")
                dispatcher(actionsApi.UpdateImportantLinks(res.data))
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setIsUpdating(false)
            })
            handleCancel()
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // setPoliticalI
    };


    const showModal = () => {
        setIsModalOpen(true);
        setModel(null)
        form.setFieldsValue({
            linkTitle: '',
            linkUrl: '',
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const deleteLinks = (item) => {
        interceptor.axiosDelete("Delete", "/links/" + item.linkId, null, token).then(res => {
            setIsUpdating(false)
            if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Remove")
                dispatcher(actionsApi.deleteLinks(item))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setIsUpdating(false)

        })

    }
    const editLinks = (value) => {
        setIsModalOpen(true);
        setModel(value)
        form.setFieldsValue({
            linkTitle: value.linkTitle,
            linkUrl: value.linkUrl,
        });
    }
    return (
        <>
            <div className='mv-form-fields mv-mt-20'>
                <div className='mv-d-flex mv-jc-sb'>
                    <Title level={2}>Important Links</Title>
                    <Link to="#" onClick={() => showModal()} className='ant-btn ant-btn-danger ant-btn-44'>Add New</Link>
                </div>

                {
                    linksArray.length > 0 &&
                    linksArray.map((value, key) => {
                        return (
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Card>
                                    <div
                                        name="linkTitle"
                                        className='mv-mb-15'
                                    >
                                        <Input value={value.linkTitle} placeholder="Important Link Title" />
                                    </div>
                                    <div
                                        name="linkUrl"
                                        className='mv-mb-0'
                                    >
                                        <Input value={value.linkUrl} placeholder="Important Link " />
                                    </div>
                                    <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                        <div className='mv-icon-btns mv-d-flex'>
                                            {/* <Link to="#" onClick={() => editLinks
                                                (value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                                <EditIcon />
                                            </Link> */}
                                            <Link to="#" onClick={() => deleteLinks(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                                <DeleteIcon />
                                            </Link>
                                        </div>
                                        {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    </div>
                                </Card>
                            </Form>

                        )
                    })
                }

                {/*  */}

                <>
                    <Modal
                        title="Add/Edit Important Link"
                        open={isModalOpen}
                        onOk={handleOk}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                            form={form}
                        >
                            <Card>
                                <Form.Item
                                    name="linkTitle"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="Important Link Title" />
                                </Form.Item>
                                <Form.Item
                                    name="linkUrl"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="important Link Url" />
                                </Form.Item>



                                <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>

                                    <Button type="primary" htmlType="submit" className='ant-btn-44'>Save</Button>
                                </div>
                            </Card>
                        </Form>
                    </Modal>
                </>


            </div>
        </>
    )
}

export default ImportantLinks;
