// Libraries
import { Typography, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import getConnected from '../../../../assets/images/get-connected.jpg';
import useToken from '../../../../hooks/useToken';

const { Title, Paragraph } = Typography;

const GetConnected = () => {
    const navigate = useNavigate();
    const { token } = useToken();
    const navigateUser = () => {
        if (!token) {
            navigate('/signin');
        } else {
            navigate('/home');

        }
    }
    return (
        <div className='mv-get-connected'>
            <div className='mv-connected-content mv-d-flex'>
                <div className='mv-connected-start'>
                    <Image src={getConnected} preview={false} alt="" />
                </div>
                <div className='mv-connected-end'>
                    <Title level={2}>Get connected to the candidates <span><br /></span> that represent you</Title>
                    <Paragraph>MyVote connects candidates & politicians to the communities they support… and allow those communities to inform their representatives on the issues that impact them the most… now and in the future</Paragraph>
                    <div className='mv-connected-btn'>
                        <span onClick={navigateUser} className='ant-btn ant-btn-danger'>Get Started</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetConnected;
