// Libraries
import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form, Input, Typography, Radio, Select } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthSlider } from '../../components';
import './style.css';
import  { getUSerAccessToken , accesToken} from '../../apis/auth/accesToken';
import useToken from '../../hooks/useToken';
import { useForm } from 'antd/lib/form/Form';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import interceptor from '../../shared/interceptor';
import { loginUser } from '../../apis/auth/authApis';


// Component
// var fs = require('browserify-fs');

var file = 'file.json';
// Styles

// Assets

const { Title, Paragraph } = Typography;
const { Option } = Select;

const SignUp = () => {
    const [userType, setuserType] = useState(1)
    const { form, error, setForm, setError } = useForm(null)
    const navigate = useNavigate()
    const dispatcher = useShareDispatch();
    const { isLoadingUserRole, loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const [loading, setLoading] = useState(false)

    const { saveLoginUser, getUserID } = useToken();
    useEffect(() => {

    }, [])
    const onFinish = async (values) => {
        setLoading(true)
        try {
            console.log('Success:', values);
            const token = await accesToken(values?.candidate);
            if (token) {
                // let model = {
                //     body: values,
                //     token: token.accessToken
                // }

                interceptor.axiosPost("POST", "/users", values, token.accessToken).then(async res => {
                    if (res.status == 200) {
                        const body = {
                            client_id: "ellis",
                            client_secret: "test",
                            scope: "none",
                            username: values.email,
                            password: values.password,

                        };
                        const acctoken = await getUSerAccessToken(body);
                        if (acctoken) {
                            saveLoginUser(acctoken)
                            let userId = getUserID()
                            loginUser(userId, acctoken.accessToken, values).then(res => {
                                setLoading(false)
                                dispatcher(actionsApi.gettingResponseSuccess(res))
                                navigate("/home")

                            }).catch(error => {

                                dispatcher(actionsApi.gettingResponseError(error))
                            })
                        }
                    }
                    else {
                        dispatcher(actionsApi.gettingResponseSuccess({}))
                    }
                }).catch(error => {
                    dispatcher(actionsApi.gettingResponseError(error))
                })
            }

        } catch {
            setLoading(false)

        }

    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='mv-auth'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} className="mv-auth-first-col">
                    <AuthSlider />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <div className='mv-auth-content'>
                        <Paragraph className='mv-auth-link mv-auth-link-top'>
                            Already have an account? <Link to="/signin">Sign In</Link>
                        </Paragraph>
                        <div className='mv-auth-form'>
                            <div className='mv-auth-header'>
                                <Title level={1}>Sign Up</Title>
                                <Paragraph>Welcome to MyVote, a platform to connect <br />
                                    with the political world</Paragraph>
                            </div>
                            <Form
                                name="signUpForm"
                                initialValues={{
                                    remember: true,

                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                                form={form}
                            >
                                <Row gutter={[20]}>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="firstName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input first name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='First Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="lastName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input last name',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Last Name' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                        <Form.Item
                                            name="userName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input username',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Username' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Input your E-mail!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Email Address' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24}>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Input your password',
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder='Password' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={10}>
                                        <Form.Item
                                            name="candidate"

                                            label="Are you a candidate for office?"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select candidate',
                                                },
                                            ]}
                                        >
                                            <Radio.Group onChange={(e) => {
                                                setuserType(e.target.value)
                                            }} value={userType} >
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={14}>
                                        <Form.Item
                                            name="gender"
                                            label="Gender"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select gender',
                                                },
                                            ]}
                                        >
                                            <Radio.Group name="genderGroup" >
                                                <Radio value={1}>Male</Radio>
                                                <Radio value={2}>Female</Radio>
                                                <Radio value={3}>Non-Binary</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[20]}>
                                    <Col xs={24} sm={24} md={10}>
                                        <Form.Item
                                            name="firstElection"

                                            label="Is this your first election?"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select option',
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[20]}>
                                    <Col xs={24}>
                                        <Paragraph style={{ fontWeight: '600', marginBottom: 10 }}>Date of Birth</Paragraph>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name="dayOfBirth"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select day',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Day"
                                            >
                                                <Option value="01">1</Option>
                                                <Option value="02">2</Option>
                                                <Option value="03">3</Option>
                                                <Option value="04">4</Option>
                                                <Option value="05">5</Option>
                                                <Option value="06">6</Option>
                                                <Option value="07">7</Option>
                                                <Option value="08">8</Option>
                                                <Option value="09">9</Option>
                                                <Option value="10">10</Option>
                                                <Option value="11">11</Option>
                                                <Option value="12">12</Option>
                                                <Option value="13">13</Option>
                                                <Option value="14">14</Option>
                                                <Option value="15">15</Option>
                                                <Option value="16">16</Option>
                                                <Option value="17">17</Option>
                                                <Option value="18">18</Option>
                                                <Option value="19">19</Option>
                                                <Option value="20">20</Option>
                                                <Option value="21">21</Option>
                                                <Option value="22">22</Option>
                                                <Option value="23">23</Option>
                                                <Option value="24">24</Option>
                                                <Option value="25">25</Option>
                                                <Option value="26">26</Option>
                                                <Option value="27">27</Option>
                                                <Option value="28">28</Option>
                                                <Option value="29">29</Option>
                                                <Option value="30">30</Option>
                                                <Option value="31">31</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name="monthOfBirth"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select month',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Month"
                                            >
                                                <Option value="01">January</Option>
                                                <Option value="02">February</Option>
                                                <Option value="03">March</Option>
                                                <Option value="04">April</Option>
                                                <Option value="05">May</Option>
                                                <Option value="06">June</Option>
                                                <Option value="07">July</Option>
                                                <Option value="08">August</Option>
                                                <Option value="09">September</Option>
                                                <Option value="10">October</Option>
                                                <Option value="11">November</Option>
                                                <Option value="12">December</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        <Form.Item
                                            name="yearOfBirth"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select year',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Year"
                                            >
                                                <Option value="2030">2030</Option>
                                                <Option value="2029">2029</Option>
                                                <Option value="2028">2028</Option>
                                                <Option value="2027">2027</Option>
                                                <Option value="2026">2026</Option>
                                                <Option value="2025">2025</Option>
                                                <Option value="2024">2024</Option>
                                                <Option value="2023">2023</Option>
                                                <Option value="2022">2022</Option>
                                                <Option value="2021">2021</Option>
                                                <Option value="2020">2020</Option>
                                                <Option value="2019">2019</Option>
                                                <Option value="2018">2018</Option>
                                                <Option value="2017">2017</Option>
                                                <Option value="2016">2016</Option>
                                                <Option value="2015">2015</Option>
                                                <Option value="2014">2014</Option>
                                                <Option value="2013">2013</Option>
                                                <Option value="2012">2012</Option>
                                                <Option value="2011">2011</Option>
                                                <Option value="2010">2010</Option>
                                                <Option value="2009">2009</Option>
                                                <Option value="2008">2008</Option>
                                                <Option value="2007">2007</Option>
                                                <Option value="2006">2006</Option>
                                                <Option value="2005">2005</Option>
                                                <Option value="2004">2004</Option>
                                                <Option value="2003">2003</Option>
                                                <Option value="2002">2002</Option>
                                                <Option value="2001">2001</Option>
                                                <Option value="2000">2000</Option>
                                                <Option value="1999">1999</Option>
                                                <Option value="1998">1998</Option>
                                                <Option value="1997">1997</Option>
                                                <Option value="1996">1996</Option>
                                                <Option value="1995">1995</Option>
                                                <Option value="1994">1994</Option>
                                                <Option value="1993">1993</Option>
                                                <Option value="1992">1992</Option>
                                                <Option value="1991">1991</Option>
                                                <Option value="1990">1990</Option>
                                                <Option value="1989">1989</Option>
                                                <Option value="1988">1988</Option>
                                                <Option value="1987">1987</Option>
                                                <Option value="1986">1986</Option>
                                                <Option value="1985">1985</Option>
                                                <Option value="1984">1984</Option>
                                                <Option value="1983">1983</Option>
                                                <Option value="1982">1982</Option>
                                                <Option value="1981">1981</Option>
                                                <Option value="1980">1980</Option>
                                                <Option value="1979">1979</Option>
                                                <Option value="1978">1978</Option>
                                                <Option value="1977">1977</Option>
                                                <Option value="1976">1976</Option>
                                                <Option value="1975">1975</Option>
                                                <Option value="1974">1974</Option>
                                                <Option value="1973">1973</Option>
                                                <Option value="1972">1972</Option>
                                                <Option value="1971">1971</Option>
                                                <Option value="1970">1970</Option>
                                                <Option value="1969">1969</Option>
                                                <Option value="1968">1968</Option>
                                                <Option value="1967">1967</Option>
                                                <Option value="1966">1966</Option>
                                                <Option value="1965">1965</Option>
                                                <Option value="1964">1964</Option>
                                                <Option value="1963">1963</Option>
                                                <Option value="1962">1962</Option>
                                                <Option value="1961">1961</Option>
                                                <Option value="1960">1960</Option>
                                                <Option value="1959">1959</Option>
                                                <Option value="1958">1958</Option>
                                                <Option value="1957">1957</Option>
                                                <Option value="1956">1956</Option>
                                                <Option value="1955">1955</Option>
                                                <Option value="1954">1954</Option>
                                                <Option value="1953">1953</Option>
                                                <Option value="1952">1952</Option>
                                                <Option value="1951">1951</Option>
                                                <Option value="1950">1950</Option>
                                                <Option value="1949">1949</Option>
                                                <Option value="1948">1948</Option>
                                                <Option value="1947">1947</Option>
                                                <Option value="1946">1946</Option>
                                                <Option value="1945">1945</Option>
                                                <Option value="1944">1944</Option>
                                                <Option value="1943">1943</Option>
                                                <Option value="1942">1942</Option>
                                                <Option value="1941">1941</Option>
                                                <Option value="1940">1940</Option>
                                                <Option value="1939">1939</Option>
                                                <Option value="1938">1938</Option>
                                                <Option value="1937">1937</Option>
                                                <Option value="1936">1936</Option>
                                                <Option value="1935">1935</Option>
                                                <Option value="1934">1934</Option>
                                                <Option value="1933">1933</Option>
                                                <Option value="1932">1932</Option>
                                                <Option value="1931">1931</Option>
                                                <Option value="1930">1930</Option>
                                                <Option value="1929">1929</Option>
                                                <Option value="1928">1928</Option>
                                                <Option value="1927">1927</Option>
                                                <Option value="1926">1926</Option>
                                                <Option value="1925">1925</Option>
                                                <Option value="1924">1924</Option>
                                                <Option value="1923">1923</Option>
                                                <Option value="1922">1922</Option>
                                                <Option value="1921">1921</Option>
                                                <Option value="1920">1920</Option>
                                                <Option value="1919">1919</Option>
                                                <Option value="1918">1918</Option>
                                                <Option value="1917">1917</Option>
                                                <Option value="1916">1916</Option>
                                                <Option value="1915">1915</Option>
                                                <Option value="1914">1914</Option>
                                                <Option value="1913">1913</Option>
                                                <Option value="1912">1912</Option>
                                                <Option value="1911">1911</Option>
                                                <Option value="1910">1910</Option>
                                                <Option value="1909">1909</Option>
                                                <Option value="1908">1908</Option>
                                                <Option value="1907">1907</Option>
                                                <Option value="1906">1906</Option>
                                                <Option value="1905">1905</Option>
                                                <Option value="1904">1904</Option>
                                                <Option value="1903">1903</Option>
                                                <Option value="1902">1902</Option>
                                                <Option value="1901">1901</Option>
                                                <Option value="1900">1900</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Paragraph className='mv-auth-link'>
                                    By clicking Sign Up, you agree to our <Link to="#">Terms</Link>, <Link to="#">Privacy Policy</Link>, and <Link to="#">Cookies Policy</Link>.
                                </Paragraph>
                                <Button type="primary" loading={loading} htmlType="submit">Sign Up</Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SignUp;
