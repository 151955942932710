// Libraries
import { useEffect, useState } from 'react';
import { Card, Typography, Form, Select, Button, Upload, Row, Col, Divider, Input } from 'antd';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { UploadOutlined } from '@ant-design/icons';

// Component
import { getIntroAsync, getPoliticalAffiliation, setIntroAsync, setPoliticalAffiliation } from '../../../../shared/asyncstorage/intro';
import uuidv4 from '../../../../shared/uuidgenerator';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import draftToHtml from 'draftjs-to-html';


// Styles
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import OpenSuccessNotification from '../../../../Notification/successNotif';
import { IMAGE_URL } from '../../../../enviroment/Envirments';
import { imageUpladBase64 } from '../../../../shared/asyncstorage/imgaeUpload';

// Assets

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const Introduction = () => {
    const { token, getUserID } = useToken();

    const [value, setValue] = useState(1);
    const [introLoading, setIntroLoading] = useState(false);
    const [partyLoading, setPartyLoading] = useState(false);
    const [distLoading, setDistLoading] = useState(false);
    const [polAccLoadingOne, setPolAccLoadingOne] = useState(false);
    const [polAccLoadingTwo, setPolAccLoadingTwo] = useState(false);
    const [polAccLoadingThree, setPolAccLoadingThree] = useState(false);
    const [educationLoad, setEducationLoad] = useState(false);
    const [positionLoad, setPositionLoad] = useState(false);
    const [curPositionLoad, setcurPositionLoad] = useState(false);
    const [backgroundLoading, setbackgroundLoading] = useState(false);
    const [attributeOne, setAttributeOne] = useState(false);
    const [attributeTwo, setAttributeTwo] = useState(false);
    const [attributeThree, setAttributeThree] = useState(false);


    const [form] = Form.useForm();
    const [formParty] = Form.useForm();
    const [formDist] = Form.useForm();
    const [formpoliticalAccOne] = Form.useForm();
    const [formpoliticalAccTwo] = Form.useForm();
    const [formpoliticalAccThree] = Form.useForm();
    const [formEducation] = Form.useForm();
    const [formPosition] = Form.useForm();
    const [formAttributeOne] = Form.useForm();
    const [formAttributeTwo] = Form.useForm();
    const [formAttributeThree] = Form.useForm();
    const [curPositionForm] = Form.useForm();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)

    const [fileList, setFileList] = useState([]);
    const [fileListTwo, setFileListTwo] = useState([]);
    const [fileListThree, setFileListThree] = useState([]);

    const dispatcher = useShareDispatch();

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [educationState, setEducationState] = useState(
        () => EditorState.createEmpty(),
    );

    useEffect(() => {

        let userCandidateExtensionBase = loginUserObject?.userCandidateExtension?.userCandidateExtensionBase

        let content = loginUserObject?.userCandidateExtension
        if (content?.candidateAttributeOneContent?.contentId) {
            setFileList([{
                url: IMAGE_URL + content?.candidateAttributeOneContent?.contentId
            }])
        }
        if (content?.candidateAttributeTwoContent?.contentId) {
            setFileListTwo([{
                url: IMAGE_URL + content?.candidateAttributeTwoContent?.contentId,
            }])
        }

        if (content?.candidateAttributeThreeContent?.contentId) {
            setFileListThree([{
                url: IMAGE_URL + content?.candidateAttributeThreeContent?.contentId
            }])
        }
        form.setFieldsValue({
            videoTitle: loginUserObject?.userCandidateExtension?.introductoryVideoLink?.linkTitle,
            videoLink: loginUserObject?.userCandidateExtension?.introductoryVideoLink?.linkUrl,
        });
        formDist.setFieldsValue({
            districtAddress: userCandidateExtensionBase?.districtAddress ? userCandidateExtensionBase?.districtAddress : null,
            districtCity: userCandidateExtensionBase?.districtCity ? userCandidateExtensionBase?.districtCity : null,
            districtState: userCandidateExtensionBase?.districtState ? userCandidateExtensionBase?.districtState : null,
            districtZip: userCandidateExtensionBase?.districtZip ? userCandidateExtensionBase?.districtZip : null,
        });
        formParty.setFieldsValue({
            politicalParty: loginUserObject?.userBase?.party
        });
        formpoliticalAccOne.setFieldsValue({
            politicalAccomplishment: userCandidateExtensionBase?.politicalAccomplishmentOne,
            index: 1
        });
        formpoliticalAccTwo.setFieldsValue({
            politicalAccomplishment: userCandidateExtensionBase?.politicalAccomplishmentTwo,
            index: 2
        });
        formpoliticalAccThree.setFieldsValue({
            politicalAccomplishment: userCandidateExtensionBase?.politicalAccomplishmentThree,
            index: 3
        });
        formpoliticalAccThree.setFieldsValue({
            politicalAccomplishment: userCandidateExtensionBase?.politicalAccomplishmentThree,
            index: 3
        });

        formAttributeOne.setFieldsValue({
            title: userCandidateExtensionBase?.candidateAttributeOneTitle,
            description: userCandidateExtensionBase?.candidateAttributeOneDescription,
            index: 1
        });
        formAttributeTwo.setFieldsValue({
            title: userCandidateExtensionBase?.candidateAttributeTwoTitle,
            description: userCandidateExtensionBase?.candidateAttributeTwoDescription,
            index: 2
        });
        formAttributeThree.setFieldsValue({
            title: userCandidateExtensionBase?.candidateAttributeThreeTitle,
            description: userCandidateExtensionBase?.candidateAttributeThreeDescription,
            index: 3
        });
        formPosition.setFieldsValue({
            position: userCandidateExtensionBase?.position ? userCandidateExtensionBase?.position : null,
        });
        curPositionForm.setFieldsValue({
            hasCurrentPoliticalPosition: userCandidateExtensionBase?.hasCurrentPoliticalPosition,
            currentPoliticalPosition: userCandidateExtensionBase?.currentPoliticalPosition ? userCandidateExtensionBase?.currentPoliticalPosition : null,
            currentPoliticalPositionDuration: userCandidateExtensionBase?.currentPoliticalPositionDuration ? userCandidateExtensionBase?.currentPoliticalPositionDuration : null,
        });
        if (userCandidateExtensionBase?.education) {
            const contentBlocks = convertFromHTML(JSON.parse(userCandidateExtensionBase?.education));
            const contentState = ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
            setEducationState(EditorState.createWithContent(contentState));
        }

        if (userCandidateExtensionBase?.background) {
            const contentBlocks = convertFromHTML(JSON.parse(userCandidateExtensionBase?.background));
            const contentState = ContentState.createFromBlockArray(
                contentBlocks.contentBlocks,
                contentBlocks.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }

    }, [loginUserObject])



    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.politicalParty) {
            setPartyLoading(true)
            interceptor.axiosPut("PUT", "/users/political-party-affiliations", values, token).then(res => {
                setPartyLoading(false)
                loadapi()
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setPartyLoading(false)

            })

        }
    };

    const loadapi = () => {
        let model = {
            userId: getUserID(),
            token: token
        }
        dispatcher(actionsApi.getUser(model))
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishIntro = (values) => {
        console.log('Success:', values);
        if (values) {
            setIntroLoading(true)
            interceptor.axiosPut("PUT", "/users/introductory-videos", values, token).then(res => {
                setIntroLoading(false)
                OpenSuccessNotification("topRight", "Successfully Added")
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setIntroLoading(false)
            })

        }

    };

    const onFinishFailedIntro = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const onFinishIDistrict = (values) => {
        console.log('Success:', values);
        if (values) {
            setDistLoading(true)
            interceptor.axiosPut("PUT", "/users/candidate-district-address", values, token).then(res => {
                if (res.status == 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    setDistLoading(false)
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setDistLoading(false)
            })

        }

    };

    const onFinishFailedDistrict = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const onFinishIPoliticalAcc = (values) => {
        console.log('Success:', values);
        if (values) {
            values.index == 1 ? setPolAccLoadingOne(true) : values.index == 2 ? setPolAccLoadingTwo(true) : setPolAccLoadingThree(true)
            interceptor.axiosPut("PUT", "/users/candidate-political-accomplishment", values, token).then(res => {
                if (res.status == 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    setPolAccLoadingOne(false)
                    setPolAccLoadingTwo(false)
                    setPolAccLoadingThree(false)
                }

            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setPolAccLoadingOne(false)
                setPolAccLoadingTwo(false)
                setPolAccLoadingThree(false)
            })

        }

    };

    const onFinishFailedPoliticalAcc = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishEducation = (values) => {
        console.log('Success:', values);
        if (values) {
            const rawContentState = draftToHtml(convertToRaw(educationState.getCurrentContent()));
            setEducationLoad(true)
            interceptor.axiosPut("PUT", "/users/candidate-education", {
                education: JSON.stringify(rawContentState)
            }, token).then(res => {
                if (res.status == 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    setEducationLoad(false)
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setEducationLoad(false)

            })

        }

    };


    const onFinishFailedEducation = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishbackground = (values) => {
        console.log('Success:', values);
        if (values) {

            const rawContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            setbackgroundLoading(true)
            interceptor.axiosPut("PUT", "/users/candidate-background", {
                background: JSON.stringify(rawContentState)
            }, token).then(res => {
                if (res.status == 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    setbackgroundLoading(false)
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setbackgroundLoading(false)

            })

        }

    }; const onFinishFailedbackground = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishPosition = (values) => {
        console.log('Success:', values);
        if (values) {
            setPositionLoad(true)
            interceptor.axiosPut("PUT", "/users/candidate-position", values, token).then(res => {
                OpenSuccessNotification("topRight", "Successfully Added")
                setPositionLoad(false)
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setPositionLoad(false)

            })

        }

    };

    const onFinishFailedPosition = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishCurPosition = (values) => {
        console.log('Success:', values);
        if (values) {
            setcurPositionLoad(true)
            interceptor.axiosPut("PUT", "/users/update-current-political-position", values, token).then(res => {
                setcurPositionLoad(false)
                OpenSuccessNotification("topRight", "Successfully Added")
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setcurPositionLoad(false)
            })

        }

    };

    const onFailedCurPosition = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishAttribute = (values) => {
        console.log('Success:', values);
        if (values) {
            values.index == 1 ? setAttributeOne(true) : values.index == 2 ? setAttributeTwo(true) : setAttributeThree(true)
            values.attributeContentId = values.index
            interceptor.axiosPut("PUT", "/users/candidate-attribute", values, token).then(res => {
                if (res.status == 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    setAttributeOne(false)
                    setAttributeTwo(false)
                    setAttributeThree(false)
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setAttributeOne(false)
                setAttributeTwo(false)
                setAttributeThree(false)
            })

        }

    };

    const onFinishFailedAttribute = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    const onChange = ({ fileList: newFileList }, type) => {
        if (newFileList.length > 0) {
            const file = newFileList[0].originFileObj
            imageUpladBase64(file, type, token, (res) => {

                if (type == "candidate_attribute_one_photo") {
                    setFileList([{
                        url: IMAGE_URL + res.data?.contentId,
                    }])
                }
                if (type == "candidate_attribute_two_photo") {
                    setFileListTwo([{
                        url: IMAGE_URL + res.data?.contentId,
                    }])
                }
                if (type == "candidate_attribute_three_photo") {
                    setFileListThree([{
                        url: IMAGE_URL + res.data?.contentId,
                    }])
                }
                OpenSuccessNotification("topRight", "Photo Uploaded Successfully")
            })
        } else {
            if (type == "candidate_attribute_one_photo") {
                setFileList(newFileList)
            }
            if (type == "candidate_attribute_two_photo") {
                setFileListTwo(newFileList)
            }
            if (type == "candidate_attribute_three_photo") {
                setFileListThree(newFileList)
            }
        }
    }

    return (
        <>
            <div className='mv-form-fields mv-mt-20 mv-input-background'>
                <Title level={2} className="mv-form-title">Political Party Affiliation</Title>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={formParty}
                >
                    <Card>
                        <Form.Item
                            // label="Political Party Affiliation"
                            name="politicalParty"
                            className='mv-mb-0'
                        >
                            <Select placeholder="Select Political Party Affiliation">
                                <Option value="republican">Republican</Option>
                                <Option value="democrat">Democrat</Option>
                                <Option value="independent">Independent</Option>
                            </Select>
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={partyLoading} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2} className="mv-form-title">Current Political Position</Title>
                <Form
                    onFinish={onFinishCurPosition}
                    onFinishFailed={onFailedCurPosition}
                    autoComplete="off"
                    layout="vertical"
                    form={curPositionForm}
                >
                    <Card>
                        <Row gutter={[20]}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="hasCurrentPoliticalPosition"
                                    className='mv-mb-0'
                                >
                                    <Select placeholder="Do you have political position?">
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[20]}>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="currentPoliticalPosition"
                                    className='mv-mt-20 mv-mb-0'
                                >
                                    <Input placeholder="What Position?" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <Form.Item
                                    name="currentPoliticalPositionDuration"
                                    className='mv-mt-20 mv-mb-0'
                                >
                                    <Input placeholder="Current Position Duration?" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={curPositionLoad} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30'>
                <Title level={2} className="mv-form-title">Background</Title>
                <Form
                    onFinish={onFinishbackground}
                    onFinishFailed={onFinishFailedbackground}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Card>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            placeholder='Please Add Background'
                        />
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={backgroundLoading} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30'>
                <Title level={2} className="mv-form-title">Education</Title>
                <Form
                    onFinish={onFinishEducation}
                    onFinishFailed={onFinishFailedEducation}
                    autoComplete="off"
                    layout="vertical"
                    form={formEducation}
                >
                    <Card>
                        <Editor
                            editorState={educationState}
                            onEditorStateChange={setEducationState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            placeholder='Please Add Education'

                        />
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={educationLoad} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2}>Position</Title>
                <Form
                    onFinish={onFinishPosition}
                    onFinishFailed={onFinishFailedPosition}
                    autoComplete="off"
                    layout="vertical"
                    form={formPosition}
                >
                    <Card>

                        <Form.Item
                            // label="Position"
                            name="position"
                            className='mv-mb-0'
                        >
                            <Select placeholder="Select Position">
                                <Option value="mayor">Mayor</Option>
                                <Option value="mayor-candidate">Mayor Candidate</Option>
                                <Option value="city-council">City Council</Option>
                                <Option value="city-council-candidate">City Council Candidate</Option>
                                <Option value="city-commisioner">City Commisioner</Option>
                                <Option value="city-commisioner-candidate">City Commisioner Candidate</Option>
                                <Option value="register-of-wills">Register Of Wills</Option>
                                <Option value="register-of-wills-candidate">Register Of Wills Candidate</Option>
                                <Option value="sheriff">Sheriff</Option>
                                <Option value="sheriff-candidate-position">Sheriff Candidate</Option>
                                <Option value="city-controller">City Controller</Option>
                                <Option value="city-controller-candidate">City Controller Candidate</Option>
                                <Option value="court-of-common-pleas">Court Of Common Pleas</Option>
                                <Option value="court-of-common-pleas-candidate">Court Of Common Pleas Candidate</Option>
                                <Option value="municipal-court">Municipal Court</Option>
                                <Option value="municpal-court-candidate">Municipal Court Candidate</Option>
                            </Select>
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={positionLoad} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2}>District Location</Title>
                <Form
                    onFinish={onFinishIDistrict}
                    onFinishFailed={onFinishFailedDistrict}
                    autoComplete="off"
                    layout="vertical"
                    form={formDist}
                >
                    <Card>
                        <Form.Item
                            name="districtAddress"
                            className='mv-mb-0'
                        >
                            <Input placeholder="Address" />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item name="districtState" className="mv-mt-20 mv-mb-0"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'required',
                                        },
                                    ]}
                                >
                                    <Select placeholder="State">
                                        <Option value="Pennsylvania">Pennsylvania</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item name="districtCity" className="mv-mt-20 mv-mb-0"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'required',
                                        },
                                    ]}>
                                    <Select placeholder="City">
                                        <Option value="Aliquippa">Aliquippa</Option>
                                        <Option value="Allentown">Allentown</Option>
                                        <Option value="Altoona">Altoona</Option>
                                        <Option value="Arnold">Arnold</Option>
                                        <Option value="Beaver Falls">Beaver Falls</Option>
                                        <Option value="Bethlehem">Bethlehem</Option>
                                        <Option value="Bloomsburg">Bloomsburg</Option>
                                        <Option value="Bradford">Bradford</Option>
                                        <Option value="Butler">Butler</Option>
                                        <Option value="Carbondale">Carbondale</Option>
                                        <Option value="Chester">Chester</Option>
                                        <Option value="Clairton">Clairton</Option>
                                        <Option value="Coatesville">Coatesville</Option>
                                        <Option value="Connellsville">Connellsville</Option>
                                        <Option value="Corry">Corry</Option>
                                        <Option value="Du Bois">Du Bois</Option>
                                        <Option value="Duquesne">Duquesne</Option>
                                        <Option value="Easton">Easton</Option>
                                        <Option value="Erie">Erie</Option>
                                        <Option value="Farrell">Farrell</Option>
                                        <Option value="Franklin">Franklin</Option>
                                        <Option value="Greensburg">Greensburg</Option>
                                        <Option value="Harrisburg">Harrisburg</Option>
                                        <Option value="Hermitage">Hazleton</Option>
                                        <Option value="Jeannette">Jeannette</Option>
                                        <Option value="Lancaster">Lancaster</Option>
                                        <Option value="Latrobe">Latrobe</Option>
                                        <Option value="Lebanon">Lebanon</Option>
                                        <Option value="Lock Haven">Lock Haven</Option>
                                        <Option value="Lower Burrell">Lower Burrell</Option>
                                        <Option value="McCandless">McCandless</Option>
                                        <Option value="McKeesport">McKeesport</Option>
                                        <Option value="Meadville">Meadville</Option>
                                        <Option value="Monessen">Monessen</Option>
                                        <Option value="Monongahela">Monongahela</Option>
                                        <Option value="New Castle">New Castle</Option>
                                        <Option value="New Kensington">New Kensington</Option>
                                        <Option value="Oil City">Oil City</Option>
                                        <Option value="Parker">Parker</Option>
                                        <Option value="Philadelphia">Philadelphia</Option>
                                        <Option value="Pittsburgh">Pittsburgh</Option>
                                        <Option value="Pittston">Pittston</Option>
                                        <Option value="Pottsville">Pottsville</Option>
                                        <Option value="Reading">Reading</Option>
                                        <Option value="St. Marys">St. Marys</Option>
                                        <Option value="Scranton">Scranton</Option>
                                        <Option value="Shamokin">Shamokin</Option>
                                        <Option value="Sharon">Sharon</Option>
                                        <Option value="Sunbury">Sunbury</Option>
                                        <Option value="Titusville">Titusville</Option>
                                        <Option value="Warren">Warren</Option>
                                        <Option value="Washington">Washington</Option>
                                        <Option value="Wilkes-Barre">Wilkes-Barre</Option>
                                        <Option value="Williamsport">Williamsport</Option>
                                        <Option value="York">York</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item
                                    name="districtZip"
                                    className='mv-mt-20 mv-mb-0'
                                >
                                    <Input placeholder="Zip" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={distLoading} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2}>Political Accomplishment #1</Title>
                <Form
                    onFinish={onFinishIPoliticalAcc}
                    onFinishFailed={onFinishFailedPoliticalAcc}
                    autoComplete="off"
                    layout="vertical"
                    form={formpoliticalAccOne}
                >
                    <Card>
                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="politicalAccomplishment"
                            className='mv-mb-0'
                        >
                            <TextArea placeholder="Political Accomplishment #1" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={polAccLoadingOne} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2}>Political Accomplishment #2</Title>
                <Form
                    onFinish={onFinishIPoliticalAcc}
                    onFinishFailed={onFinishFailedPoliticalAcc}
                    autoComplete="off"
                    layout="vertical"
                    form={formpoliticalAccTwo}
                >
                    <Card>

                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="politicalAccomplishment"
                            className='mv-mb-0'
                        >
                            <TextArea placeholder="Political Accomplishment #2" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={polAccLoadingTwo} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-input-background'>
                <Title level={2}>Political Accomplishment #3</Title>
                <Form
                    onFinish={onFinishIPoliticalAcc}
                    onFinishFailed={onFinishFailedPoliticalAcc}
                    autoComplete="off"
                    layout="vertical"
                    form={formpoliticalAccThree}
                >
                    <Card>

                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="politicalAccomplishment"
                            className='mv-mb-0'
                        >
                            <TextArea placeholder="Political Accomplishment #3" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={polAccLoadingThree} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-attribute mv-input-background'>
                <Title level={2}>Candidate Attribute #1</Title>
                <Form
                    onFinish={onFinishAttribute}
                    onFinishFailed={onFinishFailedAttribute}
                    autoComplete="off"
                    layout="vertical"
                    form={formAttributeOne}
                >
                    <Card >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            onChange={(file) => onChange(file, "candidate_attribute_one_photo")}
                            fileList={fileList}
                            maxCount={1}
                            beforeUpload={(file) => {
                                return false
                            }}
                        // defaultFileList={[...fileList]}
                        >
                            <Button icon={<UploadOutlined />}>Upload Candidate Image</Button>

                        </Upload>
                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="title"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <Input placeholder="Candidate title" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <TextArea placeholder="Candidate text" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={attributeOne} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-attribute mv-input-background'>
                <Title level={2}>Candidate Attribute #2</Title>
                <Form
                    onFinish={onFinishAttribute}
                    onFinishFailed={onFinishFailedAttribute}
                    autoComplete="off"
                    layout="vertical"
                    form={formAttributeTwo}
                >
                    <Card>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            onChange={(file) => onChange(file, "candidate_attribute_two_photo")}
                            fileList={fileListTwo}
                            maxCount={1}
                            beforeUpload={(file) => {
                                return false
                            }}
                        // defaultFileList={[...fileList]}
                        >
                            <Button icon={<UploadOutlined />}>Upload Candidate Image</Button>
                        </Upload>
                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="title"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <Input placeholder="Candidate title" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <TextArea placeholder="Candidate text" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={attributeTwo} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-mb-30 mv-attribute mv-input-background'>
                <Title level={2}>Candidate Attribute #3</Title>
                <Form
                    onFinish={onFinishAttribute}
                    onFinishFailed={onFinishFailedAttribute}
                    autoComplete="off"
                    layout="vertical"
                    form={formAttributeThree}
                >
                    <Card>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            onChange={(file) => onChange(file, "candidate_attribute_three_photo")}
                            fileList={fileListThree}
                            maxCount={1}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            <Button icon={<UploadOutlined />}>Upload Candidate Image</Button>
                        </Upload>
                        <Form.Item className='mv-dn' name="index" >
                        </Form.Item>
                        <Form.Item
                            name="title"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <Input placeholder="Candidate title" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            className='mv-mt-20 mv-mb-15'
                        >
                            <TextArea placeholder="Candidate text" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={attributeThree} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
            <Divider />
            <div className='mv-form-fields mv-input-background'>
                <Title level={2}>Introductory Video</Title>
                <Form
                    onFinish={onFinishIntro}
                    onFinishFailed={onFinishFailedIntro}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                >
                    <Card>

                        <Form.Item
                            name="videoTitle"
                            className='mv-mb-15'
                        >
                            <Input placeholder="Video Title" />
                        </Form.Item>
                        <Form.Item
                            name="videoLink"
                            className='mv-mb-0'
                        >
                            <Input placeholder="Video Link" />
                        </Form.Item>
                    </Card>
                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={introLoading} className='ant-btn-44'>Save</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Introduction;
