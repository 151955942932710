// Libraries
import { Typography, Image, Spin } from 'antd';
import Slider from "react-slick";

// Component
import { AuthSliderData } from '../../data/AuthSliderData';

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

// Assets
import { PrevArrowIcon, NextArrowIcon } from '../../assets/icons/icons';

const { Title, Paragraph } = Typography;

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><NextArrowIcon /></div>
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><PrevArrowIcon /></div>
    );
}

const AuthSlider = () => {

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <div className='mv-auth-slider'>
            <div className='mv-auth-slide'>
                <Slider {...settings}>
                    {AuthSliderData.length ? AuthSliderData.map((item, index) => (
                        <div key={index}>
                            <Image src={item.img} preview={false} alt={item.title} />
                            <Title level={3}>{item.title}</Title>
                            <Paragraph>{item.description}</Paragraph>
                        </div>
                    )) : <div className="mv-spinner">
                        <Spin size="large" />
                    </div>}
                </Slider>
            </div>
        </div>
    )
}

export default AuthSlider;
