// Libraries
import { Typography, Card, Image } from 'antd';
import { useEffect } from 'react';

// Component
// import { ConnectionsData } from '../../data/ConnectionsData';
import { IMAGE_URL } from '../../enviroment/Envirments';
import { useShareDispatch, useShareSelector } from '../../shared';
import interceptor from '../../shared/interceptor';

// Styles
import './style.css';

// Assets
import userImg from '../../assets/images/user.png'
import useToken from '../../hooks/useToken';
import { useNavigate } from 'react-router-dom';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';
import OpenErrorNotification from '../../Notification/errorNotif';

const { Title, Paragraph } = Typography;

const Connections = props => {
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    let ConnectionsData = loginUserObject?.connections ? loginUserObject?.connections : []
    const { token, } = useToken();
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    useEffect(() => {


    }, []);

    const getPublicUserDetail = async (id) => {
        let model = {
            token: token,
            userId: id
        }
        
        interceptor.axiosGet("Get", "/users/" + id + "/public", token).then(res => {
            if (res.status == 200) {
                dispatcher(gettingPublicResponseSuccess(res.data))
                let user = res.data?.userBase
                if (user?.userType == "CANDIDATE") {
                    navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                } else {
                    navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                }

            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
                OpenErrorNotification("topRight", res.detailMessageStr)
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }

    return (
        <div className='mv-connections'>
            <div className='mv-text-header mv-d-flex'>
                <Title level={2}>{props.title}</Title>
                <div className='mv-count'>{ConnectionsData.length}</div>
            </div>
            <Card>
                {ConnectionsData.length > 0 ? ConnectionsData.map((item, index) => (
                    <div className='mv-user-content mv-d-flex mv-status' key={index} onClick={() => getPublicUserDetail(item?.connectionBase?.connectedUserTwo)}>
                        <div className='mv-connection-img'>
                            <Image className="mv-img-35" src={item?.connectedUserTwoProfilePicture?.contentId ? IMAGE_URL + item?.connectedUserTwoProfilePicture?.contentId : userImg} alt="" preview={false} />
                        </div>
                        <div className='mv-user-meta'>
                            <Paragraph><span>{item.userTwoUserName}</span></Paragraph>
                        </div>
                    </div>
                ))
                    :
                    <div className='mv-user-content mv-d-flex'>
                        <Paragraph>No Connection</Paragraph>
                    </div>

                }
            </Card>
        </div>
    )
}

export default Connections;
