// Libraries
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin, Button } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { AppLayout } from '../../components';
import NewProfileBanner from '../../components/NewProfileBanner';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Styles
import './style.css';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import CheckoutForm from '../checkOut';


const Subscriptions = () => {
    const { REACT_APP_STRIPE_KEY } = process.env
    debugger
    const [isUpdating, setIsUpdating] = useState(false)
    const [loading, setloading] = useState(false)
    const { token } = useToken();
    const [model, setModel] = useState(null);
    // let stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
    // let elements = stripe.elements();
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const [item, setItem] = useState(null);
    const [options, setOption] = useState('');

    const getSubscriptionID = (values) => {
        setloading(true)
        setItem(values)

        let priceId =
        {
            "priceId": values.priceId
        }
        interceptor.axiosPost("Post", "/subscriptions", priceId, token).then(res => {
            if (res.status = 200) {
                debugger
                let CLIENT_SECRET = res.data.clientSecret
                setloading(false)

                setOption({
                    // passing the client secret obtained from the server
                    clientSecret: res?.data?.clientSecret
                })
                setClientSecret(res?.data?.clientSecret)

            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);

        })
    }

    const removeSubscription = (item) => {
        let subscriptionId = {
            "subscriptionId": item.subscriptionId
        }
        interceptor.axiosDelete("Delete", "/subscriptions", subscriptionId, token).then(res => {
            if (res.status = 200) {
                setloading(false)
                getEnhancedProduct()

            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);

        })
    }

    const getEnhancedProduct = () => {
        interceptor.axiosGet("Get", "/enhanced-products", token).then(res => {
            setloading(false)
            if (res.status = 200) {
                setModel(res.data)
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);

        })
    }


    useEffect(() => {
        setStripePromise(loadStripe(REACT_APP_STRIPE_KEY))
    }, [])
    useEffect(() => {
        if (!model) {
            setloading(true)
            getEnhancedProduct()
        }

    }, [])

    return (
        // <AppLayout>
        <main className='mv-subscriptions'>
            {/* <NewProfileBanner /> */}
            {loading ? <div className='mv-d-flex mv-jc-center mv-mt-20'><Spin></Spin></div> :
                <>
                    {!clientSecret && <Row gutter={30}>
                        {model && model.map(item => {
                            return (
                                <Col xs={24} sm={12} className={"!item.enable? mv-inactive: '' "} style={{ display: 'block' }}>
                                    <Card style={{ height: '90%', alignItems: 'center', display: 'grid' }}>
                                        <h2>{item.productTitle}</h2>
                                        <h3>{item.priceDescription}</h3>
                                        {item.subscriptionEndDate > 0 && <h2> {item.cancelled ? 'End Date' : 'Renewal Date'}:<br /> {new Date(item.subscriptionEndDate).toLocaleDateString('en-US')}</h2>}
                                        {item.subscribed ?
                                            <button disabled={item.cancelled} onClick={() => removeSubscription(item)}
                                                className='ant-btn ant-btn-danger ant-btn-44'
                                                state={{ width: "100%" }}>
                                                {item.cancelled ? ' Cancelled' : ' Cancel Subscription'}
                                            </button>
                                            :
                                            <Link href="#" onClick={() => getSubscriptionID(item)} className="ant-btn ant-btn-primary ant-btn-44">
                                                Subscribe
                                            </Link>}
                                    </Card>
                                </Col>
                            )
                        })
                        }
                        {/* <Col xs={24} sm={12}>
                        <Card>
                            <h2>Councilman Candidate Subscription</h2>
                            <h3><span>$</span>15,00</h3>
                            <Link href="#" onClick={getSubscriptionID} className="ant-btn ant-btn-primary ant-btn-44">
                                Subscribe
                            </Link>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Card className='mv-inactive'>
                            <h2>Mayor Candidate Subscription</h2>
                            <h3><span>$</span>15,00</h3>
                            <Button type="primary" onClick={getSubscriptionID} className="ant-btn-44">
                                Subscribe
                            </Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Card>
                            <h2>Councilman Candidate Subscription</h2>
                            <h3><span>$</span>15,00</h3>
                            <Button type="primary" onClick={getSubscriptionID} className="ant-btn-44">
                                Subscribe
                            </Button>
                        </Card>
                    </Col> */}

                    </Row>
                    }
                </>
            }
            <div className="mv-mt-20">
                {stripePromise && clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm item={item} />
                    </Elements>
                )}
            </div>
        </main >
        //   </AppLayout> 
    )
}

export default Subscriptions;
