// Libraries
import React from 'react';
import { AppLayout } from '../../components';
import CurrentUserProfile from '../../components/CurrentUserProfile';

// Components

// Styles
// import './style.css';

// Assets

const CompletionPage = () => {
    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700 mv-subscriptions'>
                <CurrentUserProfile />
                <h1 className='mb-5'>Thank You</h1>
                <p>Your subscription is confirmed. </p>
                <p>You have Subscribe  successfully </p>
            </main>
        </AppLayout>
    )
}

export default CompletionPage;
