import { notification } from 'antd';

const OpenSuccessNotification = (placement, desciption, message) => {
    notification.success({
        message: "Success",
        description: desciption,
        placement,
    });
}


export default OpenSuccessNotification;
