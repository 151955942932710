import axios from 'axios'
// import { API_BASE_URL } from '../enviroment/Envirments'
const { REACT_APP_API_BASE_URL } = process.env
export const baseUrl = `${REACT_APP_API_BASE_URL}`
console.log(baseUrl)
const interceptor = {
    axiosPost: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            //data: new URLSearchParams(data),
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosPut: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            // headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosGet: (method, urlPath, token, data) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosGetWithParams: (method, urlPath, token, data) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
            params: data,
        })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response.data
            })
    },

    axiosDelete: (method, urlPath, data, token) => {
        return axios({
            method: method,
            url: `${baseUrl}${urlPath}`,
            data: data,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },

    axiosGetCredentials: (method, urlPath) => {
        const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_SCOPE, REACT_APP_CLIENT_CREDENTIALS } = process.env
        const body = {
            client_id: REACT_APP_CLIENT_ID,
            client_secret: REACT_APP_CLIENT_SECRET,
            scope: REACT_APP_SCOPE,
        }
        const params = { grant_type: REACT_APP_CLIENT_CREDENTIALS }

        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(body),
            params,
        })
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },

    axiosGetCredentialsLogin: (method, urlPath, data) => {
        const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_SCOPE, REACT_APP_PASSWORD } = process.env
        const body = {
            client_id: REACT_APP_CLIENT_ID,
            client_secret: REACT_APP_CLIENT_SECRET,
            scope: REACT_APP_SCOPE,
            primary_email: data.primary_email,
            password: data.password,
        }
        const params = { grant_type: REACT_APP_PASSWORD }

        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(body),
            params,
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                return err
            })
    },

    axiosRefreshToken: (method, urlPath, refreshToken) => {
        const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_REFRESH_TOKEN } = process.env
        const body = {
            // client_id: REACT_APP_CLIENT_ID,
            client_id: refreshToken.client_id,
            // client_secret: REACT_APP_CLIENT_SECRET,
            // refresh_token: refreshToken,
            refresh_token: refreshToken.refresh_token,

        }
        const params = { grant_type: REACT_APP_REFRESH_TOKEN }

        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(body),
            params,
        })
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },
    axiosSignInWithGoogle: (method, urlPath, google_id_token) => {
        const { REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET, REACT_APP_SCOPE, REACT_APP_GOOGLE_GRANT_TYPE } = process.env
        const body = {
            client_id: REACT_APP_CLIENT_ID,
            client_secret: REACT_APP_CLIENT_SECRET,
            scope: REACT_APP_SCOPE,
            google_id_token: google_id_token,
        }
        const params = { grant_type: REACT_APP_GOOGLE_GRANT_TYPE }

        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(body),
            params,
        })
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                return err
            })
    },

    // Post Chunks Upload Porfile Picture
    axiosUploadProfilePic: (method, urlPath, data, token) => {
        return axios({
            url: `${baseUrl}${urlPath}`,
            method: method,
            data: new URLSearchParams(data),
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                if (err.response) throw err.response.data
                else if (err.request) throw err.request
                else throw new Error(`Error: ${err.message}`)
            })
    },
}

export default interceptor
