import { updateLocalStorage } from './localStorage'
var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setLinksAsync = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].links.push(array)
    user.links = userList[index].links
    updateLocalStorage(user, userList)

}
export const getLinksAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.links
    return items ? items : []
}

export const getLinksLS = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.links;
    return items ? items : null
}


export const deleteLinksAsync = (array) => {
    userList[index].links = array
    user.links = array
    updateLocalStorage(user, userList)
}


export const updatelinksAsync = (array) => {
    userList[index].links = array
    user.links = array
    updateLocalStorage(user, userList)
}

