import { updateLocalStorage } from './localStorage'
var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setSpeechesIntrestAsync = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].speechesCandidate.push(array)
    user.speechesCandidate= userList[index].speechesCandidate
    updateLocalStorage(user, userList)

}
export const getSpeechesIntrestAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.speechesCandidate
    //JSON.parse(localStorage.getItem('@politicalIntrest'));
    return items ? items : []
}

export const getSpeechesIntrestLS = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.speechesCandidate;
    return items ? items : null
}

export const deleteSpeechesIntrestAsync = (array) => {
    userList[index].speechesCandidate = array
    user.speechesCandidate = array
    updateLocalStorage(user, userList)
}


export const updateSpeechesIntrestAsync = (array) => {
    userList[index].speechesCandidate = array
    user.speechesCandidate = array
    updateLocalStorage(user, userList)
}

