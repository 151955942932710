import axios from "axios";
import OpenErrorNotification from "../../Notification/errorNotif";
import OpenSuccessNotification from "../../Notification/successNotif";

const { REACT_APP_API_BASE_URL } = process.env
const accesToken = (type) => {
  return new Promise((resolve, reject) => {
    // const body = {
    //   client_id: "Ellis",
    //   client_secret: "test",
    //    scope: "none",
    // };
    let grant_type = type ? "client_credentials" : "password"
    axios({
      url: `${REACT_APP_API_BASE_URL}/oauth2/token?grant_type=client_credentials`,
      method: "POST",
      headers: {
        Authorization: `Basic ` + btoa("ellis:test"),
        "Content-Type": "application/json",
      },
      //  data: new URLSearchParams(body),
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log("err?.response?.data?.error", err?.response);
        reject(err?.response);
      });
  });
};



const getUserLevelSerAccessToken = (body) => {
  console.log(REACT_APP_API_BASE_URL)
  return new Promise((resolve, reject) => {
    // const body = {
    //   client_id: "Ellis",
    //   client_secret: "test",
    //    scope: "none",
    // };
    axios({
      url: `${REACT_APP_API_BASE_URL}/oauth2/token?grant_type=password`,
      method: "POST",
      headers: {
        Authorization: `Basic ` + btoa("ellis:test"),
        Authorization2: `Basic ` + btoa(body.username + ":" + body.password),
        "Content-Type": "application/x-www-form-urlencoded",
      },

    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log("err?.response?.data?.error", err?.response);
        reject(err?.response);
      });
  });
};

const getUSerAccessToken = (body) => {
  console.log(REACT_APP_API_BASE_URL)
  return new Promise((resolve, reject) => {
    // const body = {
    //   client_id: "Ellis",
    //   client_secret: "test",
    //    scope: "none",
    // };
    axios({
      url: `${REACT_APP_API_BASE_URL}/oauth2/token?grant_type=password`,
      method: "POST",
      headers: {
        Authorization: `Basic ` + btoa("ellis:test"),
        Authorization2: `Basic ` + btoa(body.username + ":" + body.password),
        "Content-Type": "application/json",
      },
      data: body,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        console.log("err?.response?.data?.error", err?.response);
        reject(err?.response);
      });
  });
};

const refreshToken = (token) => {
  // return new Promise((resolve, reject) => {
  //   const body = {
  //     client_id: CLIENT_ID,
  //     client_secret: "wechipn!2021",
  //     scope: "none",
  //     refresh_token: token,
  //   };
  //   axios({
  //     url: `${API_BASE_URL}oauth2/token?grant_type=refresh_token`,
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     data: new URLSearchParams(body),
  //   })
  //     .then((res) => {
  //       resolve(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log("err?.response?.data?.error", err?.response);
  //       reject(err?.response);
  //     });
  // });
};
export { refreshToken, getUSerAccessToken, getUserLevelSerAccessToken, accesToken };
