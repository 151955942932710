// Libraries
import React from 'react';
import { Typography, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import heroImg from '../../../../assets/images/hero.jpg';
import heroLogo from '../../../../assets/images/hero-logo.png';
import useToken from '../../../../hooks/useToken';

const { Title, Paragraph } = Typography;

const Hero = () => {
    const navigate = useNavigate();
    const { token } = useToken();
    const navigateUser = () => {
        if (!token) {
            navigate('/signin');
        } else {
            navigate('/home');

        }
    }
    return (
        <div className='mv-hero'>
            <Image
                src={heroImg}
                className="mv-hero-img"
                preview={false}
                alt="An Interactive Social Platform"
            />
            <div className='mv-container'>
                <div className='mv-hero-content mv-d-flex'>
                    <div className='mv-hero-content-start'>
                        <Title level={1}>An Interactive <span><br /></span>Social Platform</Title>
                        <Paragraph>MyVote is an interactive political non-partisan social media platform that allows users to engage in the political process, on a local and national level.</Paragraph>
                        <span onClick={navigateUser} className='ant-btn ant-btn-danger'>Get Started</span>
                    </div>
                    <div className='mv-hero-content-end'>
                        <Image
                            src={heroLogo}
                            className="mv-hero-logo"
                            alt="My Vote"
                            preview={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;
