// Libraries
import { Typography, Card, Image, Button, Dropdown, Menu, Form, Input, Spin, Popconfirm } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';

import ReactPlayer from 'react-player/lazy';

// Component
import { ProfileImageMap } from '../../data/ProfileImageMap';

// Styles
import './style.css';

// Assets
import {
    CancelIcon,
    SaveIcon,
    DeleteIcon,
    DotsIcon,
    DoubleCheckIcon,
    LikeIcon,
    MessageIcon,
    ShareIcon,
    ImageIcon,
    SendIcon
}
    from '../../assets/icons/icons';
import userImg from '../../assets/images/user.png';
import { useEffect, useState } from 'react';
import { CandidiateArray } from '../../data/CandidateDataJson';
import { IMAGE_URL, VIDEO_URL } from '../../enviroment/Envirments';
import { useShareDispatch, useShareSelector } from '../../shared';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';
import OpenSuccessNotification from '../../Notification/successNotif';
import OpenErrorNotification from '../../Notification/errorNotif';
import { Trash2 } from 'react-feather';

const { Title, Paragraph } = Typography;


// Comment Delete Menu
const commentDelete = (
    <Menu
        items={[
            {
                key: '1',
                icon: <DeleteIcon />,
                label: (
                    <Link to="#">Delete Comment</Link>
                ),
            },
        ]}
    />
);

const Post = props => {
    const { userId } = useParams();
    const [userNameCurrent, setUserName] = useState("")
    const [type, setType] = useState("")
    const [postLikeLoading, setPostLikeloading] = useState("")
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const { token, userID } = useToken();
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    const [form] = Form.useForm();

    const onLikePost = (postId) => {
        setPostLikeloading(true)
        interceptor.axiosPost("Post", "/likes", { postId: postId }, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicPost(token))
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
        })
    }

    const onDeleteLike = (postId) => {
        interceptor.axiosDelete("Delete", "/likes/" + postId, null, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicPost(token))
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
        })
    }

    const onFollowPost = (postUserID) => {
        setPostLikeloading(true)
        interceptor.axiosPost("Post", "/connections", { userIdForConnection: postUserID }, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicPost(token))
                OpenSuccessNotification("topRight", "Request Successfully Sent")
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {

            OpenSuccessNotification("topRight", "Request Successfully Sent")

            console.log("error?.response?.data?.error", error?.response);
        })
    }

    const getPublicUserDetail = async (id) => {
        let model = {
            token: token,
            userId: id
        }
        interceptor.axiosGet("Get", "/users/" + id + "/public", token).then(res => {
            if (res.status == 200) {
                dispatcher(gettingPublicResponseSuccess(res.data))
                let user = res.data?.userBase
                if (user?.userType == "CANDIDATE") {
                    navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                } else {
                    navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                }

            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
                OpenErrorNotification("topRight", res.detailMessageStr)
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }


    const onComment = (comment, Id, callback) => {
        console.log(comment, Id)
        let model = {
            postId: Id,
            comment: comment
        }
        interceptor.axiosPost("Post", "/comments", model, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicPost(token))
                setTimeout(() => {
                    callback(true)
                }, 1500);
            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }

    const deletePost = (postId) => {
        interceptor.axiosDelete("DELETE", "/posts/" + postId, null, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicPost(token))
            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }


    useEffect(() => {

    }, []);
    return (
        <div className='mv-post'>
            <Card>
                <div className='mv-post-header mv-d-flex'>
                    {/* <Link to={user?.userType == "CANDIDATE" ? `/view-candidate/${props?.postUserId}/${user?.userType}/${user?.email}` : `/view-user/${userId}/${type}/${props.profileName}`}> */}
                    <a onClick={() => getPublicUserDetail(props?.postUserId)}>
                        <div className='mv-post-header-meta mv-d-flex'>

                            <div className='mv-post-user-img'>
                                {/* <Link to="#"> */}
                                <Image
                                    className='mv-img-35'
                                    //src={props.profilePic ? props.profilePic : userImg}
                                    src={`${props.profilePic}`}
                                    alt=""
                                    preview={false}
                                />

                            </div>
                            <div className='mv-post-user-meta'>
                                <Title level={2}>
                                    {props.profileName ? props.profileName : "Jhon Doe"}
                                </Title>
                                <Paragraph>{moment(props.time).format('MMMM Do')} at {moment(props.time).format('h:mm A')}</Paragraph>
                            </div>
                        </div>
                    </a>
                    {/* </Link> */}
                    <div className='mv-post-dropdown'>
                        <Dropdown overlay={(
                            <Menu>
                                <Menu.Item key="1">
                                    <CancelIcon />
                                    <Link to="#">  {('Hide Post')}</Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <SaveIcon />
                                    <Link to="#">  {('Save Post')}</Link>
                                </Menu.Item>
                                {props?.postUserId == userID && <Menu.Item key="3" >
                                    <DeleteIcon />
                                    <Link to="#"> onClick={() => deletePost(props?.postId)} {('Delete Post')}</Link>
                                </Menu.Item>}
                            </Menu>
                        )} placement="bottomRight" arrow>
                            <Button>
                                <DotsIcon />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                {
                    props.text && <div className='mv-post-text'>
                        <Paragraph>{props.text}</Paragraph>
                    </div>
                }
                {
                    props.textOne && <div className='mv-post-text'>
                        <Paragraph>{props.textOne}</Paragraph>
                    </div>
                }
                {
                    props.video &&
                    <div className='mv-player-wrapper iframe-loading' style={{ paddingTop: 0 }}>
                        {/* <ReactPlayer
                            className='mv-react-player'
                            url={`${VIDEO_URL}${props.video}`}
                            width='100%'
                            height='100%'
                        /> */}
                        < video width='100%' className=''
                            height='100%' controls>
                            <source
                                src={`${VIDEO_URL}${props.video}`}
                            />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>


                }
                {
                    props.img && <ul className='mv-post-images iframe-loading'>
                        <li className=''>
                            <Image
                                src={`${IMAGE_URL}${props.img}`}
                                alt=""

                            />
                        </li>
                    </ul>
                }
                <ul className='mv-post-comments-list mv-d-flex'>
                    <li>
                        <span >{props?.likes.length} people like this </span>
                    </li>
                    <li>
                        <span>{props?.comments.length} Comments</span>
                    </li>
                    <li>
                        <DoubleCheckIcon />
                        <span>Verified</span>
                    </li>
                </ul>

                <div className='mv-send-post-card mv-d-flex'>
                    <Link to="#">
                        <Image src={props.user ? IMAGE_URL + props.user : userImg} alt="" preview={false} className="mv-img-35" />
                        {/* <Image src={props.profilePic ? props.profilePic : userImg} alt="" preview={false} className="mv-img-35" /> */}
                    </Link>
                    {/* <div className='mv-editable-div mv' >


                        <div className='mv-post-textarea '>
                            <Input className='mv-post-input' style={{ width: "100%" }} id="editMe" contentEditable="true" placeholder="Write a comment"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        onFinish(event.target.value, props?.postUserId);
                                    }
                                }}
                            ></Input>
                        </div>
                        <div className='mv-send-post-meta'>
                            <ImageIcon />
                        </div>
                    </div> */}


                    <div className='mv-editable-div'>
                        <div className='mv-post-textarea mv-d-flex'>
                            <Input className='mv-post-input' id={`${props?.postUserId}`} contentEditable="true" placeholder="Write a comment"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        onComment(event.target.value, props?.postId, data => {
                                            event.target.value = ""
                                        });

                                    }
                                }} style={{ width: "100 %" }}
                            ></Input>

                            <div className='mv-send-post-meta' onClick={() => {
                                debugger
                                let val = document.getElementById(props?.postUserId).value
                                console.log(val)
                                if (val) {
                                    onComment(val, props?.postId, data => {
                                        document.getElementById(props?.postUserId).value = ""
                                    })
                                }
                            }}>
                                <SendIcon />
                            </div>

                        </div>
                    </div>
                </div>
                <ul className='mv-post-btns mv-d-flex'>
                    <li className={`mv-post-btn ${props.liked ? "mv-active" : ""}`}>
                        <a onClick={() => {
                            if (!props.liked) {
                                onLikePost(props?.postId)
                            }
                            else {
                                onDeleteLike(props?.postId)
                            }
                        }} className='mv-d-flex mv-btn-40'>
                            <LikeIcon />
                            <span>Like</span>
                        </a>
                    </li>
                    {/* <li className='mv-post-btn'>
                        <Link to="#" className='mv-d-flex mv-btn-40'>
                            <MessageIcon />
                            <span>Comment</span>
                        </Link>
                    </li>
                    <li className='mv-post-btn'>
                        <Link to="#" className='mv-d-flex mv-btn-40 mv-share'>
                            <ShareIcon />
                            <span>Share</span>
                        </Link>
                    </li> */}
                    <li className='mv-post-btn' >
                        <a onClick={() => onFollowPost(props?.postUserId)} className='mv-d-flex mv-btn-40' style={props.followed ? { background: "#012e6b", color: "#fff" } : {}}>
                            <span > {props.followed ? " Following" : props.requested ? "Requested" : "Follow"}</span>
                        </a>
                    </li>
                </ul>
                {
                    props?.comments.length > 0 && props?.comments.map(item => {

                        return (
                            <div className='mv-comment-container mv-d-flex'>
                                <div className='mv-comment-image'>
                                    <Link to="#">
                                        <Image src={item.userProfilePicture.contentId ? IMAGE_URL + item.userProfilePicture.contentId : userImg} alt="" preview={false} className="mv-img-35" />
                                    </Link>
                                </div>
                                <div className='mv-comment-content'>
                                    <div className='mv-comment-meta'>
                                        <div className='mv-post-dropdown'>
                                            {item.userId == userID && < Dropdown overlay={commentDelete} placement="bottomCenter" arrow>
                                                <Button>
                                                    <DotsIcon />
                                                </Button>
                                            </Dropdown>
                                            }
                                        </div>
                                        <Title level={2}>
                                            <Link to="#">{item.userName}</Link>
                                        </Title>
                                        <Paragraph>{item.commentBase?.commentStr}</Paragraph>
                                    </div>
                                    <ul className='mv-comment-list mv-d-flex'>
                                        {/* <li>
                                <Link to="#">Reply</Link>
                            </li> */}
                                        <li>{moment(item.commentBase?.commentTimestamp).format('MMMM Do')} at {moment(item.commentBase?.commentTimestamp).format('h:mm A')}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }


                {/* <div className='mv-comment-container mv-d-flex mv-comment-rply'>
                    <div className='mv-comment-image'>
                        <Link to="#">
                            <Image src={props.profilePic ? props.profilePic : userImg} alt="" preview={false} className="mv-img-35" />
                        </Link>
                    </div>
                    <div className='mv-comment-content'>
                        <div className='mv-comment-meta'>
                            <Title level={2}>
                                <Link to="#">{props.profileName ? props.profileName : "Jhon Doe"}</Link>
                            </Title>
                            <Paragraph>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Paragraph>
                        </div>
                        <ul className='mv-comment-list mv-d-flex'>
                            <li>
                                <Link to="#">Reply</Link>
                            </li>
                            <li>{moment().startOf('hour').fromNow()}</li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className='mv-comment-container mv-d-flex'>
                    <div className='mv-comment-image'>
                        <Link to="#">
                            <Image src={props.replyiMAGE ? props.replyiMAGE : userImg} alt="" preview={false} className="mv-img-35" />
                        </Link>
                    </div>
                    <div className='mv-comment-content'>
                        <div className='mv-comment-meta'>
                            <Title level={2}>
                                <Link to="#">{props.replyUser ? props.replyUser : "Jhon"}</Link>
                            </Title>
                            <div className='mv-comment-file'>
                                <Image src={userImg} alt="" preview={false} />
                            </div>
                        </div>
                        <ul className='mv-comment-list mv-d-flex'>
                            <li>
                                <Link to="#">Reply</Link>
                            </li>
                            <li>{moment().startOf('hour').fromNow()}</li>
                        </ul>
                    </div>
                </div> */}
            </Card >
        </div >
    )
}

export default Post;
