// Libraries
import { useState, useEffect } from "react";
import { Layout, Drawer } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

// Component
import {
    Navbar,
    User,
    SideNav,
    Requests,
    Connections,
    PoliticalIssues
} from '../../components';

// Styles
import './style.css';

// Assets
import { HomeIcon, MenuIcon, MoreIcon, CloseIcon } from '../../assets/icons/icons';
import useToken from "../../hooks/useToken";
import { useShareDispatch, useShareSelector } from "../../shared";
import { actionsApi } from "../../redux-toolkit/actions/actionsApi";
import interceptor from "../../shared/interceptor";

const { Sider, Content } = Layout;

function AppLayout(props) {
    const location = useLocation()
    const [page, setPage] = useState(location.pathname.split('/')[1]);

    const [openLeft, setOpenLeft] = useState(false);
    const [openRight, setOpenright] = useState(false);
    const { getUserID, token, userID } = useToken();
    const [userId, setuserId] = useState(getUserID());
    const dispatcher = useShareDispatch();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)

    const showLeftDrawer = () => {
        setOpenLeft(true);
    };

    const onCloseLeft = () => {
        setOpenLeft(false);
    };

    const showRightDrawer = () => {
        setOpenright(true);
    };

    const onCloseRight = () => {
        setOpenright(false);
    };

    useEffect(() => {
        if (!loginUserObject?.userBase) {
            let model = {
                userId: getUserID(),
                token: token
            }
            dispatcher(actionsApi.getUser(model))
        }
    }, [loginUserObject?.userBase]);

    return (
        loginUserObject && <>
            <Layout>
                <Navbar />
                <Layout className="mv-layout">
                    <Sider className='mv-left-sidebar' width={300}>
                        <User />
                        <SideNav />
                    </Sider>
                    <Content>
                        {props.children}
                    </Content>
                    <Sider className='mv-right-sidebar' width={300}>
                        {(page === 'home' || page === 'view-candidate' || page === 'view-user') && <Requests title="Follow Requests" />}
                        {(page === 'home' || page === 'view-candidate' || page === 'view-user') && <Connections title="Connections" />}
                        {(page === 'edit-candidate' || page === 'edit-user') && <PoliticalIssues />}
                    </Sider>
                </Layout>
            </Layout>
            <ul className='mv-sticky-footer mv-d-flex'>
                <li>
                    <Link to="#" onClick={showLeftDrawer}>
                        <MenuIcon />
                        <span>Menu</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/home`}>
                        <HomeIcon />
                        <span>Home</span>
                    </Link>
                </li>
                <li>
                    <Link to="#" onClick={showRightDrawer}>
                        <MoreIcon />
                        <span>More</span>
                    </Link>
                </li>
            </ul>
            <Drawer
                title="Menu"
                placement="left"
                onClose={onCloseLeft}
                open={openLeft}
                className="mv-left-drawer"
                closeIcon={<CloseIcon />}
                width="250px"
            >
                <SideNav />
            </Drawer>
            <Drawer
                title="Right Sidebar"
                placement="right"
                onClose={onCloseRight}
                open={openRight}
                className="mv-right-drawer"
                closeIcon={<CloseIcon />}
                width="300px"
            >
                <Requests title="Follow Requests" />
                <Connections title="Connections" />
            </Drawer>
        </>
    );
}

export default AppLayout;
