import bannerImg from '../assets/images/new-banner.jpg';

export const NewProfileBannerData = {
    bannerImg: bannerImg,
    name: "Doug Mastriano",
    location: "Former Philadelphia",
    designation: "Councilmember",
    userName: "@jhondoe",
    incumbent: "New or Incumbent",
    party: "Political Party",
    button: "Follow"
}