// Libraries
import React, { useState, useEffect } from 'react'
import { Card, Form, Button, AutoComplete, Input, Radio, Col } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon, DeleteIcon } from '../../../../assets/icons/icons';
import { PoliticalInterests as PoiticalList } from '../../../view-candidate/components'

// Styles
import './style.css';
import { setPoliticalIntrestAsync, getPoliticalIntrestAsync, updatePoliticalIntrestAsync, deleteInterests } from '../../../../shared/asyncstorage/politicalIntrest';
import uuidv4 from '../../../../shared/uuidgenerator';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../../../Notification/successNotif';

// Assets

const options = [
    {
        value: 'Gun Control',
    },
    {
        value: 'Abortion Rights',
    },
    {
        value: 'LGBT Rights',
    },
    {
        value: 'Freedom of Speech',
    },
    {
        value: 'Voter Registration Reform',
    },
    {
        value: 'Critical Race Theory',
    },
    {
        value: 'Pro-Life',
    },
    {
        value: 'Immigration Reform',
    },
];

const PoliticalInterests = () => {
    const [intrestArray, setIntrestArray] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false)
    const [model, setModel] = useState(null)
    const [form] = Form.useForm();
    const { token, getUserID } = useToken();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();


    useEffect(() => {
        if (loginUserObject?.userCandidateExtension?.politicalInterests?.length > 0)
            setIntrestArray(loginUserObject?.userCandidateExtension?.politicalInterests)
        //     getPoliticalIntrestAsync().then(res => {
        //         setIntrestArray(res)
        //     })
        // }, [isUpdating])

        // useEffect(() => {
        //     getPoliticalIntrestAsync().then(res => {
        //         setIntrestArray(res)
        //     })
    }, [loginUserObject])
    const onFinish = (values) => {
        if (values.interestName) {
            setIsUpdating(true)
            interceptor.axiosPost("Post", "/interests", values, token).then(res => {
                setIsUpdating(false)
                if (res.status = 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    dispatcher(actionsApi.UpdatePoliticalInterests(res.data))
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setIsUpdating(false)

            })
            // if (model) {
            //     intrestArray.forEach(x => {
            //         if (x._id == model._id) {
            //             x.politicalInterests = values.politicalInterests;
            //             x.writeViews = values.writeViews;
            //             x.isSupported = values.isSupported;
            //         }
            //     })
            //     updatePoliticalIntrestAsync(intrestArray)
            //     setModel(null)
            // }
            // else {
            //     values._id = uuidv4()
            //     setPoliticalIntrestAsync(values)
            // }

            // setIsUpdating(true)
            // form.resetFields()
            // setTimeout(() => {
            //     setIsUpdating(false)

            // }, 2000)

        }
    }


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const checkConrol = (value) => {
        let item = intrestArray.find(x => x.politicalInterests == value)
        if (item) {
            setModel(item)
            form.setFieldsValue({
                politicalInterests: item.politicalInterests,
                writeViews: item.writeViews,
                isSupported: item.isSupported
            })
        } else {
            setModel(null)
            form.setFieldsValue({
                politicalInterests: value,
                writeViews: null,
                isSupported: null
            })
        }

    }

    const editInterest = (item) => {
        setModel(item)
        form.setFieldsValue({
            politicalInterests: item.politicalInterests,
            writeViews: item.writeViews,
            isSupported: item.isSupported
        })

    }

    const deleteInterest = (item) => {
        setIsUpdating(true)
        interceptor.axiosDelete("Delete", "/interests/" + item.interestId, null, token).then(res => {
            setIsUpdating(false)
            if (res.status = 200) {
                dispatcher(actionsApi.DeletePoliticalInterests(item))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setIsUpdating(false)

        })

        // var filteredArray = intrestArray.filter(function (e) { return e._id !== id._id })
        // setIntrestArray(filteredArray)
        // deleteInterests(filteredArray)
        // form.resetFields()
        // setTimeout(() => {
        //     setIsUpdating(false)
        // }, 1000)

    }

    return (
        <>
            <div className='mv-form-fields'>
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                >
                    <Card>

                        <Form.Item
                            label="Add Political Interests"
                            name="interestName"
                            className='mv-mb-15'
                        >
                            <AutoComplete
                                options={options}
                                placeholder="Input Political Interest"
                                onChange={checkConrol}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item>


                        <Form.Item
                            name="viewPoint"
                            className='mv-mb-0'
                        >
                            <Input.TextArea placeholder="Write Your Views" className='mv-h-100' />
                        </Form.Item>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="supportive"
                                label="I support?"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Support must be required.',
                                    },
                                ]}
                            >
                                <Radio.Group name="candidateGroup" >
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                            <div className='mv-icon-btns mv-d-flex'>
                                {/* <Link to="#" className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                    <EditIcon />
                                </Link> 
                                <Link to="#" className='ant-btn ant-btn-danger ant-btn-30'>
                                    <DeleteIcon />
                                </Link>*/}
                            </div>
                            <Button type="primary" htmlType="submit" loading={isUpdating} className='ant-btn-44'>Save</Button>
                        </div>
                    </Card>
                </Form>
                {
                    intrestArray?.length > 0 && <PoiticalList  isEdit={true} isUpdate={isUpdating} onEdit={editInterest} onDelete={deleteInterest} />
                }

            </div>
        </>
    )
}

export default PoliticalInterests;
