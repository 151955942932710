// Libraries
import { Typography, Card, Image, Spin } from 'antd';
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';

// Component
import { UsersData } from '../../data/UsersData';

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

// Assets
import { PrevArrowIcon, NextArrowIcon, AddUserIcon } from '../../assets/icons/icons';
import { useShareDispatch, useShareSelector } from '../../shared';
import { IMAGE_URL } from '../../enviroment/Envirments';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import interceptor from '../../shared/interceptor';
import OpenSuccessNotification from '../../Notification/successNotif';
import OpenErrorNotification from '../../Notification/errorNotif';
import useToken from '../../hooks/useToken';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><NextArrowIcon /></div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><PrevArrowIcon /></div>
    );
}

const { Title, Paragraph } = Typography;

const Users = () => {
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { token, } = useToken();
    const dispatcher = useShareDispatch();
    const navigate = useNavigate();



    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        speed: 500,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
        ]
    };

    const onFollow = (userId) => {
        // setPostLikeloading(true)
        interceptor.axiosPost("Post", "/connections", { userIdForConnection: userId }, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getUser(token))
                OpenSuccessNotification("topRight", "Request Successfully Sent")
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {

            OpenSuccessNotification("topRight", "Request Successfully Sent")

            console.log("error?.response?.data?.error", error?.response);
        })
    }

    const getPublicUserDetail = async (id) => {
            interceptor.axiosGet("Get", "/users/" + id + "/public", token).then(res => {
            if (res.status == 200) {
                
                dispatcher(gettingPublicResponseSuccess(res.data))
                let user = res.data?.userBase
                if (user?.userType == "CANDIDATE") {
                    navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                } else {
                    navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                }

            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
                OpenErrorNotification("topRight", res.detailMessageStr)
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }

    return (
        <div className='mv-users'>
            <Card>
                <Title level={2}>Candidates Recommended For You</Title>
                <div className='mv-users-slider'>
                    <Slider {...settings}>
                        {loginUserObject?.matchedCandidates.length > 0 ? loginUserObject?.matchedCandidates.map((item, index) => (
                            <div key={index} >
                                <span onClick={() => getPublicUserDetail(item.userId)} >
                                <Image src={IMAGE_URL + item.candidateProfilePicture?.contentId} preview={false} alt={item.firstName + " " + item.lastName} />
                                <Title level={2}>{item.firstName + " " + item.lastName}</Title>
                                <Paragraph>{item.designation}</Paragraph>
                                </span>
                                <Link to="#" className='ant-btn ant-btn-primary ant-btn-40 ant-icon-btn'>
                                    <AddUserIcon />
                                    <span onClick={() => onFollow(item.userId)}>Follow</span>
                                </Link>
                            </div>
                        )) : <div className="mv-spinner">
                            <Spin size="large" />
                        </div>}
                    </Slider>
                </div>
            </Card>
        </div>
    )
}

export default Users;
