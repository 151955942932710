import interceptor from "../interceptor";
import uuidv4 from "../uuidgenerator";

export const imageUpladBase64 = (file, path, token, callback) => {
    getBase64(file, (url) => {
        var base64 = url.replace(/^data:image\/\w+;base64,/, "");
        interceptor.axiosPost("post", "/data-chunks", { correlationId: uuidv4(), order: "1", encodedData: base64 }, token).then(res => {
            // saveToken(res)
            if (res.status == 200) {
                let uploadedImageObject = res.data;
                let comObject = {
                    'correlationId': uploadedImageObject?.correlationId,
                    'totalChunkCount': uploadedImageObject?.chunkOrder,
                    'contentType': file.type,
                    'contentTitle': path,
                    'contentDescription': "des",
                }
                interceptor.axiosPost("post", "/data-chunks/complete", comObject, token).then(res => {
                    let resobj = res.data;
                    let model = {
                        "contentId": resobj.contentId,
                        "postId": "",
                        "contentAssociationType": path
                    }
                    interceptor.axiosPost("post", "/content-associations", model, token).then(res => {
                        callback(res)
                    }).catch(errorComplete => {
                        console.log("error /data-chunks/complete", errorComplete)
                    })
                }).catch(errorComplete => {
                    console.log("error /data-chunks/complete", errorComplete)
                })
            } else {
                console.log("else")
            }

        }).catch(error => {
            console.log(error)
        })
    });
}

export const upladForPostBase64 = (file, type, postId, path, token, callback) => {

    // var base64 = file.split(/^data:vidoe\/\w+;base64,/, "");
    
    interceptor.axiosPost("post", "/data-chunks", { correlationId: uuidv4(), order: "1", encodedData: file }, token).then(res => {
        // saveToken(res)
        if (res.status == 200) {
            let uploadedImageObject = res.data;
            let comObject = {
                'correlationId': uploadedImageObject?.correlationId,
                'totalChunkCount': uploadedImageObject?.chunkOrder,
                'contentType': type,
                'contentTitle': path,
                'contentDescription': "des",
            }
            uploadCompleted(comObject, path, postId, token, data => {
                callback(data)
            })

        } else {
            console.log("else")
        }

    }).catch(error => {
        console.log(error)
    })

}


export const VideoUpladForPostBase64 = (file, type, postId, path, token, callback) => {
    
    var loaded = 0;
    var chunkSize = 500000;
    var total = file.size;
    var reader = new FileReader();
    var slice = file.slice(0, chunkSize);
    var id = uuidv4()
    let orderNo = 1
    reader.readAsDataURL(slice);

    reader.onload = function (e) {
        let base64 = reader.result.split("data:application/octet-stream;base64,")[1]
        interceptor.axiosPost("post", "/data-chunks", { correlationId: id, order: orderNo, encodedData: base64 }, token).then(res => {
            // saveToken(res)
            if (res.status == 200) {

                loaded += chunkSize;
                var percentLoaded = Math.min((loaded / total) * 100, 100);
                console.log('Uploaded ' + Math.floor(percentLoaded) + '% of file "' + file.name + '"');

                //Read the next chunk and call 'onload' event again
                if (loaded <= total) {
                    slice = file.slice(loaded, loaded + chunkSize);
                    orderNo = orderNo + 1
                    callback({
                        percentLoaded: Math.floor(percentLoaded),
                        complete: false,
                        data: null
                    })
                    reader.readAsDataURL(slice);
                } else {
                    loaded = total;
                    console.log('File "' + file.name + '" uploaded successfully!');
                    let uploadedImageObject = res.data;
                    let comObject = {
                        'correlationId': uploadedImageObject?.correlationId,
                        'totalChunkCount': uploadedImageObject?.chunkOrder,
                        'contentType': type,
                        'contentTitle': path,
                        'contentDescription': "des",
                    }
                    uploadCompleted(comObject, path, postId, token, data => {
                        callback({
                            percentLoaded: percentLoaded,
                            complete: true,
                            data: data
                        })
                    })
                }




            } else {
                console.log("else")
            }

        }).catch(error => {
            console.log(error)
        })
    }

}



export const uploadCompleted = async (comObject, path, postId, token, callback) => {
    interceptor.axiosPost("post", "/data-chunks/complete", comObject, token).then(res => {
        let resobj = res.data;
        let model = {
            "contentId": resobj.contentId,
            "postId": postId,
            "contentAssociationType": path
        }
        interceptor.axiosPost("post", "/content-associations", model, token).then(res => {
            callback(res)
        }).catch(errorComplete => {
            console.log("error /data-chunks/complete", errorComplete)
        })
    }).catch(errorComplete => {
        console.log("error /data-chunks/complete", errorComplete)
    })
}


export const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    await reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

