// Libraries
import React, { useEffect } from 'react';

// Component
import { AppLayout } from '../../components';

// Styles
import './style.css';
import UpdatePassword from '../UpdatePassword';
import Subscriptions from '../Subscriptions';
import TabPane from 'antd/lib/tabs/TabPane';
import CurrentUserProfile from '../../components/CurrentUserProfile';
import { Tabs } from 'antd';
import { useShareSelector } from '../../shared';

const Settings = () => {
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    console.log(loginUserObject)
    const userType = loginUserObject?.userBase?.userType

    useEffect(() => {

    }, [loginUserObject])

    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700'>
                <CurrentUserProfile />
                {/* <Row gutter={30} className="mv-mt-30">
                    <Col xs={24}> */}
                <Tabs defaultActiveKey="1">
                    <TabPane tab=" Update Password" key="1">
                        <UpdatePassword />
                    </TabPane>
                    {userType == 'CANDIDATE' && <TabPane tab="Subscriptions" key="2">
                        <Subscriptions />
                    </TabPane>}
                </Tabs>

                {/* <Col xs={12}>
                        <Link to="/update-password" className="ant-btn ant-btn-primary ant-btn-44" style={{ width: '100%' }}>
                            Update Password
                        </Link>
                    </Col>
                    <Col xs={12}>
                        <Link to="/subscriptions" className="ant-btn ant-btn-danger ant-btn-44" style={{ width: '100%' }}>
                            Subscriptions
                        </Link>
                    </Col> */}
                {/* </Col>

                </Row> */}
            </main>
        </AppLayout>
    )
}

export default Settings;
