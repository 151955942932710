// Libraries
import { useState, useRef, useEffect } from "react";
import { Image, Input, Dropdown, Menu, Button, Drawer } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";

// Component

// Styles
import "./style.css";

// Assets
import logo from "../../assets/images/logo.svg";
import user from "../../assets/images/user.png";
import userdefault from "../../assets/images/user.png";


import {
  SearchIcon,
  MoonIcon,
  BellIcon,
  CloseIcon,
  TrashIcon,
} from "../../assets/icons/icons";
import { useShareDispatch, useShareSelector } from "../../shared";
import useToken from "../../hooks/useToken";
import { actionsApi } from "../../redux-toolkit/actions/actionsApi";
import { IMAGE_URL } from "../../enviroment/Envirments";

const notificatios = (
  <Menu
    items={[
      {
        key: "1",
        type: "group",
        label: "Notifications",
        children: [
          {
            key: "1-1",
            label: "1st menu item",
          },
          {
            key: "1-2",
            label: "2nd menu item",
          },
        ],
      },
    ]}
  />
);



function Navbar() {
  let param = useParams();
  const { getUserID, userID, removeToken } = useToken();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
  const dispatcher = useShareDispatch();
  const navigate = useNavigate();
  const mvNavbar = useRef(null);
  const handleSearch = () => {
    mvNavbar.current.classList.toggle("mv-navbar-toggle");
    setIsSearchOpen((isSearchOpen) => !isSearchOpen);
  };

  const profile = (userType, userID) => (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to={userType == "CANDIDATE" ? `/edit-candidate/${userID}/edit` : `/edit-user/${userID}/edit`}>My Profile</Link>
          ),
        },
        {
          key: '2',
          label: (
            <span onClick={logOut}>Log out</span>
          ),
        },
      ]}
    />
  );



  const logOut = () => {
    removeToken();
    navigate('/signin');
    dispatcher(actionsApi.logOutUser())
  };

  useEffect(() => {
  }, []);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="mv-navbar mv-d-flex mv-sm-px" ref={mvNavbar}>
        {/* <div className="mv-menu-icon" onClick={showDrawer}>
            <SearchIcon />
        </div> */}
        <div className="mv-logo">
          <Link to={`/home`}>
            <Image width={117} height={39} src={logo} preview={false} />
          </Link>
        </div>
        {!userID && (
          <div className="mv-navbar-end">


            <ul className="mv-auth-btns mv-d-flex">

              <li>
                <Link to="/signin" className="ant-btn ant-btn-primary">
                  Sign In
                </Link>
              </li>
              <li>
                <Link to="/signup" className="ant-btn ant-btn-danger">
                  Sign Up
                </Link>
              </li>
            </ul>
          </div>
        )}
        {userID && (
          <>
            <div className="mv-search mv-icon">
              <Input placeholder="Search myvote" prefix={<SearchIcon />} />
            </div>
            <ul className="mv-login-menu mv-d-flex">
              <li>
                <Button
                  className="mv-icon mv-search-icon"
                  onClick={handleSearch}
                >
                  {isSearchOpen ? <CloseIcon /> : <SearchIcon />}
                </Button>
              </li>
              {/* <li>
                <Button className="mv-icon">
                  <MoonIcon />
                </Button>
              </li> */}
              <li>
                <Dropdown overlay={notificatios} placement="bottomRight" arrow>
                  <Button className="mv-icon">
                    <BellIcon />
                  </Button>
                </Dropdown>
              </li>
              <li>
                <Dropdown
                  overlay={() => profile(loginUserObject?.userBase?.userType, userID)}
                  placement="bottomRight"
                  arrow
                >
                  <Image width={35} height={35} src={loginUserObject?.userProfilePicture?.contentId ? IMAGE_URL + loginUserObject?.userProfilePicture?.contentId : userdefault} preview={false} />
                </Dropdown>
              </li>
            </ul>
          </>
        )}
      </div>
      <Drawer title="Basic Drawer" placement="left" onClose={onClose} open={open}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Drawer>
    </>
  );
}

export default Navbar;
