// Libraries
import { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';

// Component

// Styles
import './style.css';
import { getCandidatePoliticalInterest, getPoliticalIntresfromLStAsync, getPoliticalIntrestAsync } from '../../../../shared/asyncstorage/politicalIntrest';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../../../assets/icons/icons';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';

// Assets

const { Title, Paragraph } = Typography;

const PoliticalInterests = (props) => {

    const { isUpdate } = props;
    const location = useLocation()
    const [page, setPage] = useState(location.pathname.split('/')[1]);
    const { username } = useParams();
    const [ellipsis, setEllipsis] = useState(true);
    const [intrestArr, setIntrestArr] = useState([]);
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const { loginUserObject } = useShareSelector(state => state.UserLoginDetails)

    const editInterestValue = (id) => {
        props.onEdit(id)
    }
    const deleteInterest = (id) => {
        props.onDelete(id)
    }
    useEffect(() => {
        if (props.isEdit) {
            if (loginUserObject?.interests?.length > 0) { setIntrestArr(loginUserObject?.interests) }
        } else {
            if (publicUser?.interests?.length > 0) { setIntrestArr(publicUser?.interests) }
        }


    }, [publicUser, loginUserObject])

    return (
        <>
            {intrestArr.length > 0 &&
                <Card>
                    <div className='mv-view'>
                        <Title className='mv-interest-head' level={2}>Political Interests</Title>
                        {
                            intrestArr.map((value, index) => {
                                return (
                                    <div className='mv-view mv-mt-20  mv-pr-10 mv-pl-10'>
                                        <Title level={2}>{value.interestName}</Title>
                                        <div className=' mv-mt-20  mv-jc-sb'>

                                            <Paragraph
                                                ellipsis={
                                                    ellipsis
                                                        ? {
                                                            rows: 4,
                                                            expandable: true,
                                                            symbol: 'READ MORE',
                                                        }
                                                        : false
                                                }
                                            >
                                                {value.viewPointStr}
                                            </Paragraph>

                                            {(page == 'edit-candidate' || page == 'edit-user') &&

                                                <div className=' mv-btn-right mv-icon-btns mv-pb-10 mv-pt-10'>
                                                    {/* <Link to="#" onClick={() => editInterestValue(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                                        <EditIcon />
                                                    </Link> */}
                                                    <Link to="#" onClick={() => deleteInterest(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                                        <DeleteIcon />
                                                    </Link>
                                                </div>

                                            }
                                        </div>
                                        {
                                            (intrestArr.length > 1 && index + 1 != intrestArr.length) &&
                                            <div className="my-bb"> </div>
                                        }
                                    </div >


                                    // <div className='mv-view mv-mt-20'>
                                    //     <Title level={2}>{value.politicalInterests}</Title>
                                    //     <Card>
                                    //         <Paragraph
                                    //             ellipsis={
                                    //                 ellipsis
                                    //                     ? {
                                    //                         rows: 4,
                                    //                         expandable: true,
                                    //                         symbol: 'READ MORE',
                                    //                     }
                                    //                     : false
                                    //             }
                                    //         >
                                    //             {value.writeViews}
                                    //         </Paragraph>
                                    //         {(page == 'edit-candidate' || page == 'edit-user') &&
                                    //             <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                    //                 <div className='mv-icon-btns mv-d-flex'>
                                    //                     <Link to="#" onClick={() => editInterestValue(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                    //                         <EditIcon />
                                    //                     </Link>
                                    //                     <Link to="#" onClick={() => deleteInterest(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                    //                         <DeleteIcon />
                                    //                     </Link>
                                    //                 </div>
                                    //                 {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    //             </div>
                                    //         }
                                    //     </Card>
                                    // </div >
                                )
                            })


                        }
                    </div>
                </Card>
            }
            {/* <div className='mv-view mv-mt-20'>
                <Title level={2}>Abortion Rights</Title>
                <Card>
                    <Paragraph
                        ellipsis={
                            ellipsis
                                ? {
                                    rows: 4,
                                    expandable: true,
                                    symbol: 'READ MORE',
                                }
                                : false
                        }
                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </Paragraph>
                </Card>
            </div> */}
        </>
    )
}

export default PoliticalInterests;
