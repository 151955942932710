// Libraries
import { useState, useEffect } from 'react'
import { Card, Form, Button, AutoComplete, Input, Typography, Modal } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon, DeleteIcon } from '../../../../assets/icons/icons';

// Styles
import './style.css';
import uuidv4 from '../../../../shared/uuidgenerator';
import { setSpeechesIntrestAsync, getSpeechesIntrestAsync, deleteSpeechesIntrestAsync, updateSpeechesIntrestAsync } from '../../../../shared/asyncstorage/SpeechesCandidateAsynsData';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../../../Notification/successNotif';

// Assets

const { Title } = Typography;

const Speeches = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [speechArray, setSpeechArray] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false)
    const [model, setModel] = useState(null);
    const [form] = Form.useForm();
    const { token, getUserID } = useToken();
    const { loginUserObject } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();


    useEffect(() => {
        if (loginUserObject?.userCandidateExtension?.speeches?.length > 0)
            setSpeechArray(loginUserObject?.userCandidateExtension?.speeches)
    }, [loginUserObject])

    const onFinish = (values) => {
        console.log('Success:', values);
        if (values.linkTitle) {
            values.linkType = "speech"
            setIsUpdating(true)
            interceptor.axiosPost("Post", "/links", values, token).then(res => {
                setIsUpdating(false)
                if (res.status = 200) {
                    OpenSuccessNotification("topRight", "Successfully Added")
                    dispatcher(actionsApi.UpdateSpeeech(res.data))
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setIsUpdating(false)
            })
            handleCancel()
        }
    };



    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
        setModel(null)
        form.setFieldsValue({
            linkTitle: '',
            linkUrl: '',
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteSpeech = (item) => {
        interceptor.axiosDelete("Delete", "/links/" + item.linkId, null, token).then(res => {
            setIsUpdating(false)
            if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Remove")
                dispatcher(actionsApi.deleteSpeech(item))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setIsUpdating(false)

        })

    }
    const editSpeech = (value) => {
        setIsModalOpen(true);
        setModel(value)
        form.setFieldsValue({
            linkTitle: value.linkTitle,
            linkUrl: value.linkUrl,
        });
    }
    return (
        <>
            <div className='mv-form-fields mv-mt-20'>
                <div className='mv-d-flex mv-jc-sb'>
                    <Title level={2}>Speeches</Title>
                    <Link to="#" onClick={() => showModal()} className='ant-btn ant-btn-danger ant-btn-44'>Add New</Link>
                </div>
                {
                    speechArray.length > 0 &&
                    speechArray.map((value, key) => {
                        return (
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Card>
                                    <div
                                        name="linkTitle"
                                        className='mv-mb-15'
                                    >
                                        <Input
                                            value={value.linkTitle}
                                            placeholder="Speech Title" />
                                    </div>
                                    <div
                                        name="linkUrl"
                                        className='mv-mb-0'
                                    >
                                        <Input value={value.linkUrl} placeholder="Speech Link" />
                                    </div>
                                    <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                        <div className='mv-icon-btns mv-d-flex'>
                                            {/* <Link to="#" onClick={() => editSpeech(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                                <EditIcon />
                                            </Link> */}
                                            <Link to="#" onClick={() => deleteSpeech(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                                <DeleteIcon />
                                            </Link>
                                        </div>
                                        {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    </div>
                                </Card>
                            </Form>
                        )
                    })

                }

                {/* */}
                <>

                    <Modal
                        title="Basic Modal"
                        open={isModalOpen}
                        onOk={handleOk}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                            form={form}
                        >
                            <Card>
                                <Form.Item
                                    name="linkTitle"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="Speech Title" />
                                </Form.Item>
                                <Form.Item
                                    name="linkUrl"
                                    className='mv-mb-0'
                                >
                                    <Input placeholder="Speech Link" />
                                </Form.Item>
                                <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                    {/* <div className='mv-icon-btns mv-d-flex'>
                                <Link to="#" className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                    <EditIcon />
                                </Link>
                                <Link to="#" className='ant-btn ant-btn-danger ant-btn-30'>
                                    <DeleteIcon />
                                </Link>
                            </div> */}
                                    <Button type="primary" htmlType="submit" className='ant-btn-44'>Save</Button>
                                </div>
                            </Card>
                        </Form>
                    </Modal>
                </>
            </div>
        </>
    )
}

export default Speeches;
