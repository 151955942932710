import _Introduction from './Introduction';
import _PoliticalInterests from './PoliticalInterests';
import _Speeches from './Speeches';
import _Interviews from './Interviews';
import _Quotes from './Quotes';
import _ImportantLinks from './ImportantLinks';

export const Introduction = _Introduction;
export const PoliticalInterests = _PoliticalInterests;
export const Speeches = _Speeches;
export const Interviews = _Interviews;
export const Quotes = _Quotes;
export const ImportantLinks = _ImportantLinks;