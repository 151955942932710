// Libraries
import {
  Card,
  Form,
  Button,
  AutoComplete,
  Input,
  Select,
  Row,
  Col,
  Typography,
  Radio,
  Upload,
  Divider
} from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

// Component
import { EditIcon, DeleteIcon } from "../../../../assets/icons/icons";
import useToken from "../../../../hooks/useToken";
import { actionsApi } from "../../../../redux-toolkit/actions/actionsApi";
import { useShareDispatch, useShareSelector } from "../../../../shared";
import { getLoc, getPoliticalAffiliation, setLoc, setPoliticalAffiliation } from "../../../../shared/asyncstorage/intro";
import { deleteInterests, getPoliticalIntrestAsync, setPoliticalIntrestAsync, updatePoliticalIntrestAsync } from "../../../../shared/asyncstorage/politicalIntrest";
import interceptor from "../../../../shared/interceptor";
import uuidv4 from "../../../../shared/uuidgenerator";

import { PoliticalInterests as PoiticalList } from '../../../view-candidate/components'

// Styles
import "./style.css";
import OpenSuccessNotification from "../../../../Notification/successNotif";
import { imageUpladBase64 } from "../../../../shared/asyncstorage/imgaeUpload";
import { IMAGE_URL } from "../../../../enviroment/Envirments";

// Assets

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const options = [
  {
    value: "Gun Control",
  },
  {
    value: "Abortion Rights",
  },
  {
    value: "LGBT Rights",
  },
  {
    value: "Freedom of Speech",
  },
  {
    value: "Voter Registration Reform",
  },
  {
    value: "Critical Race Theory",
  },
  {
    value: "Pro-Life",
  },
  {
    value: "Immigration Reform",
  },
];

const PoliticalInterests = () => {

  const dispatcher = useShareDispatch();
  const [introLoading, setIntroLoading] = useState();
  const [intrestArray, setIntrestArray] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false)
  const [partyLoading, setPartyLoading] = useState();
  const [locLoading, setLocLoading] = useState();
  const { token, getUserID } = useToken();
  const { loginUserObject } = useShareSelector(state => state.UserLoginDetails)


  const [form] = Form.useForm();
  const [formParty] = Form.useForm();
  const [formLoc] = Form.useForm();


  useEffect(() => {
    let userCandidateExtensionBase = loginUserObject?.userCandidateExtension?.userCandidateExtensionBase
    let userAddress = loginUserObject?.userBase;

    setIntrestArray(loginUserObject?.userCandidateExtension?.politicalInterests ? loginUserObject?.userCandidateExtension?.politicalInterests : [])
    formParty.setFieldsValue({
      politicalParty: userAddress?.party ? userAddress?.party : null
    });
    formLoc.setFieldsValue({
      address: userAddress?.address ? userAddress?.address : null,
      state: userAddress?.state ? userAddress?.state : null,
      city: userAddress?.city ? userAddress?.city : null,
      zip: userAddress?.zip ? userAddress?.zip : null
    });

  }, [loginUserObject])

  const onFinish = (values) => {
    console.log("politicalParty :", values)
    if (values.interestName) {
      setIsUpdating(true)
      interceptor.axiosPost("Post", "/interests", values, token).then(res => {
        OpenSuccessNotification("topRight", "Successfully Added")
        dispatcher(actionsApi.UpdatePoliticalInterests(res.data))
        form.resetFields()
        setIsUpdating(false)
      }).catch(error => {
        console.log("error?.response?.data?.error", error?.response);
        setIsUpdating(false)

      })
    }
  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishAffili = (values) => {
    console.log('Success:', values);

    if (values.politicalParty) {
      setPartyLoading(true)
      interceptor.axiosPut("PUT", "/users/political-party-affiliations", values, token).then(res => {
        OpenSuccessNotification("topRight", "Successfully Added")
        setPartyLoading(false)
      }).catch(error => {
        console.log("error?.response?.data?.error", error?.response);
        setPartyLoading(false)

      })

    }

  };

  const onFinishFailedAffili = (errorInfo) => {
    console.log('Failed:', errorInfo);

  };

  const onFinishLoc = (values) => {
    console.log('Success:', values);
    if (values) {
      setLocLoading(true)
      interceptor.axiosPut("PUT", "/users/location", values, token).then(res => {
        OpenSuccessNotification("topRight", "Successfully Added")
        setLocLoading(false)
      }).catch(error => {
        console.log("error?.response?.data?.error", error?.response);
        setLocLoading(false)

      })

    }
  };

  const onFinishFailedLoc = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const editInterest = (item) => {

    form.setFieldsValue({
      politicalInterests: item.politicalInterests,
      writeViews: item.writeViews,
      isSupported: item.isSupported
    })

  }
  const checkConrol = (value) => {
    let item = intrestArray.find(x => x.politicalInterests == value)
    if (item) {

      form.setFieldsValue({
        politicalInterests: item.politicalInterests,
        writeViews: item.writeViews,
        isSupported: item.isSupported
      })
    } else {
      form.setFieldsValue({
        politicalInterests: value,
        writeViews: null,
        isSupported: null
      })
    }

  }

  const deleteInterest = (item) => {
    setIsUpdating(true)
    interceptor.axiosDelete("Delete", "/interests/" + item.interestId, null, token).then(res => {
      setIsUpdating(false)
      debugger
      if (res.status = 200) {
        dispatcher(actionsApi.DeletePoliticalInterests(item))
      }
    }).catch(error => {
      console.log("error?.response?.data?.error", error?.response);
      setIsUpdating(false)

    })

    // var filteredArray = intrestArray.filter(function (e) { return e._id !== id._id })
    // setIntrestArray(filteredArray)
    // deleteInterests(filteredArray)
    // form.resetFields()
    // setTimeout(() => {
    //     setIsUpdating(false)
    // }, 1000)

  }

  return (
    <>
      <div className="mv-form-fields mv-mt-20">
        <Title level={2}>Location</Title>
        <Form
          onFinish={onFinishLoc}
          onFinishFailed={onFinishFailedLoc}
          autoComplete="off"
          layout="vertical"
          form={formLoc}

        >
          <Card>
            <Form.Item
              name="address"
              className='mv-mb-15'
            >
              <Input placeholder="Address" />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item name="state" className="mv-mb-0 mv-mb-sm-15 mv-mb-md-15"
                  rules={[
                    {
                      required: true,
                      message: 'required',
                    },
                  ]}
                >
                  <Select placeholder="State">
                    <Option value="Pennsylvania">Pennsylvania</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item name="city" className="mv-mb-0 mv-mb-sm-15"
                  rules={[
                    {
                      required: true,
                      message: 'required',
                    },
                  ]}>
                  <Select placeholder="City">
                    <Option value="Aliquippa">Aliquippa</Option>
                    <Option value="Allentown">Allentown</Option>
                    <Option value="Altoona">Altoona</Option>
                    <Option value="Arnold">Arnold</Option>
                    <Option value="Beaver Falls">Beaver Falls</Option>
                    <Option value="Bethlehem">Bethlehem</Option>
                    <Option value="Bloomsburg">Bloomsburg</Option>
                    <Option value="Bradford">Bradford</Option>
                    <Option value="Butler">Butler</Option>
                    <Option value="Carbondale">Carbondale</Option>
                    <Option value="Chester">Chester</Option>
                    <Option value="Clairton">Clairton</Option>
                    <Option value="Coatesville">Coatesville</Option>
                    <Option value="Connellsville">Connellsville</Option>
                    <Option value="Corry">Corry</Option>
                    <Option value="Du Bois">Du Bois</Option>
                    <Option value="Duquesne">Duquesne</Option>
                    <Option value="Easton">Easton</Option>
                    <Option value="Erie">Erie</Option>
                    <Option value="Farrell">Farrell</Option>
                    <Option value="Franklin">Franklin</Option>
                    <Option value="Greensburg">Greensburg</Option>
                    <Option value="Harrisburg">Harrisburg</Option>
                    <Option value="Hermitage">Hazleton</Option>
                    <Option value="Jeannette">Jeannette</Option>
                    <Option value="Lancaster">Lancaster</Option>
                    <Option value="Latrobe">Latrobe</Option>
                    <Option value="Lebanon">Lebanon</Option>
                    <Option value="Lock Haven">Lock Haven</Option>
                    <Option value="Lower Burrell">Lower Burrell</Option>
                    <Option value="McCandless">McCandless</Option>
                    <Option value="McKeesport">McKeesport</Option>
                    <Option value="Meadville">Meadville</Option>
                    <Option value="Monessen">Monessen</Option>
                    <Option value="Monongahela">Monongahela</Option>
                    <Option value="New Castle">New Castle</Option>
                    <Option value="New Kensington">New Kensington</Option>
                    <Option value="Oil City">Oil City</Option>
                    <Option value="Parker">Parker</Option>
                    <Option value="Philadelphia">Philadelphia</Option>
                    <Option value="Pittsburgh">Pittsburgh</Option>
                    <Option value="Pittston">Pittston</Option>
                    <Option value="Pottsville">Pottsville</Option>
                    <Option value="Reading">Reading</Option>
                    <Option value="St. Marys">St. Marys</Option>
                    <Option value="Scranton">Scranton</Option>
                    <Option value="Shamokin">Shamokin</Option>
                    <Option value="Sharon">Sharon</Option>
                    <Option value="Sunbury">Sunbury</Option>
                    <Option value="Titusville">Titusville</Option>
                    <Option value="Warren">Warren</Option>
                    <Option value="Washington">Washington</Option>
                    <Option value="Wilkes-Barre">Wilkes-Barre</Option>
                    <Option value="Williamsport">Williamsport</Option>
                    <Option value="York">York</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item name="zip" className="mv-mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'required',
                    },
                  ]}>
                  <Input placeholder="Zip" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <div className="mv-btn-right mv-mt-20">
            <Button type="primary" htmlType="submit" loading={locLoading} className="ant-btn-44">
              Save
            </Button>
          </div>
        </Form>
      </div>
      <div className="mv-form-fields">
        <Form
          onFinish={onFinishAffili}
          onFinishFailed={onFinishFailedAffili}
          autoComplete="off"
          layout="vertical"
          form={formParty}
        >
          <Card>
            <Form.Item
              label="Political Party Affiliation"
              name="politicalParty"
              className="mv-mb-0"
              rules={[
                {
                  required: true,
                  message: 'Select Political Party Affiliation',
                },
              ]}
            >
              <Select placeholder="Select Political Party Affiliation">
                <Option value="republican">Republican</Option>
                <Option value="democrat">Democrat</Option>
                <Option value="independent">Independent</Option>
              </Select>
            </Form.Item>
          </Card>
          <div className="mv-btn-right mv-mt-20">
            <Button type="primary" htmlType="submit" loading={partyLoading} className="ant-btn-44">
              Save
            </Button>
          </div>
        </Form>
      </div>

      <Divider />

      <div className="mv-form-fields">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Card>
            <Form.Item
              label="Add Political Interests"
              name="interestName"
              className="mv-mb-15"
              rules={[
                {
                  required: true,
                  message: 'Interest must be required.',
                },
              ]}>
              <AutoComplete
                options={options}
                placeholder="Input Political Interest"
                onChange={checkConrol}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item name="viewPoint" className="mv-mb-0"
              rules={[
                {
                  required: true,
                  message: 'Your Views must be required.',
                },
              ]}>
              <Input.TextArea
                placeholder="Write Your Views"
                className="mv-h-100"
              />
            </Form.Item>
            <Col xs={24} sm={24} md={12} className="mv-mt-20">
              <Form.Item
                name="supportive"
                label="I support?"
                rules={[
                  {
                    required: true,
                    message: 'Support must be required.',
                  },
                ]}
              >
                <Radio.Group name="candidateGroup" >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <div className="mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb">
              <div className="mv-icon-btns mv-d-flex">
                {/* <Link
                  to="#"
                  className="ant-btn ant-btn-primary ant-btn-30 mv-mr-10"
                >
                  <EditIcon />
                </Link>
                <Link to="#" className="ant-btn ant-btn-danger ant-btn-30">
                  <DeleteIcon />
                </Link> */}
              </div>
              <Button type="primary" htmlType="submit" loading={isUpdating} className="ant-btn-44">
                Save
              </Button>
            </div>
          </Card>
        </Form>
        {
          intrestArray.length > 0 && <PoiticalList isEdit={true} isUpdate={isUpdating} onEdit={editInterest} onDelete={deleteInterest} />
        }
      </div>
    </>
  );
};

export default PoliticalInterests;
