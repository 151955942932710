// Libraries
import React, { useState } from 'react';
import { Row, Col, Card, Button, Form, Input } from 'antd';

// Component
import { AppLayout } from '../../components';
import NewProfileBanner from '../../components/NewProfileBanner';

// Styles
import './style.css';
import OpenSuccessNotification from '../../Notification/successNotif';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';

const UpdatePassword = () => {
    const [form] = Form.useForm();
    const [loading, setloading] = useState(false)
    const { token } = useToken();

    const onFinish = (values) => {
        console.log('Success:', values);

        if (values.newPassword != values.confirmNewPassword) {
            form.setFields([
                {
                    name: 'newPassword',
                    errors: ["New Password and Confirm Password are incorrect"],
                },
                {
                    name: 'confirmNewPassword',
                    errors: ["New Password and Confirm Password are incorrect"],
                },
            ]);
        } else {
            setloading(true)
            interceptor.axiosPut("Put", "/users/password", values, token).then(res => {
                setloading(false)
                if (res.status = 200) {
                    OpenSuccessNotification("topRight", 'Your password updated successfully')
                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);

            })
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        // <AppLayout>
        <main className=''>
            {/* <NewProfileBanner /> */}
            <Form
                name="updatePassword"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
                <Card>
                    <Row gutter={30}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                // label="New Password"
                                name="newPassword"
                                className='mv-mb-0 mv-mb-sm-15'
                            >
                                <Input.Password placeholder='Enter new password' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                // label="Confirm New Password"
                                name="confirmNewPassword"
                                className='mv-mb-0'
                            >
                                <Input.Password placeholder='Confirm new password' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='mv-btn-right mv-mt-20'>
                        <Button type="primary" htmlType="submit" loading={loading} className="ant-btn-44">
                            Update Password
                        </Button>
                    </div>
                </Card>
            </Form>

        </main>
        // </AppLayout >
    )
}

export default UpdatePassword;
