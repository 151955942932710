// Libraries
import { useState, useEffect } from 'react'
import { Card, Form, Button, AutoComplete, Input, Typography, Modal } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon, DeleteIcon } from '../../../../assets/icons/icons';

// Styles
import './style.css';

import { deleteQuotesAsync, getQuotesAsync, setQuotesAsync, updateQuotesAsync } from '../../../../shared/asyncstorage/quotes';
import uuidv4 from '../../../../shared/uuidgenerator';
import interceptor from '../../../../shared/interceptor';
import useToken from '../../../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../../../Notification/successNotif';

// Assets

const { Title } = Typography;

const Quotes = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quotesArray, setQuotesArray] = useState([]);
    const [model, setModel] = useState(null);
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const { token, getUserID } = useToken();
    const { loginUserObject } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();



    useEffect(() => {
        if (loginUserObject) {
            setQuotesArray(loginUserObject.userCandidateExtension?.quotes)
        }
        // getQuotesAsync().then(res => {
        //     setQuotesArray(res)
        // })
    }, [loginUserObject])

    const onFinish = (values) => {
        console.log('Success:', values);
        setLoading(true)
        interceptor.axiosPost("Post", "/quotes", values, token).then(res => {
            if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Added")
                dispatcher(actionsApi.UpdateQuotes(res.data))
            }
            handleCancel()
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setLoading(false)

        })


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // setPoliticalI
    };


    const showModal = () => {
        setIsModalOpen(true);
        setModel(null)
        form.setFieldsValue({
            quotesTitle: '',
            quotesBy: '',
            quotes: ''
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const deleteQuotes = (item) => {
        interceptor.axiosDelete("Delete", "/quotes/" + item.quoteId, null, token).then(res => {
            if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Remove")
                dispatcher(actionsApi.deleteQuotes(item))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);

        })

    }
    const editQuotes = (value) => {
        setIsModalOpen(true);
        setModel(value)
        form.setFieldsValue({
            quotesTitle: value.quotesTitle,
            quotesBy: value.quotesBy,
            quotes: value.quotes,
        });
    }

    return (
        <>
            <div className='mv-form-fields mv-mt-20'>
                <div className='mv-d-flex mv-jc-sb'>
                    <Title level={2}>Quotes</Title>
                    <Link to="#" onClick={() => showModal()} className='ant-btn ant-btn-danger ant-btn-44'>Add New</Link>
                </div>

                {
                    quotesArray.length > 0 &&
                    quotesArray.map((value, key) => {
                        return (
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Card>
                                    <div
                                        name="title"
                                        className='mv-mb-15'
                                    >

                                        <Input value={value.title} placeholder="Quotes Title" />
                                    </div>
                                    <div
                                        name="by"
                                        className='mv-mb-15'
                                    >
                                        <Input placeholder="Quotes By" value={value.by} />
                                    </div>
                                    <div
                                        name="quoteText"
                                        className='mv-mb-0'
                                    >
                                        <Input.TextArea placeholder="Input Quote" value={value.quoteText} className='mv-h-100' />
                                    </div>
                                    <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                        <div className='mv-icon-btns mv-d-flex'>
                                            {/* <Link to="#" onClick={() => editQuotes(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                                <EditIcon />
                                            </Link> */}
                                            <Link to="#" onClick={() => deleteQuotes(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                                <DeleteIcon />
                                            </Link>
                                        </div>
                                        {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    </div>
                                </Card>
                            </Form>
                        )
                    })
                }

                {/*  */}

                <>
                    <Modal
                        title="Add/Edit Quote"
                        open={isModalOpen}
                        onOk={handleOk}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                            form={form}
                        >
                            <Card>
                                <Form.Item
                                    name="title"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="Quote Title" />
                                </Form.Item>
                                <Form.Item
                                    name="by"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="Quote By" />
                                </Form.Item>
                                <Form.Item
                                    name="text"
                                    className='mv-mb-0'
                                >
                                    <Input.TextArea placeholder="Input Quote" className='mv-h-100' />

                                </Form.Item>

                                <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>

                                    <Button type="primary" htmlType="submit" loading={loading} className='ant-btn-44'>Save</Button>
                                </div>
                            </Card>
                        </Form>
                    </Modal>
                </>
            </div>
        </>
    )
}

export default Quotes;
