import { updateLocalStorage } from './localStorage'
var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setIntroAsync = (array) => {
    userList[index].intro = array
    user.intro = array
    updateLocalStorage(user, userList)

}
export const getIntroAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.intro
    return items ? items : null
}

export const getIntroLS = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.intro;
    return items ? items : null
}

export const getAffiliatioonLS = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users;
    return items ? items : null
}

export const deleteIntroAsync = (array) => {
    userList[index].intro = array
    user.intro = array
    updateLocalStorage(user, userList)
}


export const setPoliticalAffiliation = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].politicalAffiliation = array
    user.politicalAffiliation = array
    updateLocalStorage(user, userList)

}
export const getPoliticalAffiliation = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.politicalAffiliation
    return items ? items : null
}



export const setLoc = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].location = array
    user.location = array
    updateLocalStorage(user, userList)

}
export const getLoc = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.location
    return items ? items : null
}


