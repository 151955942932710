import { updateLocalStorage } from './localStorage'
var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setInterviewAsync = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].interview.push(array)
    user.interview = userList[index].interview
    updateLocalStorage(user, userList)

}
export const getInterviewAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.interview
    //JSON.parse(localStorage.getItem('@interview'));
    return items ? items : []
}

export const getInterviewLSAsync =  (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.interview;
    return items ? items : null
}


export const deleteInterviewAsync = (array) => {
    userList[index].interview = array
    user.interview = array
    updateLocalStorage(user, userList)
}

export const updateInterviewAsync = (array) => {
    userList[index].interview = array
    user.interview = array
    updateLocalStorage(user, userList)
}
