// Libraries
import React, { useState, useEffect } from 'react'
import { Typography, Card, Image } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import userImg from '../../assets/images/user.png';
import { ProfileImageMap } from '../../data/ProfileImageMap';
import { CandidiateArray } from '../../data/CandidateDataJson';
import { useShareDispatch, useShareSelector } from '../../shared';
import { IMAGE_URL } from '../../enviroment/Envirments';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';

const { Title, Paragraph } = Typography;

const User = () => {
    const { userId } = useParams();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    let user = loginUserObject?.userBase
    const { token, } = useToken();
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    useEffect(() => {


        // const items = JSON.parse(localStorage.getItem('@user'));
        // console.log("test", items)
        // if (items) {
        //     setDate(items);
        //     setUserName(items.firstName)
        //     setEmail(items.username)
        //     // let img = ProfileImageMap.get(props.profileName)
        // }

        // let findUser = CandidiateArray.find(x => x.name === items.firstName)
        // if (findUser) {
        //     setType(findUser.userId)
        // }
        // else {
        //     setType(userId)
        // }
    }, []);

    const getPublicUserDetail = async (id) => {
        let model = {
            token: token,
            userId: id
        }
        interceptor.axiosGet("Get", "/users/" + id + "/public", token).then(res => {
            if (res.status == 200) {
                dispatcher(gettingPublicResponseSuccess(res.data))
                let user = res.data?.userBase
                if (user?.userType == "CANDIDATE") {
                    navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                } else {
                    navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                }

            }
            else {
                dispatcher(gettingPublicResponseSuccess({}))
            }
        }).catch(error => {
            dispatcher(gettingPublicResponseError(error))
        })
    }
    useEffect(() => {

    }, [])
    return (
        <div className='mv-user'>
            <Card>
                {/* <Link to={user?.userType == "CANDIDATE" ? `/view-candidate/${user?.userId}/${user?.userType}/${user?.email}` : `/view-user/${user?.userId}/${user?.userType}/${user?.userName}`}> */}
                <a onClick={() => getPublicUserDetail(user?.userId)}>
                    <div className='mv-user-content mv-d-flex'>
                        <Image className="mv-img-55" src={loginUserObject?.userProfilePicture?.contentId ? IMAGE_URL + loginUserObject?.userProfilePicture?.contentId : userImg} alt="" preview={false} />
                        <div className='mv-user-meta'>
                            <Title level={4}>{user?.userName ? user?.userName : ""}</Title>
                            <Paragraph>{user?.email}</Paragraph>
                        </div>
                    </div>
                </a>

                {/* </Link> */}
            </Card>
        </div>
    )
}

export default User;
