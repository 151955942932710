import bannerImg from '../assets/images/banner-img.jpg';
import userImg from '../assets/images/user.png';

export const EditProfileBannerData = {
    bannerImg: bannerImg,
    userImg: userImg,
    name: "Jhon Doe",
    userName: "@jhondoe",
    user: "New/Incumbent",
    party: "Political Party",
    button: "Follow"
}