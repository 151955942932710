import slideOneImg from '../assets/images/auth-slide-01.jpg';
import slideTwoImg from '../assets/images/auth-slide-02.jpg';
import slideThreeImg from '../assets/images/auth-slide-03.jpg';

export const AuthSliderData = [
    {
        img: slideOneImg,
        title: 'Connect with the world of politics',
        description: ''
    },
    {
        img: slideTwoImg,
        title: 'Connect with the world of politics',
        description: ''
    },
    {
        img: slideThreeImg,
        title: 'Connect with the world of politics',
        description: ''
    },
]