// Libraries
import React from 'react';

// Components
import { Candidate } from './components';

// Styles
import './style.css';

// Assets

const CandidatePage = () => {
    return (
        <Candidate />
    )
}

export default CandidatePage;
