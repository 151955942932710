import { configureStore } from "@reduxjs/toolkit";
import UserLoginDetails from "./actions/user/UserLoginDetails";
import PublicUserDetails from "./actions/user/PublicUser";



const store = configureStore({
	reducer: {
		UserLoginDetails,
		PublicUserDetails
	},

	// middleware:[...getDefaultMiddleware,loggerMidleWare]
	// The thunk middleware was automatically added
	// https://github.com/reduxjs/redux-thunk
});

export default store;

