// Libraries
import { Typography, Image } from 'antd';
import moment from 'moment';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// Component

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

// Assets
import slideOneImg from '../../../../assets/images/slide-01.jpg';
import slideTwoImg from '../../../../assets/images/slide-02.jpg';
import slideThreeImg from '../../../../assets/images/slide-03.jpg';
import { PrevArrowIcon, NextArrowIcon } from '../../../../assets/icons/icons';

const { Title, Paragraph } = Typography;

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><NextArrowIcon /></div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        ><PrevArrowIcon /></div>
    );
}

const HaveLook = () => {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true
                }
            }
        ]
    };

    return (
        <div className='mv-have-look'>
            <div className='mv-container'>
                <Title level={2}>Have a look at <br />what's going on</Title>
                <Paragraph>Just because you do not take an interest in politics doesn't mean politics won't
                    take an interest in you</Paragraph>
                <Slider {...settings}>
                    <div>
                        <div className='mv-have-look-content'>
                            <Link to="#">
                                <Image src={slideOneImg} preview={false} alt="" />
                                <Title level={3}>Gun Control debate intensifies... </Title>
                                <ul className='mv-have-look-list mv-d-flex'>
                                    <li>By Sharifur</li>
                                    <li>{moment().format('LL')}</li>
                                </ul>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='mv-have-look-content'>
                            <Link to="#">
                                <Image src={slideTwoImg} preview={false} alt="" />
                                <Title level={3}>Another mask mandate on the horizon...</Title>
                                <ul className='mv-have-look-list mv-d-flex'>
                                    <li>By Sharifur</li>
                                    <li>{moment().format('LL')}</li>
                                </ul>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='mv-have-look-content'>
                            <Link to="#">
                                <Image src={slideThreeImg} preview={false} alt="" />
                                <Title level={3}>The overturning of Roe v Wade has shifted the republic</Title>
                                <ul className='mv-have-look-list mv-d-flex'>
                                    <li>By Sharifur</li>
                                    <li>{moment().format('LL')}</li>
                                </ul>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='mv-have-look-content'>
                            <Link to="#">
                                <Image src={slideOneImg} preview={false} alt="" />
                                <Title level={3}>Gun Control debate intensifies...</Title>
                                <ul className='mv-have-look-list mv-d-flex'>
                                    <li>By Sharifur</li>
                                    <li>{moment().format('LL')}</li>
                                </ul>
                            </Link>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default HaveLook;
