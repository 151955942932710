
import {
    registerUser, getUser, DeletePoliticalInterests,
    UpdateQuotes, UpdateSpeeech, UpdateInterviews,
    UpdateImportantLinks, UpdatePoliticalInterests, gettingResponseError
    , gettingResponseSuccess, logOutUser, deleteQuotes, deleteInterview, deleteSpeech, deleteLinks,
    updateConnections, updateFollowRequest,
} from "./user/UserLoginDetails";
import {
    getPublicUser, gettingPublicResponseSuccess, gettingPublicResponseError, setPublicPost, getPublicPost, updatePublicPost,

} from "./user/PublicUser";

export const actionsApi = {
    registerUser,
    getUser,
    setPublicPost,
    updatePublicPost,
    getPublicUser,
    getPublicPost,
    UpdateQuotes,
    logOutUser,
    gettingResponseSuccess,
    gettingResponseError,
    UpdateSpeeech, UpdateInterviews, UpdateImportantLinks, UpdatePoliticalInterests,
    DeletePoliticalInterests,
    gettingPublicResponseSuccess,
    gettingPublicResponseError,
    deleteQuotes, deleteInterview, deleteSpeech, deleteLinks,
     updateConnections, updateFollowRequest


}
