// Libraries
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useParams } from 'react-router-dom';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';
import { getSpeechesIntrestAsync, getSpeechesIntrestLS } from '../../../../shared/asyncstorage/SpeechesCandidateAsynsData';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const Speeches = () => {
    const [speechArray, setSpeechArray] = useState([]);
    const { username } = useParams();
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)

    useEffect(() => {
        if (publicUser?.userCandidateExtension?.speeches?.length > 0)
            setSpeechArray(publicUser?.userCandidateExtension?.speeches)
    }, [publicUser])

    useEffect(() => {
        // let checkLS = getSpeechesIntrestLS(username)
        // if (checkLS) {
        //     setSpeechArray(checkLS)
        // }
        // else {
        //     getSpeechesIntrestAsync().then(res => {
        //         setSpeechArray(res)
        //     })
        // }
    }, [])
    return (
        <>
            {
                speechArray.length > 0 &&
                speechArray.map((value, key) => {
                    return (
                        <div className='mv-view mv-mt-20'>
                            <Title level={2}>{value.linkTitle}</Title>
                            <div className='mv-player-wrapper'>
                                <ReactPlayer
                                    className='mv-react-player'
                                    url={value.linkUrl}
                                    // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                        </div>
                    )
                })
            }


        </>
    )




}

export default Speeches;
