export var userList = JSON.parse(localStorage.getItem('@addusers'));
export var user = JSON.parse(localStorage.getItem('@user'));


export var index = userList ? userList.findIndex(item => item.email == user.email) : null;


export const updateLocalStorage = async (item, list) => {
    localStorage.setItem('@user', JSON.stringify(item));
    localStorage.setItem('@addusers', JSON.stringify(list));
}