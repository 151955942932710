import { updateLocalStorage } from './localStorage'
var userList = JSON.parse(localStorage.getItem('@addusers'));
var user = JSON.parse(localStorage.getItem('@user'));
var index = userList ? userList.findIndex(item => item.email == user.email) : null;

export const setQuotesAsync = (array) => {
    userList = JSON.parse(localStorage.getItem('@addusers'))
    index = userList ? userList.findIndex(item => item.email == user.email) : null;
    userList[index].quotes.push(array)
    user.quotes=userList[index].quotes
    updateLocalStorage(user, userList)

}
export const getQuotesAsync = async () => {
    user = JSON.parse(localStorage.getItem('@user'));
    const items = await user?.quotes
    return items ? items : []
}

export const getQuotesLS = (username) => {
    let checkLS = JSON.parse(localStorage.getItem('@addusers'))
    let users = checkLS.find(x => x.firstName == username)
    const items = users?.quotes;
    return items ? items : null
}

export const deleteQuotesAsync = (array) => {
    userList[index].quotes = array
    user.quotes = array
    updateLocalStorage(user, userList)
}

export const updateQuotesAsync = (array) => {
    userList[index].quotes = array
    user.quotes = array
    updateLocalStorage(user, userList)
}
