import img1 from '../assets/images/slide-01.jpg';
import img2 from '../assets/images/slide-02.jpg';
import img3 from '../assets/images/slide-03.jpg';
import img4 from '../assets/images/auth-slide-01.jpg';
import img5 from '../assets/images/auth-slide-02.jpg';
import img6 from '../assets/images/auth-slide-03.jpg';
import img7 from '../assets/images/post-img.jpg';
import img8 from '../assets/images/hero.jpg';

export const PoliticalIssuesData = [
    {
        img: img1,
        title: "Abortion Rights"
    },
    {
        img: img2,
        title: "LGBT Rights"
    },
    {
        img: img3,
        title: "Gun Control"
    },
    {
        img: img4,
        title: "Freedom of Speech"
    },
    {
        img: img5,
        title: "Voter Registration Reform"
    },
    {
        img: img6,
        title: "Critical Race Theory"
    },
    {
        img: img7,
        title: "Pro-Life"
    },
    {
        img: img8,
        title: "Immigration Reform"
    },
]