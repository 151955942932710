
// Assets
import credibleImg from '../assets/images/credible.jpg';
import democratImg from '../assets/images/democrat.jpg';

import democratLogo from '../assets/images/democrat_logo_three.jpeg';
import republicanLogo from '../assets/images/republican_logo_four.jpeg';
import magaImage from '../assets/images/Trumpist.jpeg';
import ozSmiling from '../assets/images/mehmetOzSmiling.jpeg';

import teamPlayerImg from '../assets/images/team-player.jpg';
import bannerImg from '../assets/images/new-banner.jpg';
import { BannerImageMap, ProfileImageMap } from './ProfileImageMap';



const gunControlSupport = {
    writeViews: 'I support smart gun control',
    isSupported: true,
    politicalInterests: "Gun Control",
}

const gunControlAgainst = {
    writeViews: 'I do not support gun restrictions of any kind',
    isSupported: false,
    politicalInterests: "Gun Control",
}

const abortionRightsSupport = {
    writeViews: 'I support the freedom for a woman to choose',
    isSupported: true,
    politicalInterests: "Abortion Rights",
}

const abortionRightsAgainst = {
    writeViews: 'I am ardently pro-life',
    isSupported: false,
    politicalInterests: "Abortion Rights",
}

const lgbtRightsSupport = {
    writeViews: 'I support LGBT rights',
    isSupported: false,
    politicalInterests: "LGBT Rights",
}

const lgbtRightsAgainst = {
    writeViews: 'I do not support LGBT rights',
    isSupported: false,
    politicalInterests: "LGBT Rights",
}

const electionReformSupport = {
    writeViews: 'I support smart election reform',
    isSupported: true,
    politicalInterests: "Voter Registration Reform",
}

const electionReformAgainst = {
    writeViews: 'I do not support election reform',
    isSupported: false,
    politicalInterests: "Voter Registration Reform",
}

const immigrationReformSupport = {
    writeViews: 'I support immigration reform',
    isSupported: true,
    politicalInterests: "Immigration Reform",
}

const immigrationReformAgainst = {
    writeViews: 'I do not support immigration reform',
    isSupported: false,
    politicalInterests: "Immigration Reform",
}


const JohnFettermanInterestMap = new Map();
JohnFettermanInterestMap.set('Gun Control', gunControlSupport);
JohnFettermanInterestMap.set('Abortion Rights', abortionRightsSupport);
JohnFettermanInterestMap.set('LGBT Rights', lgbtRightsSupport);
JohnFettermanInterestMap.set('Voter Registration Reform', electionReformAgainst);
JohnFettermanInterestMap.set('Immigration Reform', immigrationReformAgainst);
JohnFettermanInterestMap.set('Political Party', 'Democrat');

const DerekGreenInterestMap = new Map();
DerekGreenInterestMap.set('Gun Control', gunControlSupport);
DerekGreenInterestMap.set('Abortion Rights', abortionRightsSupport);
DerekGreenInterestMap.set('LGBT Rights', lgbtRightsSupport);
DerekGreenInterestMap.set('Voter Registration Reform', electionReformAgainst);
DerekGreenInterestMap.set('Immigration Reform', immigrationReformSupport);
DerekGreenInterestMap.set('Political Party', 'Democrat');

const MariaSanchezInterestMap = new Map();
MariaSanchezInterestMap.set('Gun Control', gunControlSupport);
MariaSanchezInterestMap.set('Abortion Rights', abortionRightsSupport);
MariaSanchezInterestMap.set('LGBT Rights', lgbtRightsSupport);
MariaSanchezInterestMap.set('Voter Registration Reform', electionReformAgainst);
MariaSanchezInterestMap.set('Immigration Reform', immigrationReformAgainst);
MariaSanchezInterestMap.set('Political Party', 'Democrat');

const MartinaWhiteInterestMap = new Map();
MartinaWhiteInterestMap.set('Gun Control', gunControlAgainst);
MartinaWhiteInterestMap.set('Abortion Rights', abortionRightsAgainst);
MartinaWhiteInterestMap.set('LGBT Rights', lgbtRightsAgainst);
MartinaWhiteInterestMap.set('Voter Registration Reform', electionReformSupport);
MartinaWhiteInterestMap.set('Immigration Reform', immigrationReformSupport);
MartinaWhiteInterestMap.set('Political Party', 'Republican');

const MehmetOzInterestMap = new Map();
MehmetOzInterestMap.set('Gun Control', gunControlAgainst);
MehmetOzInterestMap.set('Abortion Rights', abortionRightsAgainst);
MehmetOzInterestMap.set('LGBT Rights', lgbtRightsSupport);
MehmetOzInterestMap.set('Voter Registration Reform', electionReformSupport);
MehmetOzInterestMap.set('Immigration Reform', immigrationReformSupport);
MehmetOzInterestMap.set('Political Party', 'Republican');

const LouBarlettaInterestMap = new Map();
LouBarlettaInterestMap.set('Gun Control', gunControlAgainst);
LouBarlettaInterestMap.set('Abortion Rights', abortionRightsAgainst);
LouBarlettaInterestMap.set('LGBT Rights', lgbtRightsAgainst);
LouBarlettaInterestMap.set('Voter Registration Reform', electionReformSupport);
LouBarlettaInterestMap.set('Immigration Reform', immigrationReformSupport);
LouBarlettaInterestMap.set('Political Party', 'Republican');

const DougMastrianoInterestMap = new Map();
DougMastrianoInterestMap.set('Gun Control', gunControlAgainst);
DougMastrianoInterestMap.set('Abortion Rights', abortionRightsAgainst);
DougMastrianoInterestMap.set('LGBT Rights', lgbtRightsAgainst);
DougMastrianoInterestMap.set('Voter Registration Reform', electionReformSupport);
DougMastrianoInterestMap.set('Immigration Reform', immigrationReformSupport);
DougMastrianoInterestMap.set('Political Party', 'Republican');


export const CandidiateArray = [
    {
        name: 'John Fetterman',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('John Fetterman'),
        description: 'John Fetterman is an American politician who has served as the 34th lieutenant governor of Pennsylvania since 2019. A member of the Democratic Party, he previously served as mayor of Braddock from 2006 to 2019.  Fetterman is the Democratic nominee for the 2022 United States Senate election in Pennsylvania.',
        username: '',
        education: ' Albright College (BA),  University of Connecticut (MBA), & Harvard University (MPP)',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: null,
        // { videoTitle: "Intro Video", videoLink: "https://www.youtube.com/watch?v=gVZ54a0K3a0", _id: "b4c8659d-d162-4e96-b6ee-57ad0fb2761d" },
        interview: [{
            interviewTitle: "InterView",
            interviewLink: "https://www.youtube.com/watch?v=gVZ54a0K3a0"
        }],
        backgroundMiddleRightSectionOne: 'Fetterman studied finance at Albright College and earned an MBA from the University of Connecticut. Following the death of a close friend, he joined AmeriCorps and earned an MPP from Harvard.',
        backgroundMiddleRightSectionTwo: 'Fetterman ran for Pennsylvania\'s Senate seat in 2016, where he finished third in the Democratic primary.  He ran for lieutenant governor of Pennsylvania in 2018, defeating a field of candidates that included incumbent Mike Stack in the Democratic primary and winning the election with incumbent Governor Tom Wolf.',
        backgroundMiddleRightSectionThree: "During his tenure, Fetterman received national attention for his efforts to legalize cannabis statewide and pushing back on President Donald Trump's false claims of election fraud in Pennsylvania.",
        backgroundBottomLeftPhotoUrl: credibleImg,
        backgroundBottomLeftTitle: 'PRINCIPLED',
        backgroundBottomLeftText: 'Fetterman was arrested while protesting the controversial closure of Braddock Hospital in Pittsburgh',
        backgroundBottomMiddlePhotoUrl: democratLogo,
        backgroundBottomMiddleTitle: 'DEMOCRAT',
        backgrounddBottomMiddleText: 'Fetterman is often described as a social and fiscal progressive.',
        backgroundBottomRightPhotoUrl: teamPlayerImg,
        backgroundBottomRightTitle: 'STALWART',
        backgrounddBottomRightText: "Fetterman ran the Board of Pardons with the heart of an activist and, at times, the force of a bully",
        affiliation: { politicalAffiliation: "Democrat", _id: "11f4805e-057b-4c10-8eaa-4603f4e527e9" },
        interestMap: JohnFettermanInterestMap,

    },

    {
        name: 'Derek Green',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('Derek Green'),
        description: 'A candiate who is blah blah blah',
        username: '',
        education: ' American University',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: { videoTitle: "Intro Video", videoLink: "https://www.youtube.com/watch?v=SnOTh0_SBmw", _id: "b4c8659d-d162-4e96-b6ee-57ad0fb2761d" },
        interview: [{
            interviewTitle: "InterView",
            interviewLink: "https://www.youtube.com/watch?v=SnOTh0_SBmw"
        }],
        backgroundMiddleRightSectionOne: 'Some Text',
        backgroundMiddleRightSectionTwo: 'Some Text',
        backgroundMiddleRightSectionThree: 'Some Text',
        backgroundBottomLeftPhotoUrl: '',
        backgroundBottomLeftTitle: 'A title',
        backgroundBottomLeftText: 'some text',
        backgroundBottomMiddlePhotoUrl: '',
        backgroundBottomMiddleTitle: 'a title',
        backgrounddBottomMiddleText: 'some text',
        backgroundBottomRightPhotoUrl: '',
        backgroundBottomRightTitle: 'a title',
        backgrounddBottomRightText: 'some text',
        affiliation: { politicalAffiliation: "Democrat", _id: "11f4805e-057b-4c10-8eaa-4603f4e527ebe5" },
        interestMap: DerekGreenInterestMap,


    },
    {
        name: 'Maria quiñones-sánchez',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('Maria quiñones-sánchez'),
        description: 'A candiate who is blah blah blah',
        username: '',
        education: ' American University',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: { videoTitle: "Intro Video", videoLink: "https://www.youtube.com/watch?v=Qz7dmh5BVJ0", _id: "b4c8659d-d162-4e96-b6ee-57ad0fb2761d" },
        interview: [{
            interviewTitle: "InterView",
            interviewLink: "https://www.youtube.com/watch?v=Qz7dmh5BVJ0"
        }],
        backgroundMiddleRightSectionOne: 'Some Text',
        backgroundMiddleRightSectionTwo: 'Some Text',
        backgroundMiddleRightSectionThree: 'Some Text',
        backgroundBottomLeftPhotoUrl: '',
        backgroundBottomLeftTitle: 'A title',
        backgroundBottomLeftText: 'some text',
        backgroundBottomMiddlePhotoUrl: '',
        backgroundBottomMiddleTitle: 'a title',
        backgrounddBottomMiddleText: 'some text',
        backgroundBottomRightPhotoUrl: '',
        backgroundBottomRightTitle: 'a title',
        backgrounddBottomRightText: 'some text',
        affiliation: { politicalAffiliation: "Democrat", _id: "11f4805e-057b-4c10-8eaa-4603f4e527eg" },
        interestMap: MariaSanchezInterestMap,


    },
    {
        name: 'Martina White',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('Martina White'),
        description: 'A candiate who is blah blah blah',
        username: '',
        education: ' American University',
        quotes: [],
        links: [],
        speechesCandidate: [],
        introVideo: null,
        intro: null,
        interview: [],
        backgroundMiddleRightSectionOne: 'Some Text',
        backgroundMiddleRightSectionTwo: 'Some Text',
        backgroundMiddleRightSectionThree: 'Some Text',
        backgroundBottomLeftPhotoUrl: '',
        backgroundBottomLeftTitle: 'A title',
        backgroundBottomLeftText: 'some text',
        backgroundBottomMiddlePhotoUrl: '',
        backgroundBottomMiddleTitle: 'a title',
        backgrounddBottomMiddleText: 'some text',
        backgroundBottomRightPhotoUrl: '',
        backgroundBottomRightTitle: 'a title',
        backgrounddBottomRightText: 'some text',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527ea" },
        interestMap: MartinaWhiteInterestMap,


    }
    ,
    {
        name: 'Mehmet Oz',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('Mehmet Oz'),
        description: 'Known professionally as Dr. Oz, Mehmet Oz is a Turkish-American television presenter, author, professor emeritus, and retired cardiothoracic surgeon.  He is the Republican nominee for the 2022 United States Senate election in Pennsylvania and is the first Muslim candidate to be nominated by either major party for U.S. Senate.',
        username: '',
        education: 'Harvard University (BA), University of Pennsylvania (MD, MBA)',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: [],
        interview: [],
        backgroundMiddleRightSectionOne: 'In 1982, he received his undergraduate degree in biology magna cum laude at Harvard University.  He played safety on Harvard’s football team and was a goalie of the men’s varsity water polo team.  In 1986, he obtained MD and MBA degrees from the University of Pennsylvania School of Medicine and Penn’s Wharton School.  He was awarded the Captain’s Athletic Award for leadership in college and was class president and then student body president during medical school.',
        backgroundMiddleRightSectionTwo: 'Oz began his medical career with a residency at the Presbyterian Hospital in New York City, then affiliated with Columbia University, in 1986 after being hired by Eric Rose.  During his residency, Oz earned the Blakemore research award.  In April 1995, Oz and his colleague Jerry Whitworth founded the Cardiac Complementary Care Center to provide various types of alternative medicine to heart disease patients.',
        backgroundMiddleRightSectionThree: 'In 2007, it was reported that Oz had been active in his local chapter of the Republican Party of New Jersey for several years, and had donated to Republicans John McCain and Bill Frist. He supported the re-election campaign of President George W. Bush in 2004 and the candidacy of Shmuley Boteach, a rabbi who ran for Congress as a Republican in New Jersey in 2012.',
        backgroundBottomLeftPhotoUrl: magaImage,
        backgroundBottomLeftTitle: 'TRUMPER',
        backgroundBottomLeftText: 'On April 9, 2022, Oz\'s campaign was endorsed by former president Donald Trump as his views aligned to the common tenants of Trumpism',
        backgroundBottomMiddlePhotoUrl: republicanLogo,
        backgroundBottomMiddleTitle: 'REPUBLICAN',
        backgrounddBottomMiddleText: 'The Republican primary was held on May 17. A day after the election, Oz narrowly led his main opponent David McCormick by a difference of just 0.1% of the vote.',
        backgroundBottomRightPhotoUrl: ozSmiling,
        backgroundBottomRightTitle: 'TRAILBLAZER',
        backgrounddBottomRightText: 'If elected, he would be the first Muslim to serve in the U.S. Senate and the first Muslim to serve in the United States Congress as a Republican',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527eb" },
        interestMap: MehmetOzInterestMap,

    },
    {
        name: 'Lou Barletta',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('Lou Barletta'),
        description: 'A candiate who is blah blah blah',
        username: '',
        education: ' American University',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: null,
        interview: [],
        backgroundMiddleRightSectionOne: 'Some Text',
        backgroundMiddleRightSectionTwo: 'Some Text',
        backgroundMiddleRightSectionThree: 'Some Text',
        backgroundBottomLeftPhotoUrl: '',
        backgroundBottomLeftTitle: 'A title',
        backgroundBottomLeftText: 'some text',
        backgroundBottomMiddlePhotoUrl: '',
        backgroundBottomMiddleTitle: 'a title',
        backgrounddBottomMiddleText: 'some text',
        backgroundBottomRightPhotoUrl: '',
        backgroundBottomRightTitle: 'a title',
        backgrounddBottomRightText: 'some text',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527epn" },
        interestMap: LouBarlettaInterestMap,


    },
    {
        name: 'Doug Mastriano',
        position: 'Candidate',
        userId: 1,
        bannerImage: BannerImageMap.get('JDoug Mastrian'),
        description: 'A candiate who is blah blah blah',
        username: '',
        education: ' American University',
        quotes: [],
        links: [],
        speechesCandidate: [],
        intro: null,
        interview: [],
        backgroundMiddleRightSectionOne: 'Some Text',
        backgroundMiddleRightSectionTwo: 'Some Text',
        backgroundMiddleRightSectionThree: 'Some Text',
        backgroundBottomLeftPhotoUrl: '',
        backgroundBottomLeftTitle: 'A title',
        backgroundBottomLeftText: 'some text',
        backgroundBottomMiddlePhotoUrl: '',
        backgroundBottomMiddleTitle: 'a title',
        backgrounddBottomMiddleText: 'some text',
        backgroundBottomRightPhotoUrl: '',
        backgroundBottomRightTitle: 'a title',
        backgrounddBottomRightText: 'some text',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527en" },
        interestMap: DougMastrianoInterestMap,


    },
    {
        name: 'Jhon Doe',
        position: 'Standard User',
        userId: 2,
        bannerImage: ProfileImageMap.get('Jhon Doe'),
        username: '',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527en" },
        interestMap: DougMastrianoInterestMap,
    },
    {
        name: 'Mathilda Brinker',
        position: 'Standard User',
        userId: 2,
        bannerImage: ProfileImageMap.get('Mathilda Brinker'),
        username: '',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527en" },
        interestMap: DougMastrianoInterestMap,
    },
    {
        name: 'Sarah Hetfield',
        position: 'Standard User',
        userId: 2,
        bannerImage: ProfileImageMap.get('Sarah Hetfield'),
        username: '',
        affiliation: { politicalAffiliation: "Republican", _id: "11f4805e-057b-4c10-8eaa-4603f4e527en" },
        interestMap: DougMastrianoInterestMap,
    },

]