// Libraries
import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';

// Components
import {
    AppLayout,
    NewProfileBanner,
    SendPost,
    Post,
} from '../../components';
import {
    Introduction,
    PoliticalInterests,
    Speeches,
    Interviews,
    Quotes,
    ImportantLinks
} from './components';
import { ImagePost, ImagePostArray } from '../../data/PostData';


// Styles
import './style.css';
import { CandidiateArray } from '../../data/CandidateDataJson';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useShareDispatch, useShareSelector } from '../../shared';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';
import { IMAGE_URL } from '../../enviroment/Envirments';

// Assets
import userImg from '../../assets/images/user.png'

const { Footer } = Layout;
const { TabPane } = Tabs;

const ViewCandidate = () => {
    const [user, setuser] = useState(JSON.parse(localStorage.getItem('@user')));
    const [postArray, setPostArray] = useState([]);
    const location = useLocation();
    const [publicUsers, setPublicUsers] = useState(null);
    const { username, userId } = useParams();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const { token, } = useToken();
    const navigate = useNavigate();
    const dispatcher = useShareDispatch();
    useEffect(() => {
        interceptor.axiosGet("Get", "/posts/" + userId, token).then(res => {
            if (res.status == 200)
                setPostArray(res.data)

        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
        })
    }, [location])

    useEffect(() => {
        
        if (!publicUser.userBase) {
            interceptor.axiosGet("Get", "/users/" + userId + "/public", token).then(res => {
                if (res.status == 200) {
                    dispatcher(gettingPublicResponseSuccess(res.data))
                    let user = res.data?.userBase
                    if (user?.userType == "CANDIDATE") {
                        navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                    } else {
                        navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                    }

                }
                else {
                    dispatcher(gettingPublicResponseSuccess({}))
                }
            }).catch(error => {
                dispatcher(gettingPublicResponseError(error))
            })
        }


    }, [publicUser, location])
    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700'>
                <NewProfileBanner public='true' />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Introduction" key="1">
                        <Introduction />
                    </TabPane>
                    <TabPane tab="Political Interests" key="2">
                        <PoliticalInterests />
                    </TabPane>
                    <TabPane tab="Speeches" key="3">
                        <Speeches />
                    </TabPane>
                    <TabPane tab="Interviews" key="4">
                        <Interviews />
                    </TabPane>
                    <TabPane tab="Quotes" key="5">
                        <Quotes />
                    </TabPane>
                    <TabPane tab="Important Links" key="6">
                        <ImportantLinks />
                    </TabPane>
                    <TabPane tab="Posts" key="7">
                        <React.Fragment>
                            {/* <SendPost /> */}
                            {postArray?.length > 0 &&
                                postArray.map((item, key) => {
                                    return (
                                        <Post
                                            postId={item.postId}
                                            text={item?.postBase?.postStr}
                                            textOne={item.textOne}
                                            profileName={item.postUserName}
                                            profilePic={item.postUserProfilePicture.contentId ? IMAGE_URL + item.postUserProfilePicture.contentId : userImg}
                                            img={item.postContent?.contentTitle == "post_photo" ? item.postContent?.contentId : null}
                                            time={item.postBase.postTimestamp}
                                            video={item.postContent?.contentTitle == "post_video" ? item.postContent?.contentId : null}
                                            likes={item.postLikes}
                                            comments={item.postComments}
                                            postUserId={item.postBase.userId}
                                            user={loginUserObject?.userProfilePicture?.contentId}
                                            liked={item.likedByCurrentUser}
                                            followed={item.followedByCurrentUser}

                                        />
                                    )
                                })
                            }
                        </React.Fragment>
                    </TabPane>
                </Tabs>
            </main>
            {/* <Footer>© MyVote 2022. All rights reserved.</Footer> */}
        </AppLayout>
    )
}

export default ViewCandidate;