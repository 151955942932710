// Libraries
import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthSlider } from '../../components';

// Component

// Styles
import './style.css';
import useToken from '../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import interceptor from '../../shared/interceptor';
import { getUSerAccessToken } from '../../apis/auth/accesToken';
import { loginUser } from '../../apis/auth/authApis';
import OpenErrorNotification from '../../Notification/errorNotif';
import { useForm } from 'antd/lib/form/Form';
// Component
// var fs = require('browserify-fs');

var file = 'file.json';
// Assets

const { Title, Paragraph } = Typography;

const SignIn = () => {
    const naviagte = useNavigate()
    const [itemsArra, setItems] = useState([]);
    const [isSigning, setIsSigning] = useState(false)
    const { saveLoginUser, removeToken, getUserID, userID } = useToken();
    const { isLoadingUserRole, loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
    }, [])

    const onFinish = async (values) => {
        setIsSigning(true)
        try {
            const body = {
                client_id: "ellis",
                client_secret: "test",
                scope: "none",
                ...values,
            };
            const token = await getUSerAccessToken(body);
            if (token) {
                saveLoginUser(token)
                let userId = getUserID()
                loginUser(userId, token.accessToken, values).then(res => {
                    dispatcher(actionsApi.gettingResponseSuccess(res))
                    naviagte("/home")

                }).catch(error => {
                    OpenErrorNotification("topRight", error?.response.data.messageStr)
                    dispatcher(actionsApi.gettingResponseError(error))
                })
            }
        } catch (err) {
            OpenErrorNotification("topRight", err?.data.messageStr)
            form.setFields([
                {
                    name: 'password',
                    errors: [err?.data.messageStr],
                },
                {
                    name: 'email',
                    errors: [err?.data.messageStr],
                },
            ]);

            setIsSigning(false)

        }


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='mv-auth'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} className="mv-auth-first-col">
                    <AuthSlider />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <div className='mv-auth-content'>
                        <Paragraph className='mv-auth-link mv-auth-link-top'>
                            Don't have account? <Link to="/signup">Sign Up</Link>
                        </Paragraph>
                        <div className='mv-auth-form'>
                            <div className='mv-auth-header'>
                                <Title level={1}>Sign In</Title>
                                <Paragraph>Welcome to MyVote, a platform to connect <br />
                                    with the political world</Paragraph>
                            </div>
                            <Form
                                name="signInForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                                form={form}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email Address' />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Input your password',
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder='Password' />
                                </Form.Item>
                                <Paragraph className='mv-auth-link mv-d-flex'>
                                    <Link to="/forgot-password">Forgotten password?</Link>
                                </Paragraph>
                                <Button type="primary" loading={isSigning} htmlType="submit">Sign In</Button>
                                {/* <Paragraph className='mv-auth-text'>Or, Sign In with</Paragraph> */}
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SignIn;
