// Libraries
import { Typography, Card, Row, Col, Image } from 'antd';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useLocation, useParams } from 'react-router-dom';
// Styles
import './style.css';
import useFitText from "use-fit-text";
// Assets
import { useShareSelector } from '../../../../shared';
import { IMAGE_URL } from '../../../../enviroment/Envirments';
import { Textfit } from 'react-textfit';

const { Title, Paragraph } = Typography;


const Introduction = () => {

    const { username } = useParams();
    const location = useLocation();
    const [intro, setIntro] = useState([]);
    const [user, setUser] = useState([]);
    const [userCandidateExtensionBase, setUserCandidateExtensionBase] = useState([]);
    const [affiliation, setAffiliation] = useState('');
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)


    useEffect(() => {
        setAffiliation(publicUser?.userBase?.party)
        setIntro(publicUser?.userCandidateExtension?.introductoryVideoLink)
        setUserCandidateExtensionBase(publicUser?.userCandidateExtension?.userCandidateExtensionBase)
        // const findUser = CandidiateArray.find(x => x.name === username)
        // let item = getAffiliatioonLS(username)
        // setUser(findUser)
        // if (item?.politicalAffiliation) {
        //     setAffiliation(item?.politicalAffiliation.politicalAffiliation)
        // } else {
        //     setAffiliation(findUser?.affiliation.politicalAffiliation)

        // }

        // let checkLS = getIntroLS(username)
        // if (checkLS) {
        //     setIntro(checkLS)
        // }
        // else {

        //     getIntroAsync().then(res => {
        //         setIntro(res)
        //     })
        // }


    }, [publicUser]);
    const { fontSize, ref } = useFitText();
    return (
        <div className='mv-view mv-mt-20'>
            <>
                <div className='mv-view mv-mt-20'>
                    <div className='mv-profile-card mv-level-three'>
                        <Card className='mv-blue-block'>
                            <div className='mv-blue'>
                                <Title level={3}>Profile</Title>
                                <Paragraph> <span dangerouslySetInnerHTML={{ __html: userCandidateExtensionBase?.background ? JSON.parse(userCandidateExtensionBase?.background) : "" }}></span></Paragraph>

                                {/* <Paragraph>Councilmember Allan Domb is a second-term At-Large (or citywide) member of Philadelphia's City Council who came to elected office with almost 40 years of experience as an entrepreneur in the real estate industry. As an elected leader that represents 15 million Philadelphians, Councilmember Domb is focused on community safety, reducing poverty, growing job opportunities for residents, attracting new businesses to expand the city's tax base, and creating a more fiscally-responsive and efficient government.</Paragraph> */}
                            </div>
                            <div className='mv-background'>
                                <Row gutter="20">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <Card className=''>
                                            <Title level={3}>Education</Title>

                                            <Textfit max={13} min={8}  mode="multi" style={{ height: "245px" , width:"80%"}}>
                                                <span className=''
                                                    dangerouslySetInnerHTML={{ __html: userCandidateExtensionBase?.education ? JSON.parse(userCandidateExtensionBase?.education) : "" }}></span>
                                            </Textfit>
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mv-background-col-">
                                        <Card className=''>
                                            <Textfit max={13} min={8}  mode="multi" style={{ height: "70px" }}>
                                                {userCandidateExtensionBase?.politicalAccomplishmentOne}
                                            </Textfit>

                                        </Card>
                                        <Card className=''>
                                            <div className='mv-background-title'>
                                                <span>Back-ground</span>
                                            </div>
                                            <Textfit max={13} min={8} mode="multi" style={{ height: "30px", paddingLeft: "55px" }}>
                                                {userCandidateExtensionBase?.politicalAccomplishmentTwo}
                                            </Textfit>
                                        </Card>
                                        <Card className=''>

                                            <Textfit max={13} min={8}  mode="multi" style={{ height: "50px" }}>
                                                {userCandidateExtensionBase?.politicalAccomplishmentThree}
                                            </Textfit>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            <div className={(publicUser?.userBase?.party === 'Republican' || publicUser?.userBase?.party === 'republican') ? 'mv-profile-cols mv-profile-cols-red' : 'mv-profile-cols'}>
                                <Row gutter={[20]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                        <Card>
                                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension?.candidateAttributeOneContent?.contentId} alt="" />
                                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeOneTitle}</Title>
                                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeOneDescription}</Paragraph>
                                            {/* <Title level={2}>{user?.backgroundBottomLeftTitle}</Title>
                                        <Paragraph>{user?.backgroundBottomLeftText}</Paragraph> */}
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                        <Card>
                                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension?.candidateAttributeTwoContent?.contentId} alt="" />
                                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeTwoTitle}</Title>
                                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeTwoDescription}</Paragraph>
                                            {/* <Title level={2}>{user?.backgroundBottomMiddleTitle}</Title>
                                        <Paragraph>{user?.backgrounddBottomMiddleText}</Paragraph> */}
                                        </Card>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                        <Card>
                                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension?.candidateAttributeThreeContent?.contentId} alt="" />
                                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeThreeTitle}</Title>
                                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeThreeDescription}</Paragraph> {/* <Title level={2}>{user.backgroundBottomRightTitle}</Title>
                                        <Paragraph>{user.backgrounddBottomRightText}</Paragraph> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </div>
                </div>
                {/* <div className='mv-profile-card mv-level-three'>
                    <Card>
                        <Title level={3}>Profile</Title>
                        <span dangerouslySetInnerHTML={{ __html: userCandidateExtensionBase?.background }}></span>
                        <Title level={3}>Background</Title>
                        <ul className='mv-list'>
                            <li dangerouslySetInnerHTML={{ __html: userCandidateExtensionBase?.education }}></li>
                        </ul>
                    </Card>
                </div>
                <div className={(affiliation == 'Republican' || affiliation == 'republican') ? 'mv-profile-cols mv-profile-cols-red' : 'mv-profile-cols'}>
                    <Row gutter={[20]}>
                        <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                            <Card>
                                <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeOneContent?.contentId} alt="" />
                                <Title level={2}>{userCandidateExtensionBase?.candidateAttributeOneTitle}</Title>
                                <Paragraph>{userCandidateExtensionBase?.candidateAttributeOneDescription}</Paragraph>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                            <Card>
                                <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeTwoContent?.contentId} alt="" />
                                <Title level={2}>{userCandidateExtensionBase?.candidateAttributeTwoTitle}</Title>
                                <Paragraph>{userCandidateExtensionBase?.candidateAttributeTwoDescription}</Paragraph>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                            <Card>
                                <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeThreeContent?.contentId} alt="" />
                                <Title level={2}>{userCandidateExtensionBase?.candidateAttributeThreeTitle}</Title>
                                <Paragraph>{userCandidateExtensionBase?.candidateAttributeThreeDescription}</Paragraph>
                            </Card>
                        </Col>
                    </Row>
                </div> */}
            </>
            {intro?.linkUrl &&
                <Card>
                    <Title level={2}>{intro?.linkTitle}</Title>
                    <div className='mv-player-wrapper'>
                        <ReactPlayer
                            className='mv-react-player'
                            url=""
                            // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                            width='100%'
                            height='100%'
                        />
                    </div>
                </Card>
            }
        </div>
    )
}

export default Introduction;
