// Libraries
import { Row, Col, Button, Form, Input, Typography, Radio, Select } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthSlider } from '../../components';
import useToken from '../../hooks/useToken';
import OpenSuccessNotification from '../../Notification/successNotif';
import interceptor from '../../shared/interceptor';
import { accesToken } from '../../apis/auth/accesToken';


// Component

// Styles
import './style.css';

// Assets

const { Title, Paragraph } = Typography;
const { Option } = Select;

const ForgotPassword = () => {
    const [loading, setloading] = useState(false)
    const naviagte = useNavigate()
    const { token } = useToken();

    const onFinish = async (values) => {
        console.log('Success:', values);
        setloading(true)
        const body = {
            client_id: "ellis",
            client_secret: "test",
            scope: "none",
        };
        const token = await accesToken(body);
        debugger
        interceptor.axiosPut("Put", "/users/reset-password", values, token.accessToken).then(res => {
            setloading(false)
            if (res.status == 200) {
                OpenSuccessNotification("topRight", 'Temporary password is send to your resgister email')
                naviagte("/signin")

            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);

        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='mv-auth'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} className="mv-auth-first-col">
                    <AuthSlider />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <div className='mv-auth-content mv-auth-f-p'>
                        <Paragraph className='mv-auth-link mv-auth-link-top'>
                            Already havean account? <Link to="/signin">Sign In</Link>
                        </Paragraph>
                        <div className='mv-auth-form'>
                            <div className='mv-auth-header'>
                                <Title level={1}>Forgot Password</Title>
                                <Paragraph>Welcome to MyVote, a platform to connect <br />
                                    with the political world</Paragraph>
                            </div>
                            <Form
                                name="signUpForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder='Email Address' />
                                </Form.Item>
                                <Button type="primary" htmlType='submit' loading={loading}>Send</Button>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ForgotPassword;
