
import { notification } from 'antd';

const OpenErrorNotification = (placement, desciption) => {
    notification.error({
        message: "Error",
        description: desciption,
        placement,
    });
};

export default OpenErrorNotification;

