// Libraries
import { Typography, Image, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Linkedin, Youtube } from 'react-feather';

// Component

// Styles
import './style.css';

// Assets
import footerLogo from '../../assets/images/footer-logo.svg';

const { Title, Paragraph } = Typography;

const Footer = () => {
    return (
        <div className='mv-footer'>
            <div className='mv-footer-top'>
                <div className='mv-container'>
                    <div className='mv-footer-content'>
                        <Row gutter={[25]}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={10}>
                                <Image src={footerLogo} preview={false} alt="" />
                                <ul className='mv-footer-links'>
                                    <li>
                                        <Link to="mainlto:jamar.mofield@myvotepolitics.com">jamar.mofield@myvotepolitics.com</Link>
                                    </li>
                                    <li>
                                        <Link to="tel:215-460-4607">215-460-4607</Link>
                                    </li>
                                </ul>
                                <ul className='mv-social-media mv-d-flex'>
                                    <li>
                                        <Link to="#">
                                            <Twitter size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <Facebook size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <Linkedin size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <Youtube size={16} />
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={14}>
                                <Row gutter={[25]}>
                                    <Col xs={24} sm={8}>
                                        <Title level={2}>Quick Links</Title>
                                        <ul className='mv-footer-links mv-links'>
                                            <li>
                                                <Link to="#">News & Events</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Policies & Rules</Link>
                                            </li>
                                            <li>
                                                <Link to="#">FAQ</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Legal & Privacy</Link>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <Title level={2} className="mv-empty-title">&nbsp;</Title>
                                        <ul className='mv-footer-links mv-links'>
                                            <li>
                                                <Link to="#">Downloads</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <Title level={2}>Other links</Title>
                                        <ul className='mv-footer-links mv-links'>
                                            <li>
                                                <Link to="#">Terms of Use</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Help</Link>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className='mv-footer-copyright'>
                <div className='mv-container'>
                    © MyVote 2022. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer;
