import _Hero from './Hero';
import _Stats from './Stats';
import _About from './About';
import _GetConnected from './GetConnected';
import _HaveLook from './HaveLook';

export const Hero = _Hero;
export const Stats = _Stats;
export const About = _About;
export const GetConnected = _GetConnected;
export const HaveLook = _HaveLook;