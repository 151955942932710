// Libraries
import { useEffect, useState } from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';

// Component

// Styles
import './style.css';
import { getCandidatePoliticalInterest, getPoliticalIntresfromLStAsync, getPoliticalIntrestAsync } from '../../../../shared/asyncstorage/politicalIntrest';
import { useParams } from 'react-router-dom';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';
import { IMAGE_URL } from '../../../../enviroment/Envirments';

// Assets

const { Title, Paragraph } = Typography;

const PoliticalInterests = (props) => {
    const { isUpdate } = props;
    const { username } = useParams();
    const [ellipsis, setEllipsis] = useState(true);
    const [intrestArray, setIntrestArray] = useState([]);
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const [userCandidateExtensionBase, setUserCandidateExtensionBase] = useState([]);

    useEffect(() => {
        if (publicUser?.interests?.length > 0)
            setIntrestArray(publicUser?.interests)
        setUserCandidateExtensionBase(publicUser?.userCandidateExtension?.userCandidateExtensionBase)


    }, [publicUser])
    return (
        <>

            {/* <div className='mv-profile-cols mv-profile-cols-red'>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                        <Card>
                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeOneContent?.contentId} alt="" />
                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeOneTitle}</Title>
                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeOneDescription}</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                        <Card>
                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeTwoContent?.contentId} alt="" />
                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeTwoTitle}</Title>
                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeTwoDescription}</Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={24} xl={8}>
                        <Card>
                            <Image preview={false} src={IMAGE_URL + publicUser?.userCandidateExtension.candidateAttributeThreeContent?.contentId} alt="" />
                            <Title level={2}>{userCandidateExtensionBase?.candidateAttributeThreeTitle}</Title>
                            <Paragraph>{userCandidateExtensionBase?.candidateAttributeThreeDescription}</Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div> */}
            {intrestArray.length > 0 &&
                <Card>
                    <div className='mv-view'>
                        <Title className='mv-interest-head' level={2}>Political Interests</Title>
                        {
                            intrestArray.map((value, index) => {
                                return (
                                    <div className='mv-view mv-mt-20  mv-pr-10 mv-pl-10'>
                                        <Title level={2}>{value.interestName}</Title>
                                        <div className=' mv-mt-20  mv-jc-sb'>

                                            <Paragraph
                                                ellipsis={
                                                    ellipsis
                                                        ? {
                                                            rows: 4,
                                                            expandable: true,
                                                            symbol: 'READ MORE',
                                                        }
                                                        : false
                                                }
                                            >
                                                {value.viewPointStr}
                                            </Paragraph>
                                        </div>
                                        {
                                            (intrestArray.length > 1 && index + 1 != intrestArray.length) &&
                                            <div className="my-bb"> </div>
                                        }
                                    </div >


                                    // <div className='mv-view mv-mt-20'>
                                    //     <Title level={2}>{value.politicalInterests}</Title>
                                    //     <Card>
                                    //         <Paragraph
                                    //             ellipsis={
                                    //                 ellipsis
                                    //                     ? {
                                    //                         rows: 4,
                                    //                         expandable: true,
                                    //                         symbol: 'READ MORE',
                                    //                     }
                                    //                     : false
                                    //             }
                                    //         >
                                    //             {value.writeViews}
                                    //         </Paragraph>
                                    //         {(page == 'edit-candidate' || page == 'edit-user') &&
                                    //             <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                    //                 <div className='mv-icon-btns mv-d-flex'>
                                    //                     <Link to="#" onClick={() => editInterestValue(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                    //                         <EditIcon />
                                    //                     </Link>
                                    //                     <Link to="#" onClick={() => deleteInterest(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                    //                         <DeleteIcon />
                                    //                     </Link>
                                    //                 </div>
                                    //                 {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    //             </div>
                                    //         }
                                    //     </Card>
                                    // </div >
                                )
                            })


                        }
                    </div>
                </Card>
            }
            {/* {
            intrestArray.map((value, key) => {
                return (
                    <div className='mv-view mv-mt-20'>
                        <Title level={2}>{value.politicalInterests}</Title>
                        <Card>
                            <Paragraph
                                ellipsis={
                                    ellipsis
                                        ? {
                                            rows: 4,
                                            expandable: true,
                                            symbol: 'READ MORE',
                                        }
                                        : false
                                }
                            >

                                {value.writeViews}
                            </Paragraph>
                        </Card>
                    </div>
                )
            })
        } */}

        </>
    )
}

export default PoliticalInterests;
