import React from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import useToken from '../hooks/useToken';

const PrivateRoutes = () => {

    const { token, setToken, refreshToken } = useToken();
    // 'isExpired' will return a boolean
    // true => your token is expired
    // false => your token is not expired

    // if (!isExpired) {
    //     console.log("called")
    //    async reEvaluateToken(refreshToken); // decodedToken and isExpired will be update
    //     return
    // }
    return token ? <Outlet /> : <Navigate to="/signin" />
}

export default PrivateRoutes;