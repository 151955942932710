// Libraries
import { useState, useEffect } from 'react';
import { Card, Image, Typography } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon } from '../../assets/icons/icons';
import { NewProfileBannerData } from '../../data/NewProfileBannerData';

// Styles
import './style.css';
import { useShareDispatch, useShareSelector } from '../../shared';
import { IMAGE_URL } from '../../enviroment/Envirments';
import OpenSuccessNotification from '../../Notification/successNotif';
import OpenErrorNotification from '../../Notification/errorNotif';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';

// Assets

const { Title } = Typography;

const NewProfileBanner = () => {

    const { bannerImg, name, location, designation, userName, incumbent, party, button } = NewProfileBannerData;

    const [bannerImage, setBannerImage] = useState(bannerImg);

    function handleBannerImage(e) {
        console.log(e.target.files);
        setBannerImage(URL.createObjectURL(e.target.files[0]));
    }
    const dispatcher = useShareDispatch();
    const { token,} = useToken();


    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    let user = publicUser?.userBase
    let userExtension= publicUser?.userCandidateExtension?.userCandidateExtensionBase
    // useEffect(() => {
    //     if (publicUser?.userCandidateExtension?.speeches?.length > 0)
    //         setSpeechArray(publicUser?.userCandidateExtension?.speeches)
    // }, [publicUser])

    const onFollow = (userId) => {
        interceptor.axiosPost("Post", "/connections", { userIdForConnection: userId }, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicUser({ userId: userId, token: token }))
                OpenSuccessNotification("topRight", "Request Successfully Sent")
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {

            OpenSuccessNotification("topRight", "Request Successfully Sent")

            console.log("error?.response?.data?.error", error?.response);
        })
    }

    return (
        <div className='mv-candidate-banner'>
            <Card>
                <div className='mv-candidate-banner-image'>
                    {/* <label className="mv-uplaod-img mv-uplaod-img-30">
                        <input type="file" accept="image/*" onChange={handleBannerImage} />
                        <EditIcon />
                    </label> */}
                    <Image placeholder={true} preview={false} src={IMAGE_URL + publicUser?.userBannerPicture?.contentId} />
                </div>
                <div className='mv-candidate-content'>
                    <div className='mv-candidate-content-left'>
                        <div className='mv-candidate-name'>{user?.firstName} <span className='mv-candidate-username'>/ @{user?.userName}</span></div>
                        <div className='mv-candidate-location'>{userExtension?.currentPoliticalPosition}</div>
                        <div className='mv-candidate-designation'>{userExtension?.position}</div>
                    </div>
                    <div className='mv-candidate-content-right'>
                        <ul className='mv-candidate-list'>
                            {/* <li>{incumbent} / {user?.party}</li> */}
                            <li> {user?.party}</li>
                        </ul>
                        <div className='mv-candidate-btn'>
                        <a onClick={() => onFollow(user?.userBase?.userId)} className='ant-btn ant-btn-primary ant-btn-44'>Follow</a>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default NewProfileBanner;
