// live base url
// export const BASE_URL = "https://api.wechipn.com";
// export const API_BASE_URL = `${BASE_URL}/v1/`;

//test base url
 export const BASE_URL = "https://api.myvotepolitics.com";
//export const BASE_URL = "http://35.171.6.189:8080";
export const API_BASE_URL = `${BASE_URL}/v1`;

//image url
// ("https://api.wechipn-dev.com/v1/images/398b6109-01fe-468a-b106-b1c8d80c2770");
export const IMAGE_URL = `${API_BASE_URL}/images/`;
export const VIDEO_URL = `${API_BASE_URL}/videos/`;

// CLient Id live
// export const CLIENT_ID = "af8d1bf1-7e8d-4a69-b876-b2629491962c";

//Cliend Id test
export const CLIENT_ID = "407743fa-3abd-45b4-ab9f-9d5097a8d0d7";
