import axios from "axios";
import OpenErrorNotification from "../../Notification/errorNotif";
import interceptor from "../../shared/interceptor";

// const createUser = (body, token) => {
//   return new Promise((resolve, reject) => {
//     interceptor.axiosPost("POST", "/users", body, token).then(res => {
//       resolve(res?.data);
//     }).catch(error => {
//       reject(err?.response?.data?.error_data);
//     })
//   });
// };


const loginUser = (userId, token, data) => {
    return new Promise((resolve, reject) => {
        interceptor.axiosGet("Get", "/users/" + userId, token, data).then(res => {
            resolve(res?.data);
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            OpenErrorNotification("topRight", error?.response.data.messageStr)
            reject(error?.response);
        })

    });
};
// const codeVerification = (userId, code, token) => {
//   return new Promise((resolve, reject) => {
//     const body = {
//       id: userId,
//       verification_code: code,
//     };
//     axios({
//       url: `${API_BASE_URL}users/${userId}/verify`,
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         Authorization: `Bearer ${token}`,
//       },
//       data: new URLSearchParams(body),
//     })
//       .then((res) => {
//         console.log("res", res);
//         resolve(res?.data);
//       })
//       .catch((err) => {
//         reject(err?.response?.data?.error_data);
//       });
//   });
// };
export { loginUser };
