// Libraries
import { Typography, Card, Avatar, List } from 'antd';

// Component
import { PoliticalIssuesData } from '../../data/PoliticalIssuesData';

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const PoliticalIssues = () => {
    return (
        <div className='mv-political-issues mv-mt-20'>
            <Title level={2}>Political Interests</Title>
            <Card>
                <List
                    itemLayout="horizontal"
                    dataSource={PoliticalIssuesData}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src={item.img} />}
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}

export default PoliticalIssues;
