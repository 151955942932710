// Libraries
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Component
import LandingPage from "./pages/landing";
import { SignUp, SignIn, ForgotPassword } from "./pages/auth";
import CandidatePage from "./pages/candidate";
import EditCandidate from "./pages/edit-candidate";
import ViewCandidate from "./pages/view-candidate";
import EditUser from "./pages/edit-user";
import ViewUser from "./pages/view-user";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoutes from "./routes/PrivateRoutes";
import ShareRoute from "./routes/shareRoute";
import Settings from "./pages/settings";
import UpdatePassword from "./pages/UpdatePassword";
import Subscriptions from "./pages/Subscriptions";
import { loadStripe } from '@stripe/stripe-js';

// Styles
import './App.less';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./pages/checkOut";
import CompletionPage from "./pages/completion";
const stripePromise = loadStripe('pk_live_51Mmok4B2rev7mMXPPp891RIAyMdsblT0WP1DckCgPq39QH7WO5lOd1HBWFiGrWfhQ3DTdJOj3Te995K1bhkweyQe00PT40h3qv');

// Assets

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/" element={<ShareRoute />}>
            <Route path="/" element={<LandingPage />} />
          </Route>
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/home" element={<CandidatePage />} />
            <Route path="/edit-candidate/:userId/:edit" element={<EditCandidate />} />
            <Route path="/view-candidate/:userId/:type/:username" element={<ViewCandidate />} />
            <Route path="/edit-user/:userId/:edit" element={<EditUser />} />
            <Route path="/view-user/:userId/:type/:username" element={<ViewUser />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/completion" element={<CompletionPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
