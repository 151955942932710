// Libraries
import { Button, Card, Image, Input, Progress, Upload } from 'antd';
import { Link } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import userImg from '../../assets/images/user.png';
import { VideoIcon, ImageIcon } from '../../assets/icons/icons';
import { useEffect, useState } from 'react';
import { ProfileImageMap } from '../../data/ProfileImageMap';
import { useShareDispatch, useShareSelector } from '../../shared';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import { IMAGE_URL } from '../../enviroment/Envirments';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import ReactPlayer from 'react-player';
import { getBase64, upladForPostBase64, VideoUpladForPostBase64, videoUpladForPostBase64 } from '../../shared/asyncstorage/imgaeUpload';
import OpenSuccessNotification from '../../Notification/successNotif';


const SendPost = () => {
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { token } = useToken();
    const [isPosting, setIsPosting] = useState(false)
    const [url, setUrl] = useState()
    const dispatcher = useShareDispatch();
    const [fileList, setFileList] = useState()
    const [file, setFile] = useState()
    const [progress, setProgress] = useState(false)
    const [ProgressBar, setProgressBar] = useState()


    const sendPost = () => {
        let post = {
            "postText": document.getElementById("editMe").value,
        }

        if (post.postText || url || fileList) {
            setIsPosting(true)
            interceptor.axiosPost("Post", "/posts", post, token).then(res => {
                if (res.status = 200) {
                    let text = document.getElementById("editMe")
                    if (url) {
                        setProgress(true)
                        VideoUpladForPostBase64(file, "video/mp4", res.data.postId, "post_video", token, data => {
                            if (data.complete) {
                                dispatcher(actionsApi.getPublicPost(token))
                                OpenSuccessNotification("topRight", "Successfully upload")

                                setIsPosting(false)
                                text.value = ""
                                setUrl(null)
                                setFileList([])
                                setFile(null)
                                setProgress(false)
                            }
                            else {
                                setProgressBar(data.percentLoaded)
                            }

                        })
                    }
                    else if (fileList) {
                        var base64 = fileList.replace(/^data:image\/\w+;base64,/, "");
                        upladForPostBase64(base64, "image/png", res.data.postId, "post_photo", token, data => {
                            dispatcher(actionsApi.getPublicPost(token))
                            OpenSuccessNotification("topRight", "Successfully upload")
                            setIsPosting(false)
                            text.value = ""
                            setUrl(null)
                            setFileList([])
                            setFile(null)
                        })

                    }
                    else {
                        OpenSuccessNotification("topRight", "Successfully Post")
                        dispatcher(actionsApi.getPublicPost(token))
                        text.value = ""
                        setIsPosting(false)
                        setUrl(null)
                        setFileList([])
                        setFile(null)
                    }

                }
            }).catch(error => {
                console.log("error?.response?.data?.error", error?.response);
                setIsPosting(false)
            })
        }
    }

    const sendPostContent = (item) => {


    }

    const onChange = async (file) => {
        setFileList(file.fileList)
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <div className='mv-send-post'>
                <Card>
                    <div className='mv-send-post-card mv-d-flex'>
                        <Image src={loginUserObject?.userProfilePicture?.contentId ? IMAGE_URL + loginUserObject?.userProfilePicture?.contentId : userImg} alt="" preview={false} className="mv-img-35" />
                        <div className='mv-editable-div'>
                            <div className='mv-post-textarea mv-d-flex'>
                                <Input className='mv-post-input' id="editMe" placeholder={"What's on your mind, " + loginUserObject?.userBase?.firstName}></Input>
                                {/* <div className='mv-send-post-meta'>
                                <ImageIcon />
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='mv-post-btns mv-d-flex sendPost'>
                        <div className='mv-post-btn'>
                            <Link to="#" className='mv-d-flex mv-btn-44 mv-btn-red'>
                                <VideoIcon />
                                <span>Live Video</span>
                            </Link>
                        </div>
                        <div className='mv-post-btn'>
                            <Upload
                                maxCount={1}
                                listType='none'

                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={(file) => {
                                    if (file.type == "video/mp4") {
                                        setUrl(null)
                                        setFileList([])
                                        setFile(file)
                                        return new Promise((resolve) => {
                                            const reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onload = () => {
                                                const img = document.createElement('img');
                                                img.src = reader.result
                                                let urls = img.src
                                                
                                                setUrl(urls)

                                            };
                                        });
                                    }
                                    else {
                                        setUrl(null)
                                        setFileList([])
                                        getBase64(file, async (url) => {
                                            let fileLists = await url
                                            setFileList(fileLists)
                                        })
                                    }

                                    return false

                                }}
                            >
                                <Button icon={<ImageIcon />}>Photo/Video</Button>
                            </Upload>
                            {/* <Link to="#" className='mv-d-flex mv-btn-44'>
                            <ImageIcon />
                            <span>Photo/Video</span>
                        </Link> */}
                        </div>
                        <div className='mv-post-btn'>
                            <Button onClick={sendPost} loading={isPosting} className='mv-d-flex mv-btn-44 mv-btn-blue'>
                                <span>Post</span>
                            </Button>
                        </div>
                    </div>

                    {progress && <Progress percent={ProgressBar} />}
                    {url &&
                        < video width="250" controls style={{ marginTop: "10px" }}>
                            <source
                                src={url}
                            />
                            Your browser does not support HTML5 video.
                        </video>
                    }

                    {
                        fileList &&
                        <Image style={{ marginTop: "10px" }} width={150}
                            src={fileList}
                        />

                    }
                </Card>

            </div>
            {/* < video width="400" controls>
                <source
                    src="https://api.myvotepolitics.com/v1/videos/88f65d63-f452-41b3-8472-85866ecdf7d1"
                />
                Your browser does not support HTML5 video.
            </video> */}

        </>
    )
}

export default SendPost;
