// Libraries
import { useState, useEffect } from 'react';
import { Card, Image, Upload } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';

// Component
import { EditProfileBannerData } from '../../data/EditProfileBannerData';

// Styles
import './style.css';

// Assets
import { EditIcon } from '../../assets/icons/icons';
import { BannerImageMap, ProfileImageMap } from '../../data/ProfileImageMap';
import { CandidiateArray } from '../../data/CandidateDataJson';
import { useShareDispatch, useShareSelector } from '../../shared';
import uuidv4 from '../../shared/uuidgenerator';
import interceptor from '../../shared/interceptor';
import useToken from '../../hooks/useToken';
import { IMAGE_URL } from '../../enviroment/Envirments';
import { imageUpladBase64 } from '../../shared/asyncstorage/imgaeUpload';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../Notification/successNotif';
import OpenErrorNotification from '../../Notification/errorNotif';
const ProfileBanner = (props) => {
    let param = useParams();

    const [user, setUser] = useState();
    // const [userNameCurrent, setUserName] = useState("")
    // const [partyName, setPartyName] = useState("")
    // const [email, setEmail] = useState("")
    const location = useLocation();
    // const [userType, setType] = useState("")
    const { bannerImg, userImg, } = EditProfileBannerData;
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const [bannerImage, setBannerImage] = useState();
    const { token, getUserID } = useToken();
    const dispatcher = useShareDispatch();

    function handleBannerImage(e) {
        setBannerImage(URL.createObjectURL(e.target.files[0]));
        imageUpladBase64(e.target.files[0], "candidate_banner_photo", token, (res) => {
            let model = {
                userId: getUserID(),
                token: token
            }
            dispatcher(actionsApi.getUser(model))
        })

    }
    const [uploadedImageObj, setUploadedImageObj] = useState({
        "correlation_id": null,
        'order': '1',
        'encoded_data': null,
    })
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: user?.userProfilePicture?.contentId ? IMAGE_URL + user?.userProfilePicture?.contentId : userImg,
        },
    ]);

    // const onChange = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    // };


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        const file = newFileList[0].originFileObj
        imageUpladBase64(file, "user_profile_photo", token, (res) => {
            let model = {
                userId: getUserID(),
                token: token
            }
            dispatcher(actionsApi.getUser(model))
        })
    }
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onFollow = (userId) => {
        interceptor.axiosPost("Post", "/connections", { userIdForConnection: userId }, token).then(res => {
            if (res.status == 200) {
                dispatcher(actionsApi.getPublicUser({ userId: userId, token: token }))
                OpenSuccessNotification("topRight", "Request Successfully Sent")
            } else {
                OpenErrorNotification("topRight", res.detailMessageStr)
            }

        }).catch(error => {

            OpenSuccessNotification("topRight", "Request Successfully Sent")

            console.log("error?.response?.data?.error", error?.response);
        })
    }

    useEffect(() => {
        console.log(location)
        if (param.edit == "edit") {
            setUser(loginUserObject)
        } else {
            setUser(publicUser)
        }

    }, [loginUserObject, publicUser,location]);

    useEffect(() => {
        
        if (user) {
            setBannerImage(user?.userBannerPicture?.contentId ? IMAGE_URL + user?.userBannerPicture?.contentId : userImg)
            if (user?.userBase) {
                // setPartyName(items.politicalAffiliation?.politicalAffiliation)
                setFileList([{
                    uid: '-1',
                    name: 'image.png',
                    percent: 50,
                    status: 'done',
                    url: user?.userProfilePicture?.contentId ? IMAGE_URL + user?.userProfilePicture?.contentId : userImg
                }])
            }

        }
    }, [user]);

    return (
        <div className='mv-profile-banner'>
            <Card>
                <div className='mv-banner-image'>
                    {props?.public == 'false' && <label className="mv-uplaod-img mv-uplaod-img-30">
                        <input type="file" accept="image/*" onChange={handleBannerImage} />
                        <EditIcon />
                    </label>}
                    <Image placeholder={true} src={bannerImage} className="iframe-loading" />
                </div>
                <div className='mv-profile mv-d-flex '>
                    <ImgCrop rotate>
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            maxCount={1}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {props?.public == 'false' && <EditIcon />}
                        </Upload>

                    </ImgCrop>
                    <div className='mv-profile-meta mv-d-flex'>
                        <div className='mv-profile-meta-content'>
                            <div className='mv-profile-name'>{user?.userBase?.userName}</div>
                            <div className='mv-profile-username'>{user?.userBase?.email}</div>
                            {user?.userBase?.userType == "CANDIDATE" && <ul className='mv-profile-list mv-d-flex'>
                                {/* <li>{user}</li> */}
                                <li>{user?.userBase?.party}</li>
                            </ul>}
                        </div>
                        {param.edit != "edit" ?
                            <div className='mv-profile-end'>
                                <a onClick={() => onFollow(user?.userBase?.userId)} className='ant-btn ant-btn-primary ant-btn-44'>Follow</a>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default ProfileBanner;
