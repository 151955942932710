// Libraries
import { Card, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import linkImageOne from '../../../../assets/images/auth-slide-01.jpg';
import linkImageTwo from '../../../../assets/images/auth-slide-02.jpg';
import { useEffect, useState } from 'react';
import { getLinksAsync, getLinksLS } from '../../../../shared/asyncstorage/links';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';

const { Title } = Typography;
const { Meta } = Card;

const ImportantLinks = () => {
    const { username } = useParams();
    const [linksArray, setlinksArray] = useState([]);
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)


    useEffect(() => {
        if (publicUser?.userCandidateExtension?.importantLinks?.length > 0)
            setlinksArray(publicUser?.userCandidateExtension?.importantLinks)
    }, [publicUser])


    // useEffect(() => {
    //     // let findUser = CandidiateArray.find(x => x.name === username)
    //     //  if (!findUser) {
    //     let checkLS = getLinksLS(username)
    //     if (checkLS && checkLS.length > 0) {
    //         setlinksArray(checkLS)
    //     }
    //     else {
    //         getLinksAsync().then(res => {
    //             setlinksArray(res)
    //         })
    //     }
    //     // } else {
    //     //     setlinksArray(findUser.links)
    //     // }
    // }, [])
    return (
        <>
            {
                linksArray.length > 0 &&
                linksArray.map((value, key) => {
                    return (
                        <>
                            <div className='mv-view mv-mt-20'>
                                <Title level={2}>{value.linkTitle}</Title>
                                {/* <Card className='mv-link-view mv-d-flex' cover={<Link to="#"><img alt="example" src={linkImageOne} /></Link>}> */}
                                <Card className='mv-link-view mv-d-flex' cover={<a href={value.linkUrl} target="_blank">
                                    <Meta title={value.linkUrl} />
                                </a>}>
                                </Card>
                            </div>

                        </>
                    )
                })
            }

        </>
    )
}

export default ImportantLinks;
