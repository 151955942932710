// Libraries
import { Card, Menu } from 'antd';

// Component

// Styles
import './style.css';

// Assets
import { HomeIcon, RssIcon, ContentIcon, MessageIcon, UsersIcon, UserIcon, SettingIcon } from '../../assets/icons/icons';
import { Link, useParams } from 'react-router-dom';
import { useShareSelector } from '../../shared';
function getItem(label, key, icon, children,) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const SideNav = () => {
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    let param = useParams();
    let user = loginUserObject?.userBase
    const items = [
        getItem((<Link to={`/home`}>Home  </Link>), '1', <HomeIcon />),
        getItem('RSS News', '2', <RssIcon />),
        getItem('Your Content', '3', <ContentIcon />),
        getItem('Messages', '4', <MessageIcon />),
        getItem('Connections', '5', <UsersIcon />),
        getItem((
            <Link to={user?.userType == "CANDIDATE" ? `/edit-candidate/${user?.userId}/edit` : `/edit-user/${user?.userId}/edit`}>Profile</Link>
        ), '6', <UserIcon />),
        getItem((<Link to={`/settings`}>Settings  </Link>), '7', <SettingIcon />),
    ];
    return (
        <div className='mv-sidenav'>
            <Card>
                <Menu
                    defaultSelectedKeys={['1']}
                    items={items}
                />
            </Card>
        </div>
    )
}

export default SideNav;
