// Libraries
import { Typography, Card, Image } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../enviroment/Envirments';
import useToken from '../../hooks/useToken';
import OpenSuccessNotification from '../../Notification/successNotif';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';

// Component
// import { RequestsData } from '../../data/RequestsData';
import { useShareDispatch, useShareSelector } from '../../shared';
import interceptor from '../../shared/interceptor';

// Styles
import './style.css';

// Assets
import userImg from '../../assets/images/user.png'

const { Title, Paragraph } = Typography;

const Requests = props => {

    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    let RequestsData = loginUserObject?.requestedConnections ? loginUserObject?.requestedConnections : []
    const [loading, setLoading] = useState(false);
    const { token } = useToken();
    const dispatcher = useShareDispatch();

    const onFollowBack = (item) => {
        setLoading(true)
        interceptor.axiosPut("Put", "/connections/" + item.connectionBase.connectionId + "/accept-connection", null, token).then(res => {
            if (res.status = 200) {
                
                dispatcher(actionsApi.updateFollowRequest(res.data))
                dispatcher(actionsApi.updateConnections(res.data))
                OpenSuccessNotification("topRight", "Connection Add Successfull")
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setLoading(false)

        })
    }

    const onFollowBlock = (item) => {
        setLoading(true)
        interceptor.axiosDelete("Delete", "/connections" + item.connectionBase.connectionId, null, token).then(res => {
            if (res.status = 200) {
                dispatcher(actionsApi.updateFollowRequest(res.data))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setLoading(false)

        })
    }

    useEffect(() => {


    }, []);

    return (
        <div className='mv-requests'>
            <div className='mv-text-header mv-d-flex'>
                <Title level={2}>{props.title}</Title>
                <div className='mv-count'>{RequestsData.length}</div>
            </div>
            {RequestsData.length > 0 ? RequestsData.map((item, index) => (
                <>
                    <Card key={index}>
                        {/* {item.connectedUserTwoProfilePicture.connectedUserOne != loginUserObject.userBase?.userId ? */}
                        <>
                            <div className='mv-user-content mv-d-flex'>
                                <Image className="mv-img-35" src={item.connectedUserTwoProfilePicture?.contentId ? IMAGE_URL + item.connectedUserTwoProfilePicture?.contentId : userImg} alt="" preview={false} />
                                <div className='mv-user-meta'>
                                    <Paragraph><span>{item.userTwoUserName} </span>follows you</Paragraph>
                                </div>
                            </div>
                            <div className='mv-btn-group mv-d-flex'>
                                <div className='mv-btn'>
                                    <a onClick={() => onFollowBack(item)} loading={loading} className='ant-btn ant-btn-primary ant-btn-40'>Follow Back</a>
                                </div>
                                <div className='mv-btn'>
                                    <a onClick={() => onFollowBlock(item)} className='ant-btn ant-btn-danger ant-btn-40'>Block</a>
                                </div>
                            </div>
                        </>
                        {/* :
                            <Paragraph>No Follows Requests</Paragraph>
                        } */}
                    </Card>
                </>

            )) : <Card >
                <div className='mv-user-content mv-d-flex'>
                    <Paragraph>No Follows Requests</Paragraph>
                </div>
            </Card>
            }

        </div>
    )
}

export default Requests;
