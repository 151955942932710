import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import interceptor from "../../../shared/interceptor";

export const registerUser = createAsyncThunk(
	"users",

	async (object, { dispatch, getState }) => {
		dispatch(gettingResponse())

		interceptor.axiosPost("POST", "/users", object.body, object.token).then(res => {
			if (res.status == 200) {
				dispatch(gettingResponseSuccess(res.data))
			}
			else {
				dispatch(gettingResponseSuccess({}))
			}
		}).catch(error => {
			dispatch(gettingResponseError(error))
		})
	});


export const getUser = createAsyncThunk(
	"users",

	async (object, { dispatch, getState }) => {
		dispatch(gettingResponse())
		interceptor.axiosGet("Get", "/users/" + object.userId, object.token).then(res => {
			if (res.status == 200) {
				dispatch(gettingResponseSuccess(res.data))

			}
			else {
				dispatch(gettingResponseSuccess({}))
			}
		}).catch(error => {
			dispatch(gettingResponseError(error))
		})
	});


const loginSlice = createSlice({
	name: "login_user",
	initialState: {
		loginUserObject: {
			userBase: null,
			requestedConnections: [],
			requestedConnections: [],
			connections: [],
			interests: [],
			messages: [],
			userCandidateExtension: {
				quotes: [],
				speeches: [],
				interviews: [],
				importantLinks: [],
				politicalInterests: [],
				introductoryVideoLink: {
					linkId: "",
					userId: "",
					linkTitle: "",
					linkUrl: "",
					linkType: "",
					creationTimestamp: 0
				},
				userCandidateExtensionBase: null
			},
			userProfilePicture: null,
			userBannerPicture: null,
		},
		follows: [],
		connections: [],
		status: "idle",
		isLoadingLoginUser: false,
		error: null,

	},
	reducers: {
		gettingResponseError: (state, action) => {
			state.status = "error";
			state.loginUserObject = null;
			state.error = action.payload;
			state.isLoadingLoginUser = false;
		},
		gettingResponseSuccess: (state, action) => {

			state.status = "success";
			state.loginUserObject = action.payload;
			state.isLoadingLoginUser = false;
		},
		gettingResponse: (state, action) => {
			state.status = "loading";
			state.loginUserObject = [];
			state.isLoadingLoginUser = true;
		},

		logOutUser: (state, action) => {
			state.loginUserObject = null;
		},

		UpdateQuotes: (state, action) => {
			state.loginUserObject.userCandidateExtension.quotes = [...state.loginUserObject.userCandidateExtension.quotes, action.payload];
		},
		UpdateSpeeech: (state, action) => {
			state.loginUserObject.userCandidateExtension.speeches = [...state.loginUserObject.userCandidateExtension.speeches, action.payload];
		},
		UpdateInterviews: (state, action) => {
			state.loginUserObject.userCandidateExtension.interviews = [...state.loginUserObject.userCandidateExtension.interviews, action.payload];
		},
		UpdateImportantLinks: (state, action) => {
			state.loginUserObject.userCandidateExtension.importantLinks = [...state.loginUserObject.userCandidateExtension.importantLinks, action.payload];
		},
		UpdatePoliticalInterests: (state, action) => {
			state.loginUserObject.userCandidateExtension.politicalInterests = [...state.loginUserObject.userCandidateExtension.politicalInterests, action.payload];
			state.loginUserObject.interests = [...state.loginUserObject.interests, action.payload]
		},

		DeletePoliticalInterests: (state, action) => {
			state.loginUserObject.userCandidateExtension.politicalInterests = state.loginUserObject.userCandidateExtension.politicalInterests.filter(x => x.interestId != action.payload.interestId);
			state.loginUserObject.interests = state.loginUserObject.userCandidateExtension.politicalInterests.filter(x => x.interestId != action.payload.interestId);
		},

		deleteInterview: (state, action) => {
			state.loginUserObject.userCandidateExtension.interviews = state.loginUserObject.userCandidateExtension.interviews.filter(x => x.linkId != action.payload.linkId);
		},
		deleteQuotes: (state, action) => {
			state.loginUserObject.userCandidateExtension.quotes = state.loginUserObject.userCandidateExtension.quotes.filter(x => x.quoteId != action.payload.quoteId);
		},
		deleteSpeech: (state, action) => {
			state.loginUserObject.userCandidateExtension.speeches = state.loginUserObject.userCandidateExtension.speeches.filter(x => x.linkId != action.payload.linkId);
		},
		deleteLinks: (state, action) => {
			state.loginUserObject.userCandidateExtension.importantLinks = state.loginUserObject.userCandidateExtension.importantLinks.filter(x => x.linkId != action.payload.linkId);
		},

		updateFollowRequest: (state, action) => {
			state.loginUserObject.requestedConnections = state.loginUserObject.requestedConnections.filter(x => x.connectionBase.connectionId != action.payload.connectionId)
		},


		updateConnections: (state, action) => {
			state.loginUserObject.connections = [action.payload, ...state.loginUserObject.connections];
		},

	},

});


// Action creators are generated for each case reducer function
export const { gettingResponse, gettingLoginUser, gettingResponseError, setFitnessDirectory, gettingResponseSuccess, logOutUser,
	UpdateQuotes, UpdateSpeeech, UpdateInterviews, UpdateImportantLinks, UpdatePoliticalInterests, DeletePoliticalInterests,
	deleteQuotes, deleteInterview, deleteSpeech, deleteLinks,
	setConnection, updateConnections, updateFollowRequest, setFollowRequest

} = loginSlice.actions;

export default loginSlice.reducer;
