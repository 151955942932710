// Libraries
import { Card, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CandidiateArray } from '../../../../data/CandidateDataJson';
import { useShareSelector } from '../../../../shared';
import { getQuotesAsync, getQuotesLS } from '../../../../shared/asyncstorage/quotes';

// Component

// Styles
import './style.css';

// Assets

const { Title, Paragraph } = Typography;

const Quotes = (quotes) => {
    const { username } = useParams();
    const [quotesArray, setQuotesArray] = useState([]);
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)

    useEffect(() => {
        if (publicUser) {
            setQuotesArray(publicUser.userCandidateExtension?.quotes)
        }
        // if (quotes) {
        //     //setQuotesArray()
        // }
        // let checkLS = getQuotesLS(username)
        // if (checkLS && checkLS.length > 0) {
        //     setQuotesArray(checkLS)
        // }
        // else {
        //     getQuotesAsync().then(res => {
        //         setQuotesArray(res)
        //     })
        // }
    }, [publicUser])
    return (
        <>
            {
                quotesArray.length > 0 &&
                quotesArray.map((value, key) => {
                    return (
                        <div className='mv-view mv-mt-20'>
                            <Title level={2}>{value.title}</Title>
                            <Card>
                                <Paragraph>
                                    {value.quoteText}
                                </Paragraph>
                                <div className='mv-quote-by'>{value.by}</div>
                            </Card>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Quotes;
