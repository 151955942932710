// Libraries
import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';

// Components
import {
    AppLayout,
    ProfileBanner,
    SendPost,
    Post,
    NewProfileBanner,
} from '../../components';
import {
    PoliticalInterests,
} from './components';
import { CandidateViewPostArray, ImagePost, ImagePostArray } from '../../data/PostData';
import userImg from '../../assets/images/user.png'

// Styles
import './style.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CandidiateArray } from '../../data/CandidateDataJson';
import useToken from '../../hooks/useToken';
import interceptor from '../../shared/interceptor';
import { gettingPublicResponseError, gettingPublicResponseSuccess } from '../../redux-toolkit/actions/user/PublicUser';
import { useShareDispatch, useShareSelector } from '../../shared';
import { IMAGE_URL } from '../../enviroment/Envirments';

// Assets

const { Sider, Footer } = Layout;
const { TabPane } = Tabs;

const ViewUser = () => {
    const [user, setuser] = useState(JSON.parse(localStorage.getItem('@user')));
    const [postArray, setPostArray] = useState();
    const { userId, type } = useParams();
    const { token } = useToken()
    const navigate = useNavigate();
    const location = useLocation();

    const dispatcher = useShareDispatch();
    const { publicUser } = useShareSelector(state => state.PublicUserDetails)
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)

    useEffect(() => {
        interceptor.axiosGet("Get", "/posts/" + userId, token).then(res => {
            if (res.status == 200) {
                setPostArray(res.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }, [location])

    useEffect(() => {
        if (!publicUser.userBase) {
            interceptor.axiosGet("Get", "/users/" + userId + "/public", token).then(res => {
                if (res.status == 200) {
                    dispatcher(gettingPublicResponseSuccess(res.data))
                    let user = res.data?.userBase
                    if (user?.userType == "CANDIDATE") {
                        navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                    } else {
                        navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                    }

                }
                else {
                    dispatcher(gettingPublicResponseSuccess({}))
                }
            }).catch(error => {
                dispatcher(gettingPublicResponseError(error))
            })
        }


    }, [publicUser])
    useEffect(() => {
        console.log(location?.pathname.split('/')[2])
        if (location && location?.pathname.split('/')[2] != publicUser?.userBase?.userId) {
            dispatcher(gettingPublicResponseSuccess({}))
            interceptor.axiosGet("Get", "/users/" + userId + "/public", token).then(res => {
                if (res.status == 200) {
                    dispatcher(gettingPublicResponseSuccess(res.data))
                    let user = res.data?.userBase
                    if (user?.userType == "CANDIDATE") {
                        navigate(`/view-candidate/${user?.userId}/${user?.userType}/${user?.email}`);
                    } else {
                        navigate(`/view-user/${user?.userId}/${user?.userType}/${user?.email}`);
                    }

                }
                else {
                    dispatcher(gettingPublicResponseSuccess({}))
                }
            }).catch(error => {
                dispatcher(gettingPublicResponseError(error))
            })
        }


    }, [location])


    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700'>
                <ProfileBanner public='true' />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Political Interests" key="1">
                        <PoliticalInterests />
                    </TabPane>
                    <TabPane tab="Posts" key="2">
                        <React.Fragment>
                            {/* <SendPost /> */}
                            {postArray?.length > 0 &&
                                postArray.map((item, key) => {
                                    return (
                                        <Post
                                            postId={item.postId}
                                            text={item?.postBase?.postStr}
                                            textOne={item.textOne}
                                            profileName={item.postUserName}
                                            profilePic={item.postUserProfilePicture.contentId ? IMAGE_URL + item.postUserProfilePicture.contentId : userImg}
                                            img={item.postContent?.contentTitle == "post_photo" ? item.postContent?.contentId : null}
                                            time={item.postBase.postTimestamp}
                                            video={item.postContent?.contentTitle == "post_video" ? item.postContent?.contentId : null}
                                            likes={item.postLikes}
                                            comments={item.postComments}
                                            postUserId={item.postBase.userId}
                                            user={loginUserObject?.userProfilePicture?.contentId}
                                            liked={item.likedByCurrentUser}
                                            followed={item.followedByCurrentUser}
                                        />
                                    )
                                })
                            }
                        </React.Fragment>
                    </TabPane>
                </Tabs>
            </main>
            {/* <Footer>© MyVote 2022. All rights reserved.</Footer> */}
        </AppLayout>
    )
}

export default ViewUser;