// Libraries
import { Layout, Tabs } from 'antd';

// Components
import {
    AppLayout,
    ProfileBanner,
} from '../../components';
import {
    Introduction,
    PoliticalInterests,
    Speeches,
    Interviews,
    Quotes,
    ImportantLinks
} from './components';

// Styles
import './style.css';

// Assets

const { Footer } = Layout;
const { TabPane } = Tabs;

const EditCandidate = () => {
    return (
        <AppLayout>
            <main className='mv-main-content mv-w-700'>
                <ProfileBanner public='false'/>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Introduction" key="1">
                        <Introduction />
                    </TabPane>
                    <TabPane tab="Political Interests" key="2">
                        <PoliticalInterests />
                    </TabPane>
                    <TabPane tab="Speeches" key="3">
                        <Speeches />
                    </TabPane>
                    <TabPane tab="Interviews" key="4">
                        <Interviews />
                    </TabPane>
                    <TabPane tab="Quotes" key="5">
                        <Quotes />
                    </TabPane>
                    <TabPane tab="Important Links" key="6">
                        <ImportantLinks />
                    </TabPane>
                </Tabs>
            </main>
            {/* <Footer>© MyVote 2022. All rights reserved.</Footer> */}
        </AppLayout>
    )
}

export default EditCandidate;
