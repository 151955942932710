import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import interceptor from "../../../shared/interceptor";

export const getPublicUser = createAsyncThunk(
	"public_user",

	async (object, { dispatch, getState }) => {
		dispatch(gettingPublicResponse())
		interceptor.axiosGet("Get", "/users/" + object.userId + "/public", object.token).then(res => {
			if (res.status == 200) {
				dispatch(gettingPublicResponseSuccess(res.data))
			}
			else {
				dispatch(gettingPublicResponseSuccess({}))
			}
		}).catch(error => {
			dispatch(gettingPublicResponseError(error))
		})
	});


export const getPublicPost = createAsyncThunk(
	"getPublicPost",

	async (object, { dispatch, getState }) => {
		dispatch(gettingPublicResponse())
		interceptor.axiosGet("Get", "/posts", object).then(res => {
			if (res.status == 200) {
				dispatch(setPublicPost(res.data))
			}
			else {
				dispatch(setPublicPost({}))
			}
		}).catch(error => {
			dispatch(setPublicPost(error))
		})
	});


const loginSlice = createSlice({
	name: "public_user",
	initialState: {
		publicUser: {
			userBase: null,
			requestedConnections: [],
			requestedConnections: [],
			connections: [],
			interests: [],
			messages: [],
			userCandidateExtension: {
				quotes: [],
				speeches: [],
				interviews: [],
				importantLinks: [],
				politicalInterests: [],
				introductoryVideoLink: {
					linkId: "",
					userId: "",
					linkTitle: "",
					linkUrl: "",
					linkType: "",
					creationTimestamp: 0
				},
				userCandidateExtensionBase: null
			},
			userProfilePicture: null,
			userBannerPicture: null,
		},
		publicPost: null,
		status: "idle",
		isLoadingLoginUser: false,
		error: null,

	},
	reducers: {
		gettingPublicResponseError: (state, action) => {
			state.status = "error";
			state.publicUser = null;
			state.error = action.payload;
			state.isLoadingLoginUser = false;
		},
		gettingPublicResponseSuccess: (state, action) => {

			state.status = "success";
			state.publicUser = action.payload;
			state.isLoadingLoginUser = false;
		},
		gettingPublicResponse: (state, action) => {
			state.status = "loading";
			state.publicUser = null;
			state.isLoadingLoginUser = true;
		},

		clearUser: (state, action) => {
			state.publicUser = action.payload;
		},

		setPublicPost: (state, action) => {
			state.publicPost = action.payload;
		},

		updatePublicPost: (state, action) => {
			state.publicPost = [action.payload, ...state.publicPost];
		},




	},

});


// Action creators are generated for each case reducer function
export const { gettingPublicResponse, gettingPublicResponseError, gettingPublicResponseSuccess, clearUser, setPublicPost, updatePublicPost,

} = loginSlice.actions;

export default loginSlice.reducer;
