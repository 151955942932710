
// import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import store from '../redux-toolkit/store';


const useShareDispatch = () => useDispatch();
const useShareSelector = useSelector;

export {
    useShareDispatch,
    useShareSelector,
    store

}
