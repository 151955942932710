// Libraries
import { Typography } from 'antd';

// Component

// Styles
import './style.css';

// Assets

const { Title, Paragraph } = Typography;

const Stats = () => {
    return (
        <div className='mv-stats'>
            <div className='mv-container'>
                <div className='mv-stats-content mv-d-flex'>
                    <div className='mv-stats-left'>
                        <Title level={2}>160M</Title>
                        <Paragraph level={2}>Active Voters</Paragraph>
                    </div>
                    <div className='mv-stats-right'>
                        <Title level={2}>500K</Title>
                        <Paragraph level={2}>Elected Offices</Paragraph>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;
