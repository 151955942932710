


import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Card, Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../enviroment/Envirments'

const CheckoutForm = ({ item }) => {
  console.log(item)
  debugger
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState(item);
  const navigate = useNavigate();


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    navigate('/home')
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setIsModalOpen(true);
          setErrorMessage("Payment succeeded!");
          break;
        case "processing":
          setErrorMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setErrorMessage("Your payment was not successful, please try again.");
          break;
        default:
          setErrorMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  const handleSubmit = async (event) => {
    setProcessing(true)
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/home`,
      },
      redirect: 'if_required'
    });
    setProcessing(false)
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      setErrorMessage(result.error.message);
    } else {
      setIsModalOpen(true);
      setErrorMessage(null);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='ant-btn ant-btn-primary mv-mt-20' disabled={!stripe} id="submit">
        <span> {processing ? 'Processing ...' : 'Pay Now'}</span>
      </button>
      {errorMessage && <div className='errorMessage'>{errorMessage}</div>}
      <>
        <Modal
          // title="Add/Edit Important Link"
          open={isModalOpen}
          onOk={handleOk}
          footer={null}
          onCancel={handleCancel}>
          <Card>
            <div className='mv-d-flex mv-jc-center'>
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            </div>
            <h1 className='mv-mt-20 mv-mb-0'>Thank You</h1>
            <p style={{ fontSize: 16 }}>Your have successfully Subscribe <strong> {item.productTitle}</strong> for  <strong> {item.priceDescription}</strong>.</p>
            {/* <h2 className='mt-5'>
                  <span className='py-5 d-inline-block'>{title}</span>
                </h2> */}
            {/* <p className=''>{eng_lang.THANK_YOU.RECEIVE_EMAIL}</p> */}
          </Card>
        </Modal>
      </>
    </form>
  )
};

export default CheckoutForm;