export const SearchIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.66683 14C11.1646 14 14.0002 11.1645 14.0002 7.66666C14.0002 4.16886 11.1646 1.33333 7.66683 1.33333C4.16903 1.33333 1.3335 4.16886 1.3335 7.66666C1.3335 11.1645 4.16903 14 7.66683 14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6668 14.6667L13.3335 13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const MoonIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.52232 9.315C1.79232 13.1775 5.06982 16.32 8.99232 16.4925C11.7598 16.6125 14.2348 15.3225 15.7198 13.29C16.3348 12.4575 16.0048 11.9025 14.9773 12.09C14.4748 12.18 13.9573 12.2175 13.4173 12.195C9.74982 12.045 6.74982 8.9775 6.73482 5.355C6.72732 4.38 6.92982 3.4575 7.29732 2.6175C7.70232 1.6875 7.21482 1.245 6.27732 1.6425C3.30732 2.895 1.27482 5.8875 1.52232 9.315Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export const TrashIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> 
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>
    )
}

export const BellIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.01494 2.1825C6.53244 2.1825 4.51494 4.19999 4.51494 6.68249V8.84999C4.51494 9.30749 4.31994 10.005 4.08744 10.395L3.22494 11.8275C2.69244 12.7125 3.05994 13.695 4.03494 14.025C7.26744 15.105 10.7549 15.105 13.9874 14.025C14.8949 13.725 15.2924 12.6525 14.7974 11.8275L13.9349 10.395C13.7099 10.005 13.5149 9.30749 13.5149 8.84999V6.68249C13.5149 4.20749 11.4899 2.1825 9.01494 2.1825Z" stroke="#012E6B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M10.4024 2.4C10.1699 2.3325 9.92994 2.28 9.68244 2.25C8.96244 2.16 8.27244 2.2125 7.62744 2.4C7.84494 1.845 8.38494 1.455 9.01494 1.455C9.64494 1.455 10.1849 1.845 10.4024 2.4Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.2651 14.295C11.2651 15.5325 10.2526 16.545 9.01514 16.545C8.40014 16.545 7.83014 16.29 7.42514 15.885C7.02014 15.48 6.76514 14.91 6.76514 14.295" strokeWidth="1.5" strokeMiterlimit="10" />
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 2.25L2.25 15.75" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.25 2.25L15.75 15.75" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const PrevArrowIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.38 3.95337L2.33334 8.00004L6.38 12.0467" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.6667 8H2.44669" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const NextArrowIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62012 3.95337L13.6668 8.00004L9.62012 12.0467" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.33325 8H13.5533" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const HomeIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 15V12.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.39172 2.35L2.61672 6.975C1.96672 7.49167 1.55006 8.58333 1.69172 9.4L2.80006 16.0333C3.00006 17.2167 4.13339 18.175 5.33339 18.175H14.6667C15.8584 18.175 17.0001 17.2083 17.2001 16.0333L18.3084 9.4C18.4417 8.58333 18.0251 7.49167 17.3834 6.975L11.6084 2.35833C10.7167 1.64167 9.27506 1.64167 8.39172 2.35Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const RssIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 18.3333H12.4998C16.6665 18.3333 18.3332 16.6667 18.3332 12.5V7.5C18.3332 3.33333 16.6665 1.66667 12.4998 1.66667H7.49984C3.33317 1.66667 1.6665 3.33333 1.6665 7.5V12.5C1.6665 16.6667 3.33317 18.3333 7.49984 18.3333Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.3332 8.33333H1.6665" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 8.33333V18.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ContentIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 5.83333V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V5.83333C2.5 3.33333 3.75 1.66666 6.66667 1.66666H13.3333C16.25 1.66666 17.5 3.33333 17.5 5.83333Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.0835 3.75V5.41667C12.0835 6.33333 12.8335 7.08333 13.7502 7.08333H15.4168" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.6665 10.8333H9.99984" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.6665 14.1667H13.3332" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const MessageIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3917 14.025L15.7167 16.6583C15.8 17.35 15.0584 17.8333 14.4667 17.475L10.975 15.4C10.5917 15.4 10.2167 15.375 9.85004 15.325C10.4667 14.6 10.8334 13.6833 10.8334 12.6917C10.8334 10.325 8.78337 8.40836 6.25004 8.40836C5.28337 8.40836 4.39171 8.68334 3.65004 9.16667C3.62504 8.95834 3.6167 8.75 3.6167 8.53333C3.6167 4.74166 6.90837 1.66667 10.975 1.66667C15.0417 1.66667 18.3334 4.74166 18.3334 8.53333C18.3334 10.7833 17.175 12.775 15.3917 14.025Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.8332 12.6917C10.8332 13.6833 10.4665 14.6 9.84984 15.325C9.02484 16.325 7.7165 16.9667 6.24984 16.9667L4.07484 18.2583C3.70817 18.4833 3.2415 18.175 3.2915 17.75L3.49983 16.1083C2.38317 15.3333 1.6665 14.0917 1.6665 12.6917C1.6665 11.225 2.44984 9.93333 3.64984 9.16667C4.39151 8.68333 5.28317 8.40836 6.24984 8.40836C8.78317 8.40836 10.8332 10.325 10.8332 12.6917Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const UsersIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.63314 9.05834C7.5498 9.05 7.4498 9.05 7.35814 9.05834C5.3748 8.99167 3.7998 7.36667 3.7998 5.36667C3.7998 3.32501 5.4498 1.66667 7.4998 1.66667C9.54147 1.66667 11.1998 3.32501 11.1998 5.36667C11.1915 7.36667 9.61647 8.99167 7.63314 9.05834Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.6752 3.33333C15.2919 3.33333 16.5919 4.64166 16.5919 6.25C16.5919 7.825 15.3419 9.10833 13.7836 9.16666C13.7169 9.15833 13.6419 9.15833 13.5669 9.16666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.4666 12.1333C1.44993 13.4833 1.44993 15.6833 3.4666 17.025C5.75827 18.5583 9.5166 18.5583 11.8083 17.025C13.8249 15.675 13.8249 13.475 11.8083 12.1333C9.52494 10.6083 5.7666 10.6083 3.4666 12.1333Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.2832 16.6667C15.8832 16.5417 16.4499 16.3 16.9165 15.9417C18.2165 14.9667 18.2165 13.3583 16.9165 12.3833C16.4582 12.0333 15.8999 11.8 15.3082 11.6667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const UserIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0002 10C12.3013 10 14.1668 8.13452 14.1668 5.83334C14.1668 3.53215 12.3013 1.66667 10.0002 1.66667C7.69898 1.66667 5.8335 3.53215 5.8335 5.83334C5.8335 8.13452 7.69898 10 10.0002 10Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const SettingIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.6665 10.7333V9.26666C1.6665 8.4 2.37484 7.68333 3.24984 7.68333C4.75817 7.68333 5.37484 6.61666 4.6165 5.30833C4.18317 4.55833 4.4415 3.58333 5.19984 3.15L6.6415 2.325C7.29984 1.93333 8.14984 2.16666 8.5415 2.825L8.63317 2.98333C9.38317 4.29166 10.6165 4.29166 11.3748 2.98333L11.4665 2.825C11.8582 2.16666 12.7082 1.93333 13.3665 2.325L14.8082 3.15C15.5665 3.58333 15.8248 4.55833 15.3915 5.30833C14.6332 6.61666 15.2498 7.68333 16.7582 7.68333C17.6248 7.68333 18.3415 8.39166 18.3415 9.26666V10.7333C18.3415 11.6 17.6332 12.3167 16.7582 12.3167C15.2498 12.3167 14.6332 13.3833 15.3915 14.6917C15.8248 15.45 15.5665 16.4167 14.8082 16.85L13.3665 17.675C12.7082 18.0667 11.8582 17.8333 11.4665 17.175L11.3748 17.0167C10.6248 15.7083 9.3915 15.7083 8.63317 17.0167L8.5415 17.175C8.14984 17.8333 7.29984 18.0667 6.6415 17.675L5.19984 16.85C4.4415 16.4167 4.18317 15.4417 4.6165 14.6917C5.37484 13.3833 4.75817 12.3167 3.24984 12.3167C2.37484 12.3167 1.6665 11.6 1.6665 10.7333Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ImageIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.24984 12.8334H8.74984C11.6665 12.8334 12.8332 11.6667 12.8332 8.75002V5.25002C12.8332 2.33335 11.6665 1.16669 8.74984 1.16669H5.24984C2.33317 1.16669 1.1665 2.33335 1.1665 5.25002V8.75002C1.1665 11.6667 2.33317 12.8334 5.24984 12.8334Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.25016 5.83333C5.89449 5.83333 6.41683 5.311 6.41683 4.66667C6.41683 4.02233 5.89449 3.5 5.25016 3.5C4.60583 3.5 4.0835 4.02233 4.0835 4.66667C4.0835 5.311 4.60583 5.83333 5.25016 5.83333Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.55762 11.0542L4.43345 9.12333C4.89428 8.81416 5.55928 8.84916 5.97345 9.20499L6.16595 9.37416C6.62095 9.76499 7.35595 9.76499 7.81095 9.37416L10.2376 7.29166C10.6926 6.90083 11.4276 6.90083 11.8826 7.29166L12.8335 8.10833" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const VideoIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3975 15.315H4.6575C2.2875 15.315 1.5 13.74 1.5 12.1575V5.8425C1.5 3.4725 2.2875 2.685 4.6575 2.685H9.3975C11.7675 2.685 12.555 3.4725 12.555 5.8425V12.1575C12.555 14.5275 11.76 15.315 9.3975 15.315Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6402 12.825L12.5552 11.3625V6.63L14.6402 5.1675C15.6602 4.455 16.5002 4.89 16.5002 6.1425V11.8575C16.5002 13.11 15.6602 13.545 14.6402 12.825Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.625 8.25C9.24632 8.25 9.75 7.74632 9.75 7.125C9.75 6.50368 9.24632 6 8.625 6C8.00368 6 7.5 6.50368 7.5 7.125C7.5 7.74632 8.00368 8.25 8.625 8.25Z" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const DotsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z" />
            <path d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z" />
            <path d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z" />
        </svg>
    )
}

export const CancelIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.34912 8.65085L8.65079 5.34918" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.65079 8.65085L5.34912 5.34918" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.24984 12.8334H8.74984C11.6665 12.8334 12.8332 11.6667 12.8332 8.75002V5.25002C12.8332 2.33335 11.6665 1.16669 8.74984 1.16669H5.24984C2.33317 1.16669 1.1665 2.33335 1.1665 5.25002V8.75002C1.1665 11.6667 2.33317 12.8334 5.24984 12.8334Z" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const SaveIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.45817 6.21252H5.5415" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 4.78918V7.70585" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.81152 1.16669H4.18819C2.94569 1.16669 1.93652 2.18169 1.93652 3.41835V11.6375C1.93652 12.6875 2.68902 13.1309 3.61069 12.6234L6.45736 11.0425C6.76069 10.8734 7.25069 10.8734 7.54819 11.0425L10.3949 12.6234C11.3165 13.1367 12.069 12.6934 12.069 11.6375V3.41835C12.0632 2.18169 11.054 1.16669 9.81152 1.16669Z" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const DeleteIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 3.48832C10.3075 3.29582 8.35333 3.19666 6.405 3.19666C5.25 3.19666 4.095 3.25499 2.94 3.37166L1.75 3.48832" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.9585 2.89919L5.08683 2.13502C5.18016 1.58085 5.25016 1.16669 6.236 1.16669H7.76433C8.75016 1.16669 8.826 1.60419 8.9135 2.14085L9.04183 2.89919" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9961 5.33167L10.6169 11.2058C10.5527 12.1217 10.5002 12.8333 8.87273 12.8333H5.12773C3.50023 12.8333 3.44773 12.1217 3.38356 11.2058L3.00439 5.33167" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.02588 9.625H7.96838" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.5415 7.29169H8.45817" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const DoubleCheckIcon = () => {
    return (
        <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7501 2.81148L8.92057 5.62301L9.55566 6.25499L10.1907 6.88697L13.0107 4.08538C14.5617 2.54454 15.8306 1.26522 15.8306 1.24244C15.8306 1.2021 14.6396 0 14.5996 0C14.5886 0 13.3063 1.26518 11.7501 2.81148ZM16.1899 3.45532C14.3009 5.35374 12.1739 7.48451 11.4631 8.19037L10.1708 9.47376L8.28421 7.59967L6.39766 5.72558L5.77233 6.34692L5.14696 6.96822L7.66922 9.47425L10.1914 11.9802L15.5957 6.61065L21 1.24101L20.338 0.630715C19.9739 0.295023 19.6644 0.0166256 19.6502 0.0120209C19.636 0.00741626 18.0788 1.5569 16.1899 3.45532ZM0.624751 6.36733L0 6.98908L2.52193 9.49454L5.04386 12L5.67993 11.369L6.316 10.738L3.80391 8.24183C2.42223 6.86891 1.28227 5.74562 1.27066 5.74562C1.25906 5.74562 0.968398 6.02541 0.624751 6.36733Z" />
        </svg>
    )
}

export const LikeIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.60986 13.7625L7.93486 15.5625C8.23486 15.8625 8.90986 16.0125 9.35986 16.0125H12.2099C13.1099 16.0125 14.0849 15.3375 14.3099 14.4375L16.1099 8.96248C16.4849 7.91248 15.8099 7.01248 14.6849 7.01248H11.6849C11.2349 7.01248 10.8599 6.63748 10.9349 6.11248L11.3099 3.71248C11.4599 3.03748 11.0099 2.28748 10.3349 2.06248C9.73486 1.83748 8.98486 2.13748 8.68486 2.58748L5.60986 7.16248" strokeMiterlimit="10" />
            L       <path d="M1.78516 13.7625V6.41249C1.78516 5.36249 2.23516 4.98749 3.28516 4.98749H4.03516C5.08516 4.98749 5.53516 5.36249 5.53516 6.41249V13.7625C5.53516 14.8125 5.08516 15.1875 4.03516 15.1875H3.28516C2.23516 15.1875 1.78516 14.8125 1.78516 13.7625Z" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const ShareIcon = () => {
    return (
        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.57614 0.0397677C9.43407 0.105789 9.30668 0.241606 9.25268 0.384659C9.22541 0.45684 9.21175 1.23035 9.21175 2.7005V4.90794L8.83778 4.93173C7.23743 5.0334 6.30725 5.17226 5.3208 5.45671C3.98622 5.84156 3.02517 6.38348 2.18999 7.22217C1.09967 8.31697 0.521218 9.61581 0.217013 11.6521C0.0762448 12.5945 -0.0519041 15.121 0.0212416 15.5123C0.0797736 15.8254 0.423371 16.0478 0.753044 15.9857C0.982915 15.9424 1.09729 15.8006 1.44154 15.1319C2.23111 13.5983 2.91098 12.8233 4.01445 12.1989C5.1704 11.5449 6.52883 11.232 8.81772 11.0925L9.20995 11.0686L9.22046 13.3401C9.2307 15.5659 9.23254 15.6137 9.31002 15.7179C9.49444 15.9658 9.79389 16.0633 10.0468 15.958C10.2331 15.8804 18.3006 8.3932 18.3652 8.23794C18.4284 8.08611 18.4315 7.93185 18.3747 7.7682C18.3437 7.67897 17.2267 6.62077 14.2801 3.88913C12.0516 1.82309 10.1884 0.106098 10.1397 0.0735293C10.0185 -0.00758313 9.71695 -0.0256382 9.57614 0.0397677ZM13.7378 5.06785C15.4571 6.65973 16.8639 7.97693 16.8639 7.99502C16.8639 8.01308 15.4226 9.36293 13.6611 10.9946L10.4583 13.9614L10.4392 12.0973C10.4206 10.2902 10.4176 10.23 10.3409 10.127C10.1723 9.90045 10.0808 9.86304 9.65987 9.84845C8.78953 9.81826 7.0939 9.97221 6.12222 10.1696C4.10007 10.5804 2.53512 11.4378 1.53793 12.6814L1.29836 12.9802L1.3254 12.6337C1.3605 12.1847 1.48784 11.3363 1.58254 10.9206C1.91966 9.44088 2.61816 8.30488 3.64175 7.57164C4.03804 7.28773 4.82838 6.89517 5.33775 6.72925C6.38557 6.38787 7.58598 6.21244 9.27681 6.15361C10.1216 6.12424 10.1527 6.11573 10.3409 5.86277C10.4179 5.75933 10.4203 5.70651 10.4306 3.88651L10.4413 2.01649L10.5265 2.09503C10.5734 2.13822 12.0185 3.47598 13.7378 5.06785Z" />
        </svg>
    )
}

export const AddUserIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.875 14.625H10.875" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.375 16.125V13.125" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.11995 8.1525C9.04495 8.145 8.95495 8.145 8.87245 8.1525C7.08745 8.0925 5.66995 6.63 5.66995 4.83C5.66245 2.9925 7.15495 1.5 8.99245 1.5C10.83 1.5 12.3225 2.9925 12.3225 4.83C12.3225 6.63 10.8974 8.0925 9.11995 8.1525Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99229 16.3575C7.62728 16.3575 6.26979 16.0125 5.23479 15.3225C3.41979 14.1075 3.41979 12.1275 5.23479 10.92C7.29729 9.54001 10.6798 9.54001 12.7423 10.92" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const EditIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.73463 2.1L2.94546 7.16917C2.76463 7.36167 2.58963 7.74083 2.55463 8.00333L2.3388 9.89333C2.26296 10.5758 2.75296 11.0425 3.42963 10.9258L5.30796 10.605C5.57046 10.5583 5.93796 10.3658 6.11879 10.1675L10.908 5.09833C11.7363 4.22333 12.1096 3.22584 10.8205 2.00667C9.53713 0.799169 8.56296 1.225 7.73463 2.1Z" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.93555 2.94583C7.18638 4.55583 8.49305 5.78666 10.1147 5.95" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.75 12.8333H12.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const MenuIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 7H21" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M3 12H21" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M3 17H21" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    )
}

export const MoreIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 9.32C13.19 9.32 14.16 8.35 14.16 7.16C14.16 5.97 13.19 5 12 5C10.81 5 9.83997 5.97 9.83997 7.16C9.83997 8.35 10.81 9.32 12 9.32Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.79 19C7.98 19 8.95 18.03 8.95 16.84C8.95 15.65 7.98 14.68 6.79 14.68C5.6 14.68 4.63 15.65 4.63 16.84C4.63 18.03 5.59 19 6.79 19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.21 19C18.4 19 19.37 18.03 19.37 16.84C19.37 15.65 18.4 14.68 17.21 14.68C16.02 14.68 15.05 15.65 15.05 16.84C15.05 18.03 16.02 19 17.21 19Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const SendIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.44026 12H10.8403M9.51026 4.23001L18.0703 8.51001C21.9103 10.43 21.9103 13.57 18.0703 15.49L9.51026 19.77C3.75026 22.65 1.40026 20.29 4.28026 14.54L5.15026 12.81C5.37026 12.37 5.37026 11.64 5.15026 11.2L4.28026 9.46001C1.40026 3.71001 3.76026 1.35001 9.51026 4.23001Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


