// Libraries
import React from 'react';

// Components
import { Footer, Navbar } from '../../components';
import { About, GetConnected, HaveLook, Hero, Stats } from './components';

// Styles
import './style.css';

// Assets

const LandingPage = () => {
    return (
        <React.Fragment>
            <Navbar />
            <Hero />
            <Stats />
            <About />
            <GetConnected />
            <HaveLook />
            <Footer />
        </React.Fragment>
    )
}

export default LandingPage;
