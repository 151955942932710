// Libraries
import { Typography, Image } from 'antd';

// Component

// Styles
import './style.css';

// Assets
import aboutImg from '../../../../assets/images/about-img.jpg';

const { Title, Paragraph } = Typography;

const About = () => {
    return (
        <div className='mv-about'>
            <div className='mv-container'>
                <div className='mv-about-content mv-d-flex'>
                    <div className='mv-about-start'>
                        <ul className='mv-about-meta'>
                            <li>
                                <Title level={2}>Candidate Profiles</Title>
                                <Paragraph>See detailed profiles of candidates that represent you the most.</Paragraph>
                            </li>
                            <li>
                                <Title level={2}>Candidate Polls</Title>
                                <Paragraph>Provide real-time feedback to your local representatives on how they are doing and what issues concern you the most. </Paragraph>
                            </li>
                        </ul>
                    </div>
                    <div className='mv-about-center'>
                        <Image src={aboutImg} preview={false} alt="" />
                    </div>
                    <div className='mv-about-end'>
                        <ul className='mv-about-meta'>
                            <li>
                                <Title level={2}>Candidate Livestreams</Title>
                                <Paragraph>Virtually interact with the people that represent you.</Paragraph>
                            </li>
                            <li>
                                <Title level={2}>Candidate Interviews</Title>
                                <Paragraph>Hear what candidates have to say and hold them to account.</Paragraph>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
