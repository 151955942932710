// Libraries
import {
    Typography,
    Card,
    Image,
    Button,
    Dropdown,
    Menu,
    Radio,
    Progress
} from 'antd';
import { Link } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import userImgOne from '../../assets/images/image-1.jpg';
import userImgTwo from '../../assets/images/image-2.jpg';
import userImgThree from '../../assets/images/image-3.jpg';
import userImgFour from '../../assets/images/image-4.jpg';
import userImgFive from '../../assets/images/image-5.jpg';
import {
    CancelIcon,
    SaveIcon,
    DeleteIcon,
    DotsIcon,
}
    from '../../assets/icons/icons';

const { Title, Paragraph } = Typography;

const menu = (
    <Menu
        items={[
            {
                key: '1',
                icon: <CancelIcon />,
                label: (
                    <Link to="#">Hide Post</Link>
                ),
            },
            {
                key: '2',
                icon: <SaveIcon />,
                label: (
                    <Link to="#">Save Post</Link>
                ),
            },
            {
                key: '3',
                icon: <DeleteIcon />,
                label: (
                    <Link to="#">Delete Post</Link>
                ),
            },
        ]}
    />
);

const Poll = (props) => {
    return (
        <div className='mv-poll'>
            <Card>
                <div className='mv-post-header mv-d-flex'>
                    <div className='mv-post-header-meta mv-d-flex'>
                        <div className='mv-post-user-meta'>
                            <Title level={2}>{props.profileName}</Title>
                        </div>
                    </div>
                    <div className='mv-post-dropdown'>
                        <Dropdown overlay={menu} placement="bottomRight" arrow>
                            <Button>
                                <DotsIcon />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <Paragraph>{props.text}</Paragraph>
                <ul className='mv-poll-list'>

                    {props.poll.map(item => {
                        return (
                            <li>
                                <div className='mv-pool-header mv-d-flex'>
                                    <Radio defaultChecked>{item.text}</Radio>
                                </div>
                                <Progress percent={item.value} />
                                <Paragraph>5 friends voted for this</Paragraph>
                                <ul className='mv-poll-images mv-d-flex'>
                                    <li>
                                        <Image src={userImgOne} alt="" preview={false} className="mv-img-35" />
                                    </li>
                                    <li>
                                        <Image src={userImgTwo} alt="" preview={false} className="mv-img-35" />
                                    </li>
                                    <li>
                                        <Image src={userImgThree} alt="" preview={false} className="mv-img-35" />
                                    </li>
                                    <li>
                                        <Image src={userImgFour} alt="" preview={false} className="mv-img-35" />
                                    </li>
                                    <li>
                                        <Image src={userImgFive} alt="" preview={false} className="mv-img-35" />
                                    </li>
                                </ul>
                            </li>
                        )

                    })
                    }

                </ul>
            </Card>
        </div>
    )
}

export default Poll;
