// Libraries
import { useState, useEffect } from 'react'
import { Card, Form, Button, AutoComplete, Input, Typography, Modal } from 'antd';
import { Link } from 'react-router-dom';

// Component
import { EditIcon, DeleteIcon } from '../../../../assets/icons/icons';

// Styles
import './style.css';
import { deleteInterviewAsync, getInterviewAsync, setInterviewAsync, updateInterviewAsync } from '../../../../shared/asyncstorage/interview';
import uuidv4 from '../../../../shared/uuidgenerator';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import OpenSuccessNotification from '../../../../Notification/successNotif';

// Assets

const { Title } = Typography;

const Interviews = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [interviewArray, setInterviewArray] = useState([]);
    const [model, setModel] = useState(null);
    const [form] = Form.useForm();
    const [isUpdating, setIsUpdating] = useState(false)
    const { token, getUserID } = useToken();
    const { loginUserObject, } = useShareSelector(state => state.UserLoginDetails)
    const dispatcher = useShareDispatch();
    useEffect(() => {
        if (loginUserObject?.userCandidateExtension?.interviews?.length > 0)
            setInterviewArray(loginUserObject?.userCandidateExtension?.interviews)
    }, [loginUserObject])

    const onFinish = (values) => {

        console.log('Success:', values);
        if (values) {
            setIsUpdating(true)
            if (values.linkTitle) {
                values.linkType = "interview"
                setIsUpdating(true)
                interceptor.axiosPost("Post", "/links", values, token).then(res => {
                    setIsUpdating(false)
                    if (res.status = 200) {
                        OpenSuccessNotification("topRight", "Successfully Added")
                        dispatcher(actionsApi.UpdateInterviews(res.data))
                    }
                }).catch(error => {
                    console.log("error?.response?.data?.error", error?.response);
                    setIsUpdating(false)
                })
                handleCancel()
            }

        }
        // if (model) {
        //     interviewArray.forEach(x => {
        //         if (x._id == model._id) {
        //             x.interviewTitle = values.interviewTitle;
        //             x.interviewLink = values.interviewLink;
        //         }
        //     })
        //     updateInterviewAsync(interviewArray)
        // } else {
        //     values._id = uuidv4()
        //     setInterviewAsync(values)
        // }
        // setIsUpdating(true)
        // form.resetFields()
        // setTimeout(() => {
        //     setIsUpdating(false)

        // }, 2000)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

        // setPoliticalI
    };


    const showModal = () => {
        setIsModalOpen(true);
        setModel(null)
        form.setFieldsValue({
            interviewTitle: '',
            interviewLink: '',
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const deleteInterview = (item) => {
        interceptor.axiosDelete("Delete", "/links/" + item.linkId, null, token).then(res => {
            setIsUpdating(false)
            if (res.status = 200) {
                OpenSuccessNotification("topRight", "Successfully Remove")
                dispatcher(actionsApi.deleteInterview(item))
            }
        }).catch(error => {
            console.log("error?.response?.data?.error", error?.response);
            setIsUpdating(false)

        })
    }
    const editInterview = (value) => {
        setIsModalOpen(true);
        setModel(value)
        form.setFieldsValue({
            interviewTitle: value.interviewTitle,
            interviewLink: value.interviewLink,
        });
    }

    return (
        <>
            <div className='mv-form-fields mv-mt-20'>
                <div className='mv-d-flex mv-jc-sb'>
                    <Title level={2}>Interviews</Title>
                    <Link to="#" onClick={() => showModal()} className='ant-btn ant-btn-danger ant-btn-44'>Add New</Link>
                </div>

                {
                    interviewArray.length > 0 &&
                    interviewArray.map((value, key) => {
                        return (
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Card>
                                    <div
                                        name="interviewTitle"
                                        className='mv-mb-15'
                                    >
                                        <Input
                                            value={value.linkTitle}
                                            placeholder="Interview Title" />
                                    </div>
                                    <div
                                        name="interviewLink"
                                        className='mv-mb-0'
                                    >
                                        <Input value={value.linkUrl} placeholder="Interview Link" />
                                    </div>
                                    <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>
                                        <div className='mv-icon-btns mv-d-flex'>
                                            {/* <Link to="#" onClick={() => editInterview(value)} className='ant-btn ant-btn-primary ant-btn-30 mv-mr-10'>
                                                <EditIcon />
                                            </Link> */}
                                            <Link to="#" onClick={() => deleteInterview(value)} className='ant-btn ant-btn-danger ant-btn-30'>
                                                <DeleteIcon />
                                            </Link>
                                        </div>
                                        {/* <Button type="primary" className='ant-btn-44'>Save</Button> */}
                                    </div>
                                </Card>
                            </Form>
                        )
                    })
                }
                {/* */}
                <>

                    <Modal
                        title="Add/Edit Interviews"
                        open={isModalOpen}
                        onOk={handleOk}
                        footer={null}
                        onCancel={handleCancel}>
                        <Form
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                            form={form}
                        >
                            <Card>
                                <Form.Item
                                    name="linkTitle"
                                    className='mv-mb-15'
                                >
                                    <Input placeholder="Interview Title" />
                                </Form.Item>
                                <Form.Item
                                    name="linkUrl"
                                    className='mv-mb-0'
                                >
                                    <Input placeholder="Interview Link" />
                                </Form.Item>
                                <div className='mv-btn-right mv-mt-20 mv-d-flex mv-jc-sb'>

                                    <Button type="primary" htmlType="submit" className='ant-btn-44'>Save</Button>
                                </div>
                            </Card>
                        </Form>
                    </Modal>
                </>

            </div>


        </>


    )
}

export default Interviews;
